import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";

import transactionsReducer from "@features/transactions/reducer";
import paybylinkReducer from "@features/paybylink/reducer";
import dashboardReducer from "@features/dashboard/reducer";
import statisticsReducer from "@features/statistics/reducer";
import administrationReducer from "@features/administration/reducer";
import keysReducer from "@features/developers/ApiKeys/reducer";
import reportReducer from "@features/common/report/reducer";
import ordersReducer from "@features/orders/reducer";
import chargebacksReducer from "@features/chargebacks/reducer";
import shiftsReducer from "@features/shifts/reducer";
import webhooksReducer from "@features/developers/Webhooks/reducer";
import storesReducer from "@features/settings/Store/reducer";

import uiReducer from "./reducers/ui";
import userReducer from "./reducers/user";
import notificationsReducer from "./reducers/notifications";

import rootSaga from "../sagas";

const sagaMiddleware = createSagaMiddleware();

export default configureStore({
  reducer: {
    ui: uiReducer,
    user: userReducer,
    keys: keysReducer,
    report: reportReducer,
    orders: ordersReducer,
    chargebacks: chargebacksReducer,
    paybylink: paybylinkReducer,
    dashboard: dashboardReducer,
    statistics: statisticsReducer,
    transactions: transactionsReducer,
    notifications: notificationsReducer,
    administration: administrationReducer,
    shifts: shiftsReducer,
    webhooks: webhooksReducer,
    stores: storesReducer,
  },
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["notifications/enqueue", "ui/openModal"],
      },
    }),
    sagaMiddleware,
    logger,
  ],
});

sagaMiddleware.run(rootSaga);
