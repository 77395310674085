import React, { useEffect, lazy, useState, useRef } from 'react';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';
import { ApmRoute } from '@elastic/apm-rum-react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import useHotjar from 'react-use-hotjar';

import Box from '@mui/material/Box';

import { useAuth } from '@context/Auth0Context';

import ProtectedRoute from '@components/atoms/ProtectedRoute';

import Loading from '@components/molecules/Loading';
import Home from '@features/home';
import Notifier from '@features/common/notifier';
import ModalManager from '@components/organisms/ModalManager';
import PageErrorBoundary from '@components/templates/PageErrorBoundary';
import NotFound from '@components/molecules/NotFound';
import Login from '@components/pages/Login';

import SidebarMenu from '@components/organisms/SidebarMenu';
import { PERMISSIONS } from '@services/config';

import { actions } from '@store/reducers/user';
import { useStyles } from './style';

const Transactions = lazy(() => import('@features/transactions'));
const PayByLink = lazy(() => import('@features/paybylink'));
const Dashboard = lazy(() => import('@features/dashboard'));
const Administration = lazy(() => import('@features/administration'));
const Statistics = lazy(() => import('@features/statistics'));
const ApiKeys = lazy(() => import('@features/developers/ApiKeys'));
const User = lazy(() => import('@features/settings/User'));
const Webhooks = lazy(() => import('@features/developers/Webhooks'));
const Onboard = lazy(() => import('@features/settings/Onboard'));
const Invite = lazy(() => import('@features/invite'));
const Shifts = lazy(() => import('@features/shifts/components/Shifts'));
// const Settlements = lazy(() => import('@features/administration/Settlements'));
// const SettlementsDetails = lazy(
//   () => import('@features/administration/SettlementsDetails')
// );

const SocialPaymentsWrapper = lazy(
  () => import('@features/socialPayments/components/SocialPaymentsWrapper')
);
const Forbidden = lazy(() => import('@features/forbidden'));
const Chargebacks = lazy(() => import('@features/chargebacks'));
const ChargebacksDetail = lazy(() => import('@features/chargebacks/Detail'));
const ChargebacksAdd = lazy(() => import('@features/chargebacks/Add'));

const Stores = lazy(() => import('@features/settings/Store'));
const OrdersNew = lazy(
  () => import('@components/pages/Orders/components/order')
);
const OrderNewDetail = lazy(
  () => import('@components/pages/Orders/components/order-details')
);
const TransactionsNew = lazy(
  () => import('@components/pages/Transactions/components/transaction')
);
const TransactionNewDetail = lazy(
  () => import('@components/pages/Transactions/components/transaction-details')
);

const Payouts = lazy(
  () => import('@components/pages/Payouts/components/payout')
);
const PayoutsDetails = lazy(
  () => import('@components/pages/Payouts/components/details')
);
const SettlementFiles = lazy(
  () => import('@components/pages/Settlements/components/files')
);

export const Root = () => {
  //const [isLoading, setLoading] = useState(true);
  const globalBanner = useRef(null);
  const globalBannerOffset = globalBanner.current?.clientHeight;
  const dispatch = useDispatch();
  const { user, accessToken, logout, isLoading, needsLogin } = useAuth();
  const menuOpen = useSelector((state) => state.ui.menuOpen);
  //const accessToken = useSelector(state => state.user.accessToken);
  const hasToLogout = useSelector((state) => state.user.hasToLogout);
  const classes = useStyles();
  const dev = process.env.NODE_ENV === 'development';
  // TODO - Is it possible to receive this the same as API_URL?
  const stage = window.location.href.includes('dashboard-s');
  const [gqlClient, setGQLClient] = useState(null);
  const { identifyHotjar } = useHotjar();

  useEffect(() => {
    if (user) {
      identifyHotjar(user.sub, { ...user, email: user.name });
    }
  }, [user]);

  /* const handleStyleTypeChange = event => {
    const checked = event.target.checked;
    dispatch(switchMode(checked ? 'light' : 'dark'));
  }; */

  useEffect(() => {
    if (accessToken) {
      const setupGraphQL = (accessToken) => {
        const httpLink = createHttpLink({
          uri: `https://webapp-paybyrd-bff${dev || stage ? '-s' : ''}.azurewebsites.net/graphql`,
        });

        const authLink = setContext((_, { headers }) => {
          return {
            headers: {
              ...headers,
              authorization: `Bearer ${accessToken}`,
            },
          };
        });

        setGQLClient(
          new ApolloClient({
            link: from([authLink, httpLink]),
            cache: new InMemoryCache(),
            defaultOptions: {
              watchQuery: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'ignore',
              },
              query: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
              },
            },
          })
        );
      };

      const getAccessToken = () => {
        // Setup GraphQL after accessToken is received
        setupGraphQL(accessToken);

        dispatch({
          type: actions.GET_USER_INFO,
          payload: {
            accessToken,
          },
        });
      };

      getAccessToken();
    }
  }, [accessToken, dev, stage]);

  useEffect(() => {
    if (hasToLogout !== null && hasToLogout && user) {
      logout({
        returnTo: window.location.origin,
      });
    }
  }, [hasToLogout, user, logout]);

  if (!isLoading && needsLogin) {
    return <Login />;
  }

  if (isLoading || gqlClient === null) {
    return <Loading />;
  }

  return (
    <ApolloProvider client={gqlClient}>
      {/*renderPhase()*/}
      {user && <SidebarMenu open={menuOpen} offset={globalBannerOffset} />}
      <Box
        style={{ paddingTop: globalBannerOffset }}
        className={clsx(classes.app, {
          [classes.open]: menuOpen && user,
          [classes.close]: !menuOpen && user,
        })}
      >
        <PageErrorBoundary>
          {/* {<Toggle onChange={handleStyleTypeChange} />} */}
          <Switch>
            <ApmRoute exact path="/" component={Home} />
            <ProtectedRoute
              path="/dashboard"
              permissions={[
                PERMISSIONS.REPORTS_TRANSACTIONS,
                PERMISSIONS.REPORTS_STATISTICS,
              ]}
              component={Dashboard}
            />
            <ProtectedRoute
              permissions={PERMISSIONS.REPORTS_TRANSACTIONS}
              path="/transactions"
              component={Transactions}
            />
            <ProtectedRoute
              permissions={PERMISSIONS.REPORTS_TRANSACTIONS}
              exact
              path="/transactions-new"
              component={TransactionsNew}
            />
            <ProtectedRoute
              permissions={PERMISSIONS.REPORTS_TRANSACTIONS}
              exact
              path="/transactions-new/:storeId/:transactionId"
              component={TransactionNewDetail}
            />
            <ProtectedRoute
              permissions={PERMISSIONS.REPORTS_TRANSACTIONS}
              exact
              path="/orders-new/:storeId/:orderId"
              component={OrderNewDetail}
            />
            <ProtectedRoute
              permissions={PERMISSIONS.REPORTS_TRANSACTIONS}
              path="/orders-new"
              component={OrdersNew}
            />
            <ProtectedRoute
              permissions={PERMISSIONS.REPORTS_STATISTICS}
              path="/statistics"
              component={Statistics}
            />
            <ProtectedRoute
              permissions={PERMISSIONS.PAYBYLINK}
              path="/paybylink"
              component={PayByLink}
            />
            <ProtectedRoute
              permissions={PERMISSIONS.ADMINISTRATION}
              path="/administration"
              component={Administration}
            />
            <ProtectedRoute
              permissions={PERMISSIONS.DEVELOPERS}
              path="/apikeys"
              component={ApiKeys}
            />
            <ProtectedRoute
              permissions={PERMISSIONS.SHIFTS}
              path="/shifts"
              component={Shifts}
            />

            {/*<ProtectedRoute
              permissions={PERMISSIONS.ADMINISTRATION_INVOICES}
              path="/payouts"
              exact
              component={Settlements}
            />
            <ProtectedRoute
              permissions={PERMISSIONS.ADMINISTRATION_INVOICES}
              path="/payouts/:id"
              exact
              component={SettlementsDetails}
            />*/}

            <ProtectedRoute
              permissions={PERMISSIONS.PAYMENTS_SETTLEMENTS}
              path="/settlement-files"
              exact
              component={SettlementFiles}
            />

            <ProtectedRoute
              permissions={PERMISSIONS.PAYMENTS_PAYOUTS}
              path="/payouts"
              exact
              component={Payouts}
            />

            <ProtectedRoute
              permissions={PERMISSIONS.PAYMENTS_PAYOUTS}
              path="/payouts/:settlementGuid"
              exact
              component={PayoutsDetails}
            />

            <ProtectedRoute
              permissions={PERMISSIONS.REPORTS_TRANSACTIONS}
              path="/social"
              exact
              component={SocialPaymentsWrapper}
            />

            <ProtectedRoute
              path="/chargebacks/add"
              exact
              component={ChargebacksAdd}
            />
            <ProtectedRoute
              path="/chargebacks/add/:transactionId/:storeId/:storeName/:brandCode"
              exact
              component={ChargebacksAdd}
            />
            <ProtectedRoute
              path="/chargebacks/:id"
              exact
              component={ChargebacksDetail}
            />
            <ProtectedRoute
              permissions={PERMISSIONS.REPORTS_TRANSACTIONS}
              path="/social/:id"
              exact
              component={SocialPaymentsWrapper}
            />
            <ProtectedRoute path="/chargebacks" component={Chargebacks} />
            <ProtectedRoute path="/user" component={User} />
            <ProtectedRoute
              permissions={PERMISSIONS.DEVELOPERS}
              path="/webhooks"
              component={Webhooks}
            />
            <ProtectedRoute path="/onboarding" component={Onboard} />
            <ProtectedRoute path="/invite" component={Invite} />
            <ProtectedRoute
              permissions={PERMISSIONS.ADMINISTRATION}
              path="/stores"
              component={Stores}
            />
            <ProtectedRoute path="/forbidden" component={Forbidden} />
            <Redirect to="/dashboard" />
            <ApmRoute path="*" component={NotFound} />
          </Switch>
          <Notifier />
          <ModalManager />
        </PageErrorBoundary>
      </Box>
    </ApolloProvider>
  );
};

export default Root;
