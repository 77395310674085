const APP_AUTH0_DOMAIN = process.env.APP_AUTH0_DOMAIN;
const APP_AUTH0_CLIENT_ID = process.env.APP_AUTH0_CLIENT_ID;
const API_AUDIENCE = process.env.APP_AUTH0_AUDIENCE;
const API_URL = process.env.API_URL;
const BFF_API = process.env.BFF_API;
const NODE_ENV = process.env.NODE_ENV;
const MERCHANT_ONBOARDING_URL = process.env.MERCHANT_ONBOARDING_URL;
const APP_INTERCOM_API_KEY = process.env.APP_INTERCOM_API_KEY;
const DEFAULT_PAGE_SIZE = 10;

const SCOPES = {
  OPENID: 'openid',
  PROFILE: 'profile',
  EMAIL: 'email',
  OFFLINE_ACCESS: 'offline_access',
  READ_MERCHANTS: 'read:merchants',
  READ_BANKACCOUNTS: 'read:bankaccounts',
  CREATE_BANKACCOUNT: 'create:bankaccount',
  UPDATE_BANKACCOUNT: 'update:bankaccount',
  DELETE_BANKACCOUNT: 'delete:bankaccount',
  READ_SUBSCRIPTIONS: 'read:subscriptions',
  WRITE_SUBSCRIPTION: 'write:subscription',
  CREATE_PAYBYLINK: 'create:paybylink',
};

const PERMISSIONS = {
  REPORTS_TRANSACTIONS: 'reports:transactions',
  REPORTS_STATISTICS: 'reports:statistics',
  ADMINISTRATION: 'administration',
  ADMINISTRATION_CURRENT_BALANCE: 'administration:current-balance',
  ADMINISTRATION_INVOICES: 'administration:invoices',
  ADMINISTRATION_SETTLEMENTS: 'administration:settlements',
  ONBOARDING: 'onboarding',
  PAYBYLINK: 'paybylink',
  SETTINGS: 'settings',
  SETTINGS_BANK_ACCOUNTS: 'settings:bank-accounts',
  SETTINGS_ORGANIZATION: 'settings:organization',
  DEVELOPERS: 'developers',
  SHIFTS: 'read:shifts',
  NO_REFUND: 'deny:refund:transaction',
  PAYMENTS_SETTLEMENTS: 'payments:settlements',
  PAYMENTS_PAYOUTS: 'payments:payouts',
};

const TRANSACTION_SOURCES = {
  E_COMMERCE: 'Ecommerce',
  POS: 'POS',
  PAYBYLINK: 'PayByLink',
};

const TRANSACTION_TYPES = {
  PAYMENT: 'Payment',
  REFUND: 'Refund',
  CHARGEBACK: 'Chargeback',
  PREAUTH: 'PreAuth',
  CAPTURE: 'Capture',
};

const TRANSACTION_STATUSES = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  TEMPORARY_FAILED: 'temporaryfailed',
  DENIED: 'denied',
  SUCCESS: 'success',
  PENDINGMERCHANTACTION: 'pendingmerchantaction',
  CANCELED: 'canceled',
  ERROR: 'error',
  EXPIRED: 'expired',
  PAID: 'paid',
  ACQUIRERSUCCESS: 'acquirersuccess',
  ACQUIRERPROCESSING: 'acquirerprocessing',
  ACQUIRERFAILED: 'acquirerfailed',
  ACQUIRERTEMPORARYFAILED: 'acquirertemporaryfailed',
  DECLINED: 'declined',
  CREATED: 'created',
  REFUNDED: 'refunded',
  FAILED: 'failed',
};

const CHARGEBACK_STATUSES = {
  DISPUTING: 'Disputing',
  LOST: 'Lost',
  WON: 'Won',
  PENDING_ACTION: 'PendingAction',
  CREATED: 'Created',
  // CLOSED: "Closed",
  ACCEPTED: 'Accepted',
};

const SHIFT_STATUSES = {
  CLOSED: 'closed',
  OPENED: 'opened',
};

const REFUND_STATUSES = {
  REFUNDED: 'Refund',
  PARTIALLY_REFUNDED: 'PartiallyRefunded',
};

const CAPTURE_STATUSES = {
  CAPTURED: 'Captured',
  PARTIALLY_CAPTURED: 'PartiallyCaptured',
};

const ALLOWED_OPERATIONS = {
  REFUND: 'refund',
  CAPTURE: 'capture',
  REVERSAL: 'reversal',
};

const DOCUMENTATION_LINK = 'https://docs.paybyrd.com/';

const STATUSES = [
  { label: 'Canceled', value: TRANSACTION_STATUSES.CANCELED },
  { label: 'Failed', value: TRANSACTION_STATUSES.ACQUIRERFAILED },
  { label: 'Created', value: TRANSACTION_STATUSES.CREATED },
  { label: 'Success', value: TRANSACTION_STATUSES.ACQUIRERSUCCESS },
  { label: 'Processing', value: TRANSACTION_STATUSES.PROCESSING },
  {
    label: 'Pending Merchant Action',
    value: TRANSACTION_STATUSES.PENDINGMERCHANTACTION,
  },
  { label: 'Error', value: TRANSACTION_STATUSES.ERROR },
];

const CHANNELS = [
  { label: 'POS', value: TRANSACTION_SOURCES.POS },
  { label: 'Ecommerce', value: TRANSACTION_SOURCES.E_COMMERCE },
];

const ACTIVITY_BRANCHES = {
  AIRLINE: 'Airline',
  GENERAL: 'General',
  RESTAURANT: 'Restaurant',
  HOTEL: 'Hotel',
  ALL: ['Airline', 'General', 'Restaurant', 'Hotel'],
};

const ORDER_STATUSES = {
  CANCELED: 'Canceled',
  CREATED: 'Created',
  EXPIRED: 'Expired',
  PAID: 'Paid',
  PENDING: 'Pending',
  REFUNDED: 'Refunded',
};

export {
  APP_AUTH0_DOMAIN,
  APP_AUTH0_CLIENT_ID,
  API_URL,
  BFF_API,
  MERCHANT_ONBOARDING_URL,
  API_AUDIENCE,
  SCOPES,
  PERMISSIONS,
  DEFAULT_PAGE_SIZE,
  TRANSACTION_SOURCES,
  TRANSACTION_TYPES,
  TRANSACTION_STATUSES,
  SHIFT_STATUSES,
  CHARGEBACK_STATUSES,
  NODE_ENV,
  DOCUMENTATION_LINK,
  REFUND_STATUSES,
  CAPTURE_STATUSES,
  ALLOWED_OPERATIONS,
  STATUSES,
  CHANNELS,
  ACTIVITY_BRANCHES,
  APP_INTERCOM_API_KEY,
  ORDER_STATUSES,
};
