import { takeLatest, put, call, all } from 'redux-saga/effects';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';

import { api } from '@legacy/api';
import { setAccessToken, setAppReady, actions } from '@store/reducers/user';
import { getCultures, setCookie } from 'utils';

export function* handleSetAccessToken({ payload: { accessToken } }) {
  let merchants = [];
  let scopes = '';
  let onboardingPhase = null;
  let onboardingId = null;
  let onboardingUrl = null;
  let groups = [];
  let stores = [];
  let paymentMethods = [];
  let hasToLogout = false;
  let currencies = [];
  let countries = [];
  let channels = [];
  let activityBranches = [];
  let plMerchants = [];

  try {
    const decoded = jwtDecode(accessToken);
    const { 'https://gateway.paybyrd.com/PersonId': ids, scope, exp } = decoded;
    const expiration = new Date();
    expiration.setTime(exp * 1000);

    // Init api with access token
    yield call(api.init, accessToken);
    // Set the token
    setCookie('paybyrd.oauth', accessToken, '.paybyrd.com', expiration);

    const result = yield all([
      api.getCurrencies(),
      api.userInfo(),
      api.getStores(),
      api.onboard(),
      api.getUserInfo(),
    ]);

    const { data: onboarding } = result[3];
    const data = result[4];

    if (onboarding.onboard) {
      const {
        onboard: {
          data: { id, onboardUrl },
          phase,
        },
      } = onboarding;

      onboardingUrl = onboardUrl;
      onboardingPhase = phase;
      onboardingId = id;

      // In this case we are dealing with a freshly created user.
      // We need to redirect her to the merchant onboarding form.
      if (
        ids &&
        ids.length === 0 &&
        (phase === 'SendMerchantDocuments' ||
          phase === 'WaitMerchantDocumentsReview')
      ) {
        hasToLogout = true;
      }
    }

    if (data) {
      if (data.length > 0 && data[0].defaultCurrency) {
        setCookie('defaultCurrency', data[0].defaultCurrency);
      } else {
        setCookie('defaultCurrency', 'EUR');
      }

      if (!Cookies.get('currentCurrency')) {
        setCookie('currentCurrency', Cookies.get('defaultCurrency'));
      }

      scopes = scope.split(' ');
      plMerchants = getCultures(data);
    } else {
      throw new Error('Error getting merchants');
    }

    currencies = (result[0] || []).map((currency) => ({
      label: currency,
      value: currency,
    }));
    activityBranches = result[1];
    stores = result[2];
  } catch (_) {
    // Do nothing
  } finally {
    yield put(
      setAccessToken({
        stores,
        paymentMethods,
        groups,
        accessToken,
        merchants,
        scopes,
        phase: onboardingPhase,
        onboardingId,
        hasToLogout,
        onboardingUrl,
        currencies,
        countries,
        channels,
        activityBranches,
        plMerchants,
      })
    );
    yield put(setAppReady());
  }
}

export default function* userSaga() {
  yield takeLatest(actions.GET_USER_INFO, handleSetAccessToken);
}
