import { fork, put } from 'redux-saga/effects';
import { takeEveryAsync } from 'saga-toolkit';
import axios from 'axios';

import { fetchKeys, generateKey } from './reducer';

function* handleFetchKeys() {
  try {
    const { data: keys } = yield axios.get('subscriptions');
    return { keys };
  } catch (_) {
    throw new Error();
  }
}

function* handleFetchKey({ meta: { arg } }) {
  try {
    yield axios.post('subscription', { PersonId: arg });
    yield put(fetchKeys());
    return true;
  } catch (_) {
    throw new Error();
  }
}

function* getKeys() {
  yield takeEveryAsync(fetchKeys.type, handleFetchKeys);
}

function* getKey() {
  yield takeEveryAsync(generateKey.type, handleFetchKey);
}

export function* keysSaga() {
  yield fork(getKeys);
  yield fork(getKey);
}
