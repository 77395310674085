import React from 'react';

import eachMonthOfInterval from 'date-fns/eachMonthOfInterval';
import subMonths from 'date-fns/subMonths';
import addMonths from 'date-fns/addMonths';
import isValid from 'date-fns/isValid';
import startOfYear from 'date-fns/startOfYear';
import eachYearOfInterval from 'date-fns/eachYearOfInterval';
import subYears from 'date-fns/subYears';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import startOfToday from 'date-fns/startOfToday';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import format from 'date-fns/format';
import dateFormat from 'dateformat';
import Cookies from 'js-cookie';
import parseISO from 'date-fns/parseISO';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import subWeeks from 'date-fns/subWeeks';
import startOfQuarter from 'date-fns/startOfQuarter';
import endOfQuarter from 'date-fns/endOfQuarter';
import subQuarters from 'date-fns/subQuarters';
import endOfYear from 'date-fns/endOfYear';

import enUS from 'date-fns/locale/en-US';
import pt from 'date-fns/locale/pt';
import es from 'date-fns/locale/es';
import de from 'date-fns/locale/de';
import fr from 'date-fns/locale/fr';
import nl from 'date-fns/locale/nl';

import {
  getTimezoneOffset,
  utcToZonedTime,
  zonedTimeToUtc,
  toDate,
} from 'date-fns-tz';

import {
  TRANSACTION_STATUSES,
  DEFAULT_PAGE_SIZE,
  SHIFT_STATUSES,
  CHARGEBACK_STATUSES,
} from '../services/config';

import Tag from '@components/atoms/Tag';

import Amex from '@assets/icons/AmexIcon';
import ApplePay from '@assets/icons/ApplePayIcon';
import Discover from '@assets/icons/DiscoverIcon';
import Elo from '@assets/icons/EloIcon';
import Ideal from '@assets/icons/IdealIcon';
import Maestro from '@assets/icons/MaestroIcon';
import MasterCard from '@assets/icons/MasterCardIcon';
import MBWay from '@assets/icons/MBWayIcon';
import Multibanco from '@assets/icons/MultibancoIcon';
import PayPal from '@assets/icons/PayPalIcon';
import CarteBancaire from '@assets/icons/CarteBancaireIcon';
import Dankort from '@assets/icons/DankortIcon';
import DinersClub from '@assets/icons/DinersClubIcon';
import JCB from '@assets/icons/JCBIcon';
import VPay from '@assets/icons/VPayIcon';
import Visa from '@assets/icons/VisaIcon';
import Pix from '@assets/icons/PixIcon';
import Multicaixa from '@assets/icons/MulticaixaIcon';
import Floa from '@assets/icons/FloaIcon';
import Klarna from '@assets/icons/KlarnaIcon';
import RevolutPay from '@assets/icons/RevolutPayIcon';

export function setCookie(name, value, domain, expiration) {
  const date = expiration || new Date();
  if (!expiration) date.setMonth(date.getMonth() + 12);
  Cookies.set(name, value, { path: '/', domain, expires: date });
}

export function getTimeZoneId() {
  const timeZoneId =
    localStorage.getItem('CURRENT_TIMEZONE') ||
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  return timeZoneId;
}

export function getIsoTimezoneOffset() {
  return getTimezoneOffset(getTimeZoneId());
}

function formatOffset(offsetMs) {
  const offsetMinutes = Math.floor(offsetMs / 1000 / 60);
  const sign = offsetMinutes < 0 ? '-' : '+';
  const absoluteOffset = Math.abs(offsetMinutes);
  const hours = Math.floor(absoluteOffset / 60);
  const minutes = absoluteOffset % 60;
  return `${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
}

export function getOffsetDate(date) {
  const manualOffset = getIsoTimezoneOffset();
  const navigatorOffset = date.getTimezoneOffset();
  return new Date(manualOffset + navigatorOffset * 60 * 1000 + date.getTime());
}

export function dateStrToTimeZonedDate(dateStr) {
  const timeZoneId = getTimeZoneId();
  const utcDate = zonedTimeToUtc(
    toDate(dateStr, { timeZone: timeZoneId }),
    timeZoneId
  );
  const zonedDate = utcToZonedTime(utcDate, timeZoneId);
  const offsetDate = getOffsetDate(zonedDate);
  return offsetDate;
}

export function formatDate(date = new Date()) {
  let offset = formatOffset(getIsoTimezoneOffset());

  const formatted = format(date, "yyyy-MM-dd'T'HH:mm:ss") + offset;

  return formatted;
}

export function hasAtLeastOneElement(acceptedValues, currentValues) {
  for (let i = 0; i < currentValues.length; i++) {
    if (acceptedValues.includes(currentValues[i])) {
      return true;
    }
  }

  return false;
}

export function getPeriod(
  startDate = new Date(),
  endDate = new Date(),
  maintainDates = false
) {
  return {
    startDate: formatDate(maintainDates ? startDate : startOfDay(startDate)),
    endDate: formatDate(maintainDates ? endDate : endOfDay(endDate)),
  };
}

export function getRangePeriod({
  startDate = startOfToday(),
  interval = 'monthly',
  subtractPeriod = true,
} = {}) {
  const { sub, start, end } = {
    daily: { sub: subDays, start: startOfDay, end: endOfDay },
    weekly: { sub: subWeeks, start: startOfWeek, end: endOfWeek },
    monthly: { sub: subMonths, start: startOfMonth, end: endOfMonth },
    quarterly: { sub: subQuarters, start: startOfQuarter, end: endOfQuarter },
    yearly: { sub: subYears, start: startOfYear, end: endOfYear },
  }[interval];

  const returnValue = {
    startDate: formatDate(
      subtractPeriod ? sub(start(startDate), 1) : start(startDate)
    ),
    endDate: formatDate(end(startDate)),
  };

  return returnValue;
}

export function getPeriodMonth(date = new Date()) {
  return {
    startDate: formatDate(startOfMonth(date)),
    endDate: formatDate(endOfMonth(date)),
  };
}

export const hasRequiredActivityBranches = (
  isPaybyrd,
  activityBranches,
  requiredActivityBranches
) => {
  return (
    !!isPaybyrd ||
    hasAtLeastOneElement(requiredActivityBranches, activityBranches)
  );
};

export const range = (start, end) => {
  const range = [];

  for (let i = start; i < end; i++) {
    range.push(i);
  }

  return range;
};

export function getIconFromBrandCode(brandCode) {
  switch (brandCode) {
    case 'AMEX':
      return <Amex />;
    case 'APPLE':
      return <ApplePay />;
    case 'ELO':
      return <Elo />;
    case 'MASTER':
    case 'MASTERDEBIT':
    case 'MASTERCARD':
      return <MasterCard />;
    case 'PAYPAL':
      return <PayPal />;
    case 'VISAELECTRON':
    case 'VISADEBIT':
    case 'VISA':
      return <Visa />;
    case 'DISCOVER':
      return <Discover />;
    case 'SIBS_MULTIBANCO':
      return <Multibanco />;
    case 'SIBS_MBWAY':
    case 'MBWAY':
      return <MBWay />;
    case 'IDEAL':
      return <Ideal />;
    case 'MAESTRO':
      return <Maestro />;
    case 'CARTEBANCAIRE':
      return <CarteBancaire />;
    case 'DANKORT':
      return <Dankort />;
    case 'DINERS':
      return <DinersClub />;
    case 'JCB':
      return <JCB />;
    case 'VPAY':
      return <VPay />;
    case 'PIX':
      return <Pix />;
    case 'MULTICAIXA_REF':
      return <Multicaixa />;
    case 'FLOA3X':
    case 'FLOA4X':
    case 'FLOA1XD':
      return <Floa />;
    case 'KLARNA':
      return <Klarna />;
    case 'REVOLUTPAY':
      return <RevolutPay />;
    default:
      return null;
  }
}

export function getRelatedTransactionData(status = '', type = '', translator) {
  switch (status.toLowerCase()) {
    case TRANSACTION_STATUSES.PROCESSING:
    case TRANSACTION_STATUSES.ACQUIRERPROCESSING:
    case TRANSACTION_STATUSES.ACQUIRERTEMPORARYFAILED:
    case TRANSACTION_STATUSES.TEMPORARY_FAILED:
      return {
        label: translator.i18n('processing'),
        status: 'in-progress',
      };
    case TRANSACTION_STATUSES.DENIED:
      return {
        label: translator.i18n('denied'),
        status: 'canceled',
      };
    case TRANSACTION_STATUSES.SUCCESS:
    case TRANSACTION_STATUSES.ACQUIRERSUCCESS:
    case TRANSACTION_STATUSES.PAID: {
      if (type === 'refund') {
        return {
          label: translator.i18n('refunded'),
          status: 'completed',
        };
      }

      return {
        label: translator.i18n('paid'),
        status: 'completed',
      };
    }
    case TRANSACTION_STATUSES.PENDINGMERCHANTACTION:
    case TRANSACTION_STATUSES.PENDING:
      return {
        label: translator.i18n('pending'),
        status: 'in-progress',
      };
    case TRANSACTION_STATUSES.REFUNDED:
      return {
        label: translator.i18n('refunded'),
        status: 'completed',
      };
    case TRANSACTION_STATUSES.CANCELED:
      return {
        label: translator.i18n('canceled'),
        status: 'canceled',
      };
    case TRANSACTION_STATUSES.ERROR:
      return {
        label: translator.i18n('error'),
        status: 'canceled',
      };
    case TRANSACTION_STATUSES.FAILED:
    case TRANSACTION_STATUSES.ACQUIRERFAILED:
      return {
        label: translator.i18n('failed'),
        status: 'canceled',
      };
    case TRANSACTION_STATUSES.EXPIRED:
      return {
        label: translator.i18n('expired'),
        status: 'canceled',
      };
    case TRANSACTION_STATUSES.DECLINED:
      return {
        label: translator.i18n('declined'),
        status: 'canceled',
      };
    case TRANSACTION_STATUSES.CREATED:
      return {
        label: translator.i18n('created'),
        status: 'in-progress',
      };
    case SHIFT_STATUSES.CLOSED:
      return {
        label: translator.i18n('closed'),
        status: 'canceled',
      };
    case SHIFT_STATUSES.OPENED:
      return {
        label: translator.i18n('opened'),
        status: 'in-progress',
      };
    default:
      return {
        label: translator.i18n('noOrderInfo'),
        status: 'in-progress',
      };
  }
}

export function getLabelFromStatus(status = '', translator, showChip = true) {
  switch (status.toLowerCase()) {
    case TRANSACTION_STATUSES.PROCESSING:
    case TRANSACTION_STATUSES.ACQUIRERPROCESSING:
    case TRANSACTION_STATUSES.ACQUIRERTEMPORARYFAILED:
    case TRANSACTION_STATUSES.TEMPORARY_FAILED:
      return (
        <Tag
          showChip={showChip}
          size="small"
          label={translator.i18n('processing')}
          type="processing"
        />
      );
    case TRANSACTION_STATUSES.DENIED:
      return (
        <Tag
          size="small"
          showChip={showChip}
          label={translator.i18n('denied')}
          type="error"
        />
      );
    case TRANSACTION_STATUSES.SUCCESS:
    case TRANSACTION_STATUSES.ACQUIRERSUCCESS:
      return (
        <Tag
          size="small"
          showChip={showChip}
          label={translator.i18n('success')}
          type="success"
        />
      );
    case TRANSACTION_STATUSES.PENDINGMERCHANTACTION:
      return (
        <Tag
          size="small"
          showChip={showChip}
          label={translator.i18n('pending')}
          type="pending"
        />
      );
    case TRANSACTION_STATUSES.REFUNDED:
      return (
        <Tag
          size="small"
          showChip={showChip}
          label={translator.i18n('refunded')}
          type="error"
        />
      );
    case TRANSACTION_STATUSES.CANCELED:
      return (
        <Tag
          size="small"
          showChip={showChip}
          label={translator.i18n('canceled')}
          type="error"
        />
      );
    case TRANSACTION_STATUSES.ERROR:
      return (
        <Tag
          size="small"
          showChip={showChip}
          label={translator.i18n('error')}
          type="error"
        />
      );
    case TRANSACTION_STATUSES.FAILED:
      return (
        <Tag
          size="small"
          showChip={showChip}
          label={translator.i18n('failed')}
          type="error"
        />
      );
    case TRANSACTION_STATUSES.EXPIRED:
      return (
        <Tag
          size="small"
          showChip={showChip}
          label={translator.i18n('expired')}
          type="error"
        />
      );
    case TRANSACTION_STATUSES.PAID:
      return (
        <Tag
          size="small"
          showChip={showChip}
          label={translator.i18n('paid')}
          type="success"
        />
      );
    case TRANSACTION_STATUSES.ACQUIRERFAILED:
      return (
        <Tag
          size="small"
          showChip={showChip}
          label={translator.i18n('failed')}
          type="error"
        />
      );
    case TRANSACTION_STATUSES.DECLINED:
      return (
        <Tag
          size="small"
          showChip={showChip}
          label={translator.i18n('declined')}
          type="error"
        />
      );
    case TRANSACTION_STATUSES.CREATED:
      return (
        <Tag
          size="small"
          showChip={showChip}
          label={translator.i18n('created')}
          type="created"
        />
      );
    case TRANSACTION_STATUSES.PENDING:
      return (
        <Tag
          showChip={showChip}
          size="small"
          label={translator.i18n('pending')}
          type="processing"
        />
      );
    case SHIFT_STATUSES.CLOSED:
      return (
        <Tag
          size="small"
          showChip={showChip}
          label={translator.i18n('closed')}
          type="error"
        />
      );
    case SHIFT_STATUSES.OPENED:
      return (
        <Tag
          size="small"
          showChip={showChip}
          label={translator.i18n('opened')}
          type="created"
        />
      );
    default:
      return (
        <Tag
          size="small"
          showChip={showChip}
          label={translator.i18n('no-info')}
          type=""
        />
      );
  }
}

export function getChargebackLabelFromStatus(status, translator) {
  switch (status) {
    case CHARGEBACK_STATUSES.CLOSED:
      return (
        <Tag size="small" label={translator.i18n('closed')} type="error" />
      );
    case CHARGEBACK_STATUSES.CREATED:
      return (
        <Tag size="small" label={translator.i18n('created')} type="created" />
      );
    case CHARGEBACK_STATUSES.ACCEPTED:
      return (
        <Tag size="small" label={translator.i18n('accepted')} type="success" />
      );
    case CHARGEBACK_STATUSES.DISPUTING:
      return (
        <Tag size="small" label={translator.i18n('disputing')} type="pending" />
      );
    case CHARGEBACK_STATUSES.LOST:
      return <Tag size="small" label={translator.i18n('lost')} type="error" />;
    case CHARGEBACK_STATUSES.WON:
      return <Tag size="small" label={translator.i18n('won')} type="success" />;
    case CHARGEBACK_STATUSES.PENDING_ACTION:
      return (
        <Tag
          size="small"
          label={translator.i18n('pendingaction')}
          type="pending"
        />
      );
    default:
      return <Tag size="small" label={translator.i18n('no-info')} type="" />;
  }
}

export function getCurrencySymbol(currency) {
  const currency_symbols = {
    AED: 'د.إ', // United Arab Emirates Dirham
    AFN: '؋', // Afghanistan Afghani
    ALL: 'Lek', // Albania Lek
    ANG: 'ƒ', // Netherlands Antilles Guilder
    ARS: '$', // Argentina Peso
    AMD: '֏', // Armenian Dram
    AUD: '$', // Australia Dollar
    AOA: 'Kz', // Angolan Kwanza
    AWG: 'ƒ', // Aruba Guilder
    AZN: '₼', // Azerbaijan Manat
    BAM: 'KM', // Bosnia and Herzegovina Convertible Mark
    BBD: '$', // Barbados Dollar
    BDT: 'Tk', // Bangladeshi Taka
    BIF: 'Fbu', // Burundian Franc
    BHD: 'BD', // Bahraini Dinar
    BGN: 'лв', // Bulgaria Lev
    BMD: '$', // Bermuda Dollar
    BND: '$', // Brunei Darussalam Dollar
    BOB: '$b', // Bolivia Bolíviano
    BRL: 'R$', // Brazil Real
    BSD: '$', // Bahamas Dollar
    BTN: 'Nu', // Bhutanese Ngultrum
    BWP: 'P', // Botswana Pula
    BYN: 'Br', // Belarus Ruble
    BZD: 'BZ$', // Belize Dollar
    CAD: '$', // Canada Dollar
    CDF: 'FC', // Congolese Franc
    CHF: 'CHF', // Switzerland Franc
    CLP: '$', // Chile Peso
    CNY: '¥', // China Yuan Renminbi
    COP: '$', // Colombia Peso
    CRC: '₡', // Costa Rica Colon
    CUP: '₱', // Cuba Peso
    CVE: '$', // Cape Verde Escudo
    CZK: 'Kč', // Czech Republic Koruna
    DJF: 'Fdj', // Djiboutian Franc
    DKK: 'kr', // Denmark Krone
    DOP: 'RD$', // Dominican Republic Peso
    DZD: 'DA', // Algerian Dinar
    ERN: 'Nkf', // Eritrean Nafka
    ETB: 'Br', // Ethiopian Birr
    EGP: '£', // Egypt Pound
    EUR: '€', // Euro Member Countries
    FJD: '$', // Fiji Dollar
    FKP: '£', // Falkland Islands (Malvinas) Pound
    GBP: '£', // United Kingdom Pound
    GGP: '£', // Guernsey Pound
    GHS: '¢', // Ghana Cedi
    GIP: '£', // Gibraltar Pound
    GMD: 'D', // Gambian Dalasi
    GNF: 'FG', // Guinean Franc
    GTQ: 'Q', // Guatemala Quetzal
    GYD: '$', // Guyana Dollar
    HKD: '$', // Hong Kong Dollar
    HNL: 'L', // Honduras Lempira
    HRK: 'kn', // Croatia Kuna
    HTG: 'G', // Haitian Gourde
    HUF: 'Ft', // Hungary Forint
    IDR: 'Rp', // Indonesia Rupiah
    ILS: '₪', // Israel Shekel
    IMP: '£', // Isle of Man Pound
    INR: '₹', // India Rupee
    IQD: 'ع.د', // Iraqi Dinar
    IRR: '﷼', // Iran Rial
    ISK: 'kr', // Iceland Krona
    JEP: '£', // Jersey Pound
    JMD: 'J$', // Jamaica Dollar
    JOD: 'JD', // Jordan Dinar
    JPY: '¥', // Japan Yen
    KES: 'KSh', // Kenyan Shilling
    KGS: 'лв', // Kyrgyzstan Som
    KHR: '៛', // Cambodia Riel
    KMF: 'CF', // Comorian Franc
    KPW: '₩', // Korea (North) Won
    KRW: '₩', // Korea (South) Won
    KWD: 'KD', // Kuwaiti Dinar
    KYD: '$', // Cayman Islands Dollar
    KZT: 'лв', // Kazakhstan Tenge
    LAK: '₭', // Laos Kip
    LBP: '£', // Lebanon Pound
    LKR: '₨', // Sri Lanka Rupee
    LRD: '$', // Liberia Dollar
    LSL: 'M', // Lesotho Loti
    LYD: 'LD', // Libya Dinar
    MAD: 'DH', // Morocco Dirham
    MDL: 'L', // Moldova Leu
    MGA: 'Ar', // Madagascar Ariary
    MKD: 'ден', // Macedonia Denar
    MMK: 'K', // Burmese Kyat
    MOP: '$', // Macau Pataca
    MRU: 'UM', // Mauritanian Ouguiya
    MNT: '₮', // Mongolia Tughrik
    MUR: '₨', // Mauritius Rupee
    MVR: 'Rf', // Maldives (Maldive Islands) Rufiyaa
    MWK: 'MK', // Malawi Kwacha
    MXN: '$', // Mexico Peso
    MYR: 'RM', // Malaysia Ringgit
    MZN: 'MT', // Mozambique Metical
    NAD: '$', // Namibia Dollar
    NGN: '₦', // Nigeria Naira
    NIO: 'C$', // Nicaragua Cordoba
    NOK: 'kr', // Norway Krone
    NPR: '₨', // Nepal Rupee
    NZD: '$', // New Zealand Dollar
    OMR: '﷼', // Oman Rial
    PAB: 'B/.', // Panama Balboa
    PEN: 'S/.', // Peru Sol
    PGK: 'K', // Papua New Guinean Kina
    PHP: '₱', // Philippines Peso
    PKR: '₨', // Pakistan Rupee
    PLN: 'zł', // Poland Zloty
    PYG: 'Gs', // Paraguay Guarani
    QAR: '﷼', // Qatar Riyal
    RON: 'lei', // Romania Leu
    RWF: 'R₣', // Rwandan Franc
    RSD: 'Дин.', // Serbia Dinar
    RUB: '₽', // Russia Ruble
    SAR: '﷼', // Saudi Arabia Riyal
    SBD: '$', // Solomon Islands Dollar
    SCR: '₨', // Seychelles Rupee
    SDG: 'ج.س.', // Sudanese Pound
    SEK: 'kr', // Sweden Krona
    SGD: '$', // Singapore Dollar
    SHP: '£', // Saint Helena Pound
    SLL: 'Le', // Sierra Leonean Leone
    SOS: 'S', // Somalia Shilling
    STN: 'Db', // Sao Tomean Dobra
    SRD: '$', // Suriname Dollar
    SVC: '$', // El Salvador Colon
    SYP: '£', // Syria Pound
    SZL: 'E', // Swazi Lilangeni
    THB: '฿', // Thailand Baht
    TJS: 'SM', // Tajikistan Somoni
    TMT: 'T', // Turkmenistan Manat
    TND: 'د.ت', // Tunisia Dinar
    TOP: 'T$', // Tonga Pa'anga
    TRY: '₺', // Turkey Lira
    TTD: 'TT$', // Trinidad and Tobago Dollar
    TVD: '$', // Tuvalu Dollar
    TWD: 'NT$', // Taiwan New Dollar
    UAH: '₴', // Ukraine Hryvnia
    USD: '$', // United States Dollar
    UYU: '$U', // Uruguay Peso
    UZS: 'лв', // Uzbekistan Som
    VEF: 'Bs', // Venezuela Bolívar
    VND: '₫', // Viet Nam Dong
    VUV: 'VT', // Vanuatu Vatu
    WST: 'WS$', // Samoa Tala
    XCD: '$', // East Caribbean Dollar
    YER: '﷼', // Yemen Rial
    ZAR: 'R', // South Africa Rand
    ZMW: 'ZK', // Zambia Kwacha
    ZWD: 'Z$', // Zimbabwe Dollar
  };

  if (currency) {
    return currency_symbols[currency] || '€';
  }

  const storedCurrency = Cookies.get('defaultCurrency');
  const defaultCurrency = storedCurrency ? storedCurrency : 'EUR';

  if (currency_symbols[defaultCurrency] !== undefined) {
    return currency_symbols[defaultCurrency];
  }

  return '€';
}

export function getCurrencyName(currency) {
  const currency_symbols = {
    AED: 'United Arab Emirates Dirham',
    AFN: 'Afghanistan Afghani',
    ALL: 'Albania Lek',
    ANG: 'Netherlands Antilles Guilder',
    ARS: 'Argentina Peso',
    AMD: 'Armenian Dram',
    AUD: 'Australia Dollar',
    AOA: 'Angolan Kwanza',
    AWG: 'Aruba Guilder',
    AZN: 'Azerbaijan Manat',
    BAM: 'Bosnia and Herzegovina Convertible Mark',
    BBD: 'Barbados Dollar',
    BDT: 'Bangladeshi Taka',
    BIF: 'Burundian Franc',
    BHD: 'Bahraini Dinar',
    BGN: 'Bulgaria Lev',
    BMD: 'Bermuda Dollar',
    BND: 'Brunei Darussalam Dollar',
    BOB: 'Bolivia Bolíviano',
    BRL: 'Brazil Real',
    BSD: 'Bahamas Dollar',
    BTN: 'Bhutanese Ngultrum',
    BWP: 'Botswana Pula',
    BYN: 'Belarus Ruble',
    BZD: 'Belize Dollar',
    CAD: 'Canada Dollar',
    CDF: 'Congolese Franc',
    CHF: 'Switzerland Franc',
    CLP: 'Chile Peso',
    CNY: 'China Yuan Renminbi',
    COP: 'Colombia Peso',
    CRC: 'Costa Rica Colon',
    CUP: 'Cuba Peso',
    CVE: 'Cape Verde Escudo',
    CZK: 'Czech Republic Koruna',
    DJF: 'Djiboutian Franc',
    DKK: 'Denmark Krone',
    DOP: 'Dominican Republic Peso',
    DZD: 'Algerian Dinar',
    ERN: 'Eritrean Nafka',
    ETB: 'Ethiopian Birr',
    EGP: 'Egypt Pound',
    EUR: 'Euro',
    FJD: 'Fiji Dollar',
    FKP: 'Falkland Islands (Malvinas) Pound',
    GBP: 'United Kingdom Pound',
    GGP: 'Guernsey Pound',
    GHS: 'Ghana Cedi',
    GIP: 'Gibraltar Pound',
    GMD: 'Gambian Dalasi',
    GNF: 'Guinean Franc',
    GTQ: 'Guatemala Quetzal',
    GYD: 'Guyana Dollar',
    HKD: 'Hong Kong Dollar',
    HNL: 'Honduras Lempira',
    HRK: 'Croatia Kuna',
    HTG: 'Haitian Gourde',
    HUF: 'Hungary Forint',
    IDR: 'Indonesia Rupiah',
    ILS: 'Israel Shekel',
    IMP: 'Isle of Man Pound',
    INR: 'India Rupee',
    IQD: 'Iraqi Dinar',
    IRR: 'Iran Rial',
    ISK: 'Iceland Krona',
    JEP: 'Jersey Pound',
    JMD: 'Jamaica Dollar',
    JOD: 'Jordan Dinar',
    JPY: 'Japan Yen',
    KES: 'Kenyan Shilling',
    KGS: 'Kyrgyzstan Som',
    KHR: 'Cambodia Riel',
    KMF: 'Comorian Franc',
    KPW: 'Korea (North) Won',
    KRW: 'Korea (South) Won',
    KWD: 'Kuwaiti Dinar',
    KYD: 'Cayman Islands Dollar',
    KZT: 'Kazakhstan Tenge',
    LAK: 'Laos Kip',
    LBP: 'Lebanon Pound',
    LKR: 'Sri Lanka Rupee',
    LRD: 'Liberia Dollar',
    LSL: 'Lesotho Loti',
    LYD: 'Libya Dinar',
    MAD: 'Morocco Dirham',
    MDL: 'Moldova Leu',
    MGA: 'Madagascar Ariary',
    MKD: 'Macedonia Denar',
    MMK: 'Burmese Kyat',
    MOP: 'Macau Pataca',
    MRU: 'Mauritanian Ouguiya',
    MNT: 'Mongolia Tughrik',
    MUR: 'Mauritius Rupee',
    MVR: 'Maldives (Maldive Islands) Rufiyaa',
    MWK: 'Malawi Kwacha',
    MXN: 'Mexico Peso',
    MYR: 'Malaysia Ringgit',
    MZN: 'Mozambique Metical',
    NAD: 'Namibia Dollar',
    NGN: 'Nigeria Naira',
    NIO: 'Nicaragua Cordoba',
    NOK: 'Norway Krone',
    NPR: 'Nepal Rupee',
    NZD: 'New Zealand Dollar',
    OMR: 'Oman Rial',
    PAB: 'Panama Balboa',
    PEN: 'Peru Sol',
    PGK: 'Papua New Guinean Kina',
    PHP: 'Philippines Peso',
    PKR: 'Pakistan Rupee',
    PLN: 'Poland Zloty',
    PYG: 'Paraguay Guarani',
    QAR: 'Qatar Riyal',
    RON: 'Romania Leu',
    RWF: 'Rwandan Franc',
    RSD: 'Serbia Dinar',
    RUB: 'Russia Ruble',
    SAR: 'Saudi Arabia Riyal',
    SBD: 'Solomon Islands Dollar',
    SCR: 'Seychelles Rupee',
    SDG: 'Sudanese Pound',
    SEK: 'Sweden Krona',
    SGD: 'Singapore Dollar',
    SHP: 'Saint Helena Pound',
    SLL: 'Sierra Leonean Leone',
    SOS: 'Somalia Shilling',
    STN: 'Sao Tomean Dobra',
    SRD: 'Suriname Dollar',
    SVC: 'El Salvador Colon',
    SYP: 'Syria Pound',
    SZL: 'Swazi Lilangeni',
    THB: 'Thailand Baht',
    TJS: 'Tajikistan Somoni',
    TMT: 'Turkmenistan Manat',
    TND: 'Tunisia Dinar',
    TOP: "Tonga Pa'anga",
    TRY: 'Turkey Lira',
    TTD: 'Trinidad and Tobago Dollar',
    TVD: 'Tuvalu Dollar',
    TWD: 'Taiwan New Dollar',
    UAH: 'Ukraine Hryvnia',
    USD: 'United States Dollar',
    UYU: 'Uruguay Peso',
    UZS: 'Uzbekistan Som',
    VEF: 'Venezuela Bolívar',
    VND: 'Viet Nam Dong',
    VUV: 'Vanuatu Vatu',
    WST: 'Samoa Tala',
    XCD: 'East Caribbean Dollar',
    YER: 'Yemen Rial',
    ZAR: 'South Africa Rand',
    ZMW: 'Zambia Kwacha',
    ZWD: 'Zimbabwe Dollar',
  };
  return currency_symbols[currency] || 'Euro';
}

export function currencyFormat(amount = 0, locale, currency, precision = 2) {
  let defaultCurrency;

  if (!currency) {
    const storedCurrency = Cookies.get('defaultCurrency');
    defaultCurrency = storedCurrency ? storedCurrency : 'EUR';
  } else {
    defaultCurrency = currency;
  }

  let storedLocale = 'pt-PT';
  if (!locale) {
    storedLocale =
      localStorage.getItem('SYSTEM_LANGUAGE') || navigator.language || 'pt-PT';
  }

  const numberFormat = new Intl.NumberFormat(storedLocale, {
    style: 'currency',
    currencyDisplay: 'symbol',
    currency: defaultCurrency,
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });

  return numberFormat.format(isNaN(amount) ? 0 : amount);
}

export function isRefundable({ allowedOperations }) {
  return (
    allowedOperations &&
    Array.isArray(allowedOperations) &&
    allowedOperations.map((x) => x.toLowerCase()).includes('refund')
  );
}

export function isCapturable({ allowedOperations }) {
  return (
    allowedOperations &&
    Array.isArray(allowedOperations) &&
    allowedOperations.map((x) => x.toLowerCase()).includes('capture')
  );
}

export function isChargebackAllowed({ allowedOperations }) {
  return (
    allowedOperations &&
    Array.isArray(allowedOperations) &&
    allowedOperations.map((x) => x.toLowerCase()).includes('chargeback')
  );
}

export function isReversable({ allowedOperations }) {
  return (
    allowedOperations &&
    Array.isArray(allowedOperations) &&
    allowedOperations.map((x) => x.toLowerCase()).includes('reversal')
  );
}

export const getParamsFromFilters = ({
  query,
  period: { startDate, endDate },
  units,
  statuses,
  channel,
  pageSize = DEFAULT_PAGE_SIZE,
  pageNumber = 1,
  journalType,
  ...filters
}) => {
  let newStatuses = statuses;

  if (statuses && statuses.includes(TRANSACTION_STATUSES.PROCESSING)) {
    if (!statuses.includes(TRANSACTION_STATUSES.ACQUIRERPROCESSING)) {
      newStatuses = newStatuses.concat([
        TRANSACTION_STATUSES.ACQUIRERPROCESSING,
      ]);
    }

    if (!statuses.includes(TRANSACTION_STATUSES.ACQUIRERTEMPORARYFAILED)) {
      newStatuses = newStatuses.concat([
        TRANSACTION_STATUSES.ACQUIRERTEMPORARYFAILED,
      ]);
    }
  }

  if (statuses && !statuses.includes(TRANSACTION_STATUSES.PROCESSING)) {
    newStatuses = newStatuses.filter(
      (x) =>
        x !== TRANSACTION_STATUSES.ACQUIRERPROCESSING &&
        x !== TRANSACTION_STATUSES.ACQUIRERTEMPORARYFAILED
    );
  }

  return {
    ...filters,
    query,
    endDate,
    startDate,
    sortField: 'JournalDate',
    merchantIds: units,
    journalType,
    journalStates: newStatuses,
    initiatedFrom: channel === '' ? null : channel,
    sortDescending: true,
    pageSize,
    pageNumber,
  };
};

export function storeTimezone(timezone) {
  localStorage.setItem('CURRENT_TIMEZONE', timezone);
}

export function refreshTimeZoneSpecs() {
  let timeZoneId = localStorage.getItem('CURRENT_TIMEZONE');
  if (!timeZoneId) {
    timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
    storeTimezone(timeZoneId);
  }

  return timeZoneId;
}

export function formatDateTimezone(date, { format, onlyDate } = {}) {
  if (!date) {
    return '-';
  }

  const currentLocale =
    localStorage.getItem('SYSTEM_LANGUAGE') || navigator.language || 'en';
  const localeFormat =
    currentLocale === 'en'
      ? `mm/dd/yyyy${!onlyDate ? ' hh:MM tt' : ''}`
      : `dd/mm/yyyy${!onlyDate ? ' HH:MM' : ''}`;
  const timeZoneId = getTimeZoneId();

  return dateFormat(utcToZonedTime(date, timeZoneId), format || localeFormat);
}

export function getCultures(merchants) {
  if (merchants) {
    return merchants.map(({ personId, businessName, messageTemplates }) => {
      let cultures = [{ value: 'en-US', label: 'en-US' }];

      if (messageTemplates) {
        const { culture = '' } =
          messageTemplates.find(
            (template) => template.context === 1 && template.type === 1
          ) || {};

        cultures = culture.split(',').map((c) => ({ value: c, label: c }));
      }

      return { value: personId, label: businessName, cultures };
    });
  }

  return null;
}

export function storeLocale(locale) {
  localStorage.setItem('SYSTEM_LANGUAGE', locale);
}

export function getYearRange(date) {
  if (date && isValid(date)) {
    const start = startOfYear(date);
    return eachYearOfInterval({
      start: subYears(start, 2),
      end: start,
    });
  }

  return null;
}

export function getMonthRange(date, sub = 10, add = 10) {
  if (date && isValid(date)) {
    const start = startOfMonth(date);
    return eachMonthOfInterval({
      start: sub > 0 ? subMonths(start, sub) : start,
      end: add > 0 ? addMonths(start, add) : start,
    });
  }

  return null;
}

export function getDateLocale(locale = 'en') {
  const locales = {
    'en-US': enUS,
    en: enUS,
    pt,
    es,
    de,
    fr,
    nl,
  };

  return locales[locale];
}

export const isForbidden = (status) => {
  const hasForbiddenStatus = status === 401 || status === 403;
  const hasForbiddenURL = location.hash.includes('error=403');

  return hasForbiddenStatus && !hasForbiddenURL;
};

export const handleAPIError = (status) => {
  const errors = [401, 403];
  const alreadyHasErrors = location.hash && location.hash.includes('error=');

  if (errors.includes(status) && !alreadyHasErrors) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('error', status);
    const newRelativePathQuery = `${location.hash}?${searchParams.toString()}`;
    history.pushState(null, '', newRelativePathQuery);
  }
};

export const clearAllIntervals = () => {
  const id = window.setInterval(() => {}, Number.MAX_SAFE_INTEGER);

  for (let i = 1; i < id; i++) {
    window.clearInterval(i);
  }
};

export const getType = (type) => {
  switch (type) {
    case 'PreAuth':
      return 'pre-auth';
    case 'Payment':
      return 'payments';
    case 'Refund':
      return 'refund';
    case 'Capture':
      return 'capture';
    default:
      return '';
  }
};

export const validateGUID = (guid) => {
  const regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return regex.test(guid);
};

export const formatApiDate = (apiDate) => {
  const dt = new Date();
  const date = parseISO(apiDate);
  const adjustedDate = new Date(
    date.valueOf() + dt.getTimezoneOffset() * 60 * 1000
  );
  return format(adjustedDate, 'dd/MM/yyyy HH:mm');
};

export function isSubset(arr1, arr2) {
  return arr1.every((item) => arr2.includes(item));
}

export function notEmpty(value) {
  if (value === null || value === undefined) {
    return false;
  }

  if (Array.isArray(value)) {
    return value.length > 0 && value?.filter((v) => v).length > 0;
  }

  if (typeof value === 'object') {
    return Object.keys(value).length > 0;
  }

  if (typeof value === 'boolean') {
    return true;
  }

  return value.length > 0;
}

export function translate(translationKey, fallback = '', translator) {
  const translated = translator.i18n(translationKey);
  const translateFallback = (translator, fallback) => {
    const fallbackTranslated = translator.i18n(fallback.toLowerCase());
    return fallbackTranslated === fallback.toLowerCase()
      ? translator.i18n(fallback)
      : fallbackTranslated;
  };

  if (!fallback) {
    return translated;
  }

  return translated === translationKey
    ? translateFallback(translator, fallback)
    : translated;
}

export const capitalizeFirstLetter = (string = '') => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export function hexAToRGBA(h) {
  let r = 0,
    g = 0,
    b = 0,
    a = 1;

  if (h.length == 5) {
    r = '0x' + h[1] + h[1];
    g = '0x' + h[2] + h[2];
    b = '0x' + h[3] + h[3];
    a = '0x' + h[4] + h[4];
  } else if (h.length == 9) {
    r = '0x' + h[1] + h[2];
    g = '0x' + h[3] + h[4];
    b = '0x' + h[5] + h[6];
    a = '0x' + h[7] + h[8];
  }

  a = +(a / 255).toFixed(3);

  return 'rgba(' + +r + ',' + +g + ',' + +b + ',' + a + ')';
}
