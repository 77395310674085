import React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

export default function NumberInput(props) {
  const { prefix, inputRef, onChange, max, precision = 2, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        if (max && event.target.value.length > max) {
          return;
        }

        return onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalSeparator=","
      isNumericString
      prefix={prefix}
      decimalScale={precision}
    />
  );
}

NumberInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
