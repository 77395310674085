import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';

import MenuItem, { MenuItemChild } from '../MenuItem';

import { useUser } from '@hooks/useUser';

export function Menu({ topItems, bottomItems, onClose }) {
  const history = useHistory();
  const { userHasScope } = useUser();
  const { pathname } = useLocation();
  const [currentMenuIndex, setCurrentMenu] = useState('dashboard');

  const redirectToRoute = (route, slug) => {
    setCurrentMenu(slug);
    return history.push(`/${route}`);
  };

  const openMenu = (slug) => {
    setCurrentMenu(slug);
  };

  useEffect(() => {
    const selectedItem = topItems
      .concat(bottomItems)
      .flatMap(
        (parent) =>
          parent.children?.map((child) => ({
            slug: parent.slug,
            childSlug: child.slug,
          })) ?? [
            {
              slug: parent.slug,
              childSlug: parent.slug,
            },
          ]
      )
      .filter((item) => pathname.replace(/\//g, '') === item.childSlug);

    if (selectedItem.length === 1) {
      setCurrentMenu(selectedItem[0].slug);
    }
  }, [pathname]);

  const isRouteSelected = (children, parentIndex) => {
    if (!parentIndex) {
      return children?.filter((child) => pathname.includes(child.slug))?.length > 0;
    }
    return pathname.split('/').indexOf(children.slug) > -1;
  };

  const getItem = ({ label, icon, slug, permissions, children, onClick }) => {
    if (children?.length > 0) {
      const containsPermissions = children.some((child) => child.permissions);
      const hasSomePermission = children.some((child) => userHasScope(child.permissions));

      if (!containsPermissions || hasSomePermission) {
        return (
          <div key={label}>
            <MenuItem
              hasChilds={true}
              key={label}
              label={label}
              icon={icon}
              open={currentMenuIndex === slug}
              slug={slug}
              selected={currentMenuIndex === slug}
              onClick={openMenu}
            />
            <Collapse
              in={currentMenuIndex === slug}
              timeout="auto"
              unmountOnExit
              sx={{
                ...(!(currentMenuIndex === slug) && {
                  '& > div > div > div': {
                    paddingLeft: '25px !important',

                    '& span': {
                      display: 'none',
                    },
                  },
                }),
              }}
            >
              {children.map(
                (child) =>
                  (!child.permissions || userHasScope(child.permissions)) && (
                    <MenuItemChild
                      key={child.label}
                      label={child.label}
                      icon={child.icon}
                      slug={child.slug}
                      selected={isRouteSelected(child, slug)}
                      onClick={(route) => {
                        onClose && onClose();
                        redirectToRoute(route, slug);
                      }}
                    />
                  )
              )}
            </Collapse>
          </div>
        );
      }

      return;
    }

    return (
      (!permissions || userHasScope(permissions)) && (
        <MenuItem
          hasChilds={false}
          key={label}
          label={label}
          icon={icon}
          open={currentMenuIndex === slug}
          slug={slug}
          selected={currentMenuIndex === slug}
          onClick={(route) => {
            onClose && onClose();
            (onClick && onClick(route)) ?? redirectToRoute(route, slug);
          }}
        />
      )
    );
  };

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <List disablePadding={true} sx={{ marginTop: 0, flexGrow: 1, overflowY: 'visible', boxSizing: 'border-box' }}>
        {topItems.map((item) => getItem(item))}
      </List>
      <List disablePadding={true} sx={{ marginTop: 0 }}>
        {bottomItems.map((item) => getItem(item))}
      </List>
    </Box>
  );
}

export default Menu;
