import React, { Fragment, useContext } from 'react';
import { BananaContext } from '@wikimedia/react.i18n';
import Box from '@mui/material/Box';

import { formatDateTimezone } from 'utils';

import { useStyles } from './style';

export function OnboardingDetail({ onboarding }) {
  const translator = useContext(BananaContext);
  const classes = useStyles();
  const {
    externalId,
    reference,
    channel,
    status,
    createdAtUtc,
    partnerEmail,
    userEmail,
    contactPerson,
    firstName,
    lastName,
  } = onboarding || {};

  return (
    <Fragment>
      <Box component="dl" className={classes.definitions}>
        <Box className={classes.definition}>
          <Box component="dt" className={classes.title}>
            {translator.i18n('external-id')}
          </Box>
          <Box component="dd">{externalId}</Box>
        </Box>
        <Box className={classes.definition}>
          <Box component="dt" className={classes.title}>
            {translator.i18n('reference')}
          </Box>
          <Box component="dd">{reference}</Box>
        </Box>
        <Box className={classes.definition}>
          <Box component="dt" className={classes.title}>
            {translator.i18n('mode')}
          </Box>
          <Box component="dd">{channel}</Box>
        </Box>
        <Box className={classes.definition}>
          <Box component="dt" className={classes.title}>
            {translator.i18n('status')}
          </Box>
          <Box component="dd">{status}</Box>
        </Box>
        <Box className={classes.definition}>
          <Box component="dt" className={classes.title}>
            {translator.i18n('date')}
          </Box>
          <Box component="dd">{formatDateTimezone(createdAtUtc)}</Box>
        </Box>
        <Box className={classes.definition}>
          <Box component="dt" className={classes.title}>
            {translator.i18n('partner-email')}
          </Box>
          <Box component="dd">{partnerEmail}</Box>
        </Box>
        <Box className={classes.definition}>
          <Box component="dt" className={classes.title}>
            {translator.i18n('user-email')}
          </Box>
          <Box component="dd">{userEmail}</Box>
        </Box>
        <Box className={classes.definition}>
          <Box component="dt" className={classes.title}>
            {translator.i18n('contact-person')}
          </Box>
          <Box component="dd">{contactPerson}</Box>
        </Box>
        <Box className={classes.definition}>
          <Box component="dt" className={classes.title}>
            {translator.i18n('firstname')}
          </Box>
          <Box component="dd" style={{ textTransform: 'capitalize' }}>
            {firstName}
          </Box>
        </Box>
        <Box className={classes.definition}>
          <Box component="dt" className={classes.title}>
            {translator.i18n('lastname')}
          </Box>
          <Box component="dd" style={{ textTransform: 'capitalize' }}>
            {lastName}
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
}

export default OnboardingDetail;
