import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { createSagaAction } from 'saga-toolkit';

import getWeek from 'date-fns/getWeek';
import { reducePeriod, getQuarterReference } from './utils';
import { getRangePeriod } from 'utils';

const name = 'statistics';

export const actions = {
  FETCH: `${name}/fetchStatistics`,
  FETCH_TABLE: `${name}/fetchTableStatistics`,
  FETCH_CHART: `${name}/fetchChartStatistics`,
};

export const fetchStatistics = createSagaAction(actions.FETCH);
export const fetchTableStatistics = createSagaAction(actions.FETCH_TABLE);
export const fetchChartStatistics = createSagaAction(actions.FETCH_CHART);

export const statisticsSlice = createSlice({
  name,
  initialState: {
    chart: {
      loading: true,
      data: [],
      error: null,
      success: null,
      filters: {
        period: getRangePeriod(),
        interval: 'daily',
      },
    },
    dashboard: {
      loading: true,
      data: [],
      error: null,
      success: null,
      filters: {
        period: getRangePeriod(),
        interval: 'monthly',
        stores: [],
      },
    },
    table: {
      loading: true,
      data: [],
      error: null,
      success: null,
      filters: {
        period: getRangePeriod(),
        interval: 'daily',
        groupBy: 'brand',
      },
    },
  },
  reducers: {
    setFilters: (state, { payload }) => {
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          filters: payload.filters,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(fetchStatistics.pending, fetchTableStatistics.pending, fetchChartStatistics.pending),
        (state, { meta: { arg } }) => {
          if (state[arg.type].loading === false) {
            state[arg.type].loading = true;
          }
        }
      )
      .addMatcher(
        isAnyOf(fetchStatistics.fulfilled, fetchTableStatistics.fulfilled, fetchChartStatistics.fulfilled),
        (state, { payload: data, meta: { arg } }) => {
          let formattedData = data;

          // Normalize chart and dashboard data when filtering by hour
          if (
            (arg.filters.interval === 'hourly' && arg.type === 'chart') ||
            (arg.filters.interval === 'daily' && arg.type === 'dashboard')
          ) {
            formattedData = [];

            const currentDayIndex = Number(arg.filters.endDate.substr(8, 2));
            const prevHoursDay = [];
            const currentHoursDay = [];

            let hasMultipleDays = true;

            Object.values(data).map((hour, index) => {
              if ((index === 0 && hour.referenceDay) === currentDayIndex) {
                hasMultipleDays = false;

                if (currentDayIndex === hour.referenceDay) {
                  formattedData.push([]);
                  return formattedData.push(arg.type === 'dashboard' ? reducePeriod(data) : data);
                } else {
                  formattedData.push(arg.type === 'dashboard' ? reducePeriod(data) : data);
                  return formattedData.push([]);
                }
              }

              if (hour.referenceDay !== currentDayIndex) {
                return prevHoursDay.push(hour);
              }

              return currentHoursDay.push(hour);
            });

            if (hasMultipleDays) {
              formattedData.push(arg.type === 'dashboard' ? reducePeriod(prevHoursDay) : prevHoursDay);
              formattedData.push(arg.type === 'dashboard' ? reducePeriod(currentHoursDay) : currentHoursDay);
            }

            return {
              ...state,
              [arg.type]: {
                ...state[arg.type],
                data: formattedData,
                loading: false,
              },
            };
          }

          // Normalize chart and dashboard data when filtering by week
          if (state.dashboard.filters.interval === 'weekly' && arg.type !== 'table') {
            formattedData = [];

            const currentWeekIndex =
              data.length &&
              getWeek(
                new Date(
                  Number(arg.filters.endDate.substr(0, 4)),
                  Number(arg.filters.endDate.substr(5, 2)) - 1,
                  Number(arg.filters.endDate.substr(8, 2))
                )
              );
            const prevWeekDays = [];
            const currentWeekDays = [];

            let hasMultipleWeeks = true;

            Object.values(data).map((day, index) => {
              const referenceWeekIndex =
                day.referenceWeek || getWeek(new Date(day.referenceYear, day.referenceMonth - 1, day.referenceDay));

              if ((index === 0 && referenceWeekIndex) === currentWeekIndex) {
                hasMultipleWeeks = false;

                if (currentWeekIndex === referenceWeekIndex) {
                  formattedData.push([]);
                  return formattedData.push(arg.type === 'dashboard' ? reducePeriod(data) : data);
                } else {
                  formattedData.push(arg.type === 'dashboard' ? reducePeriod(data) : data);
                  return formattedData.push([]);
                }
              }

              if (referenceWeekIndex !== currentWeekIndex) {
                return prevWeekDays.push(day);
              }

              return currentWeekDays.push(day);
            });

            if (hasMultipleWeeks) {
              formattedData.push(arg.type === 'dashboard' ? reducePeriod(prevWeekDays) : prevWeekDays);
              formattedData.push(arg.type === 'dashboard' ? reducePeriod(currentWeekDays) : currentWeekDays);
            }

            return {
              ...state,
              [arg.type]: {
                ...state[arg.type],
                data: formattedData,
                loading: false,
              },
            };
          }

          // Normalize chart and dashboard data when filtering by quarters
          if (state.dashboard.filters.interval === 'quarterly' && arg.type !== 'table') {
            formattedData = [];

            const currentQuarterIndex = getQuarterReference(Number(arg.filters.endDate.substr(5, 2)));
            const prevQuarter = [];
            const currentQuarter = [];

            let hasMultipleQuarters = true;

            Object.values(data).map((month, index) => {
              if (
                index === 0 &&
                getQuarterReference(month.referenceMonth) === getQuarterReference(data[data.length - 1].referenceMonth)
              ) {
                hasMultipleQuarters = false;

                if (currentQuarterIndex === getQuarterReference(month.referenceMonth)) {
                  formattedData.push([]);
                  return formattedData.push(arg.type === 'dashboard' ? reducePeriod(data) : data);
                } else {
                  formattedData.push(arg.type === 'dashboard' ? reducePeriod(data) : data);
                  return formattedData.push([]);
                }
              }

              if (getQuarterReference(month.referenceMonth) !== currentQuarterIndex) {
                return prevQuarter.push(month);
              }

              return currentQuarter.push(month);
            });

            if (hasMultipleQuarters) {
              formattedData.push(arg.type === 'dashboard' ? reducePeriod(prevQuarter) : prevQuarter);
              formattedData.push(arg.type === 'dashboard' ? reducePeriod(currentQuarter) : currentQuarter);
            }

            return {
              ...state,
              [arg.type]: {
                ...state[arg.type],
                data: formattedData,
                loading: false,
              },
            };
          }

          // Normalize chart and dashboard data when filtering by days
          if (state.dashboard.filters.interval === 'monthly' && arg.type !== 'table') {
            formattedData = [];

            const currentMonthIndex = Number(arg.filters.endDate.substr(5, 2));
            const prevMonthDays = [];
            const currentMonthDays = [];

            let hasMultipleMonths = true;

            Object.values(data).map((day, index) => {
              if ((index === 0 && day.referenceMonth) === data[data.length - 1].referenceMonth) {
                hasMultipleMonths = false;

                if (currentMonthIndex === day.referenceMonth) {
                  formattedData.push([]);
                  return formattedData.push(arg.type === 'dashboard' ? reducePeriod(data) : data);
                } else {
                  formattedData.push(arg.type === 'dashboard' ? reducePeriod(data) : data);
                  return formattedData.push([]);
                }
              }

              if (day.referenceMonth !== currentMonthIndex) {
                return prevMonthDays.push(day);
              }

              return currentMonthDays.push(day);
            });

            if (hasMultipleMonths) {
              formattedData.push(arg.type === 'dashboard' ? reducePeriod(prevMonthDays) : prevMonthDays);
              formattedData.push(arg.type === 'dashboard' ? reducePeriod(currentMonthDays) : currentMonthDays);
            }

            return {
              ...state,
              [arg.type]: {
                ...state[arg.type],
                data: formattedData,
                loading: false,
              },
            };
          }

          // Normalize chart and dashboard data when filtering by years
          if (state.dashboard.filters.interval === 'yearly' && arg.type !== 'table') {
            formattedData = [];

            let hasMultipleYears = true;
            const currentYearIndex = Number(arg.filters.endDate.substr(0, 4));
            const prevYear = [];
            const currentYear = [];

            Object.values(data).map((month, index) => {
              if ((index === 0 && month.referenceYear) === data[data.length - 1].referenceYear) {
                hasMultipleYears = false;

                if (currentYearIndex === month.referenceYear) {
                  formattedData.push([]);
                  return formattedData.push(arg.type === 'dashboard' ? reducePeriod(data) : data);
                } else {
                  formattedData.push(arg.type === 'dashboard' ? reducePeriod(data) : data);
                  return formattedData.push([]);
                }
              }

              if (currentYearIndex === month.referenceYear) {
                return currentYear.push(month);
              }

              return prevYear.push(month);
            });

            if (hasMultipleYears) {
              formattedData.push(arg.type === 'dashboard' ? reducePeriod(prevYear) : prevYear);
              formattedData.push(arg.type === 'dashboard' ? reducePeriod(currentYear) : currentYear);
            }

            return {
              ...state,
              [arg.type]: {
                ...state[arg.type],
                data: formattedData,
                loading: false,
              },
            };
          }

          // Normalize table data
          return {
            ...state,
            [arg.type]: {
              ...state[arg.type],
              data: formattedData,
              loading: false,
            },
          };
        }
      )
      .addMatcher(
        isAnyOf(fetchStatistics.rejected, fetchTableStatistics.rejected, fetchChartStatistics.rejected),
        (state, { error, meta: { arg } }) => ({
          ...state,
          [arg.type]: {
            ...state[arg.type],
            loading: false,
            error,
          },
        })
      );
  },
});

export const { setFilters } = statisticsSlice.actions;
export default statisticsSlice.reducer;
