import React, { Fragment, useContext } from 'react';
import { BananaContext } from '@wikimedia/react.i18n';
import Box from '@mui/material/Box';

import { formatDateTimezone, currencyFormat } from 'utils';

import { useStyles } from './style';

export function ShiftDetail({ shift }) {
  const translator = useContext(BananaContext);
  const classes = useStyles();
  const {
    personName,
    balanceAmount,
    startDate,
    endDate,
    code,
    status,
    refundCount,
    refundAmount,
    paymentCount,
    paymentAmount,
  } = shift || {};

  return (
    <Fragment>
      <Box component="dl" className={classes.definitions}>
        <Box className={classes.definition}>
          <Box component="dt" className={classes.title}>
            {translator.i18n('payment-amount')}
          </Box>
          <Box component="dd">
            {currencyFormat(paymentAmount)} {translator.i18n('in-transactions', paymentCount)}
          </Box>
        </Box>
        <Box className={classes.definition}>
          <Box component="dt" className={classes.title}>
            {translator.i18n('refund-amount')}
          </Box>
          <Box component="dd">
            {currencyFormat(refundAmount)} {translator.i18n('in-transactions', refundCount)}
          </Box>
        </Box>
        <Box className={classes.definition}>
          <Box component="dt" className={classes.title}>
            {translator.i18n('total-amount')}
          </Box>
          <Box component="dd">{currencyFormat(balanceAmount)}</Box>
        </Box>
        <Box className={classes.definition}>
          <Box component="dt" className={classes.title}>
            {translator.i18n('start-date')}
          </Box>
          <Box component="dd">{formatDateTimezone(startDate)}</Box>
        </Box>
        <Box className={classes.definition}>
          <Box component="dt" className={classes.title}>
            {translator.i18n('end-date')}
          </Box>
          <Box component="dd">{endDate ? formatDateTimezone(endDate) : '-'}</Box>
        </Box>
        <Box className={classes.definition}>
          <Box component="dt" className={classes.title}>
            {translator.i18n('code')}
          </Box>
          <Box component="dd">{code}</Box>
        </Box>
        <Box className={classes.definition}>
          <Box component="dt" className={classes.title}>
            {translator.i18n('unit')}
          </Box>
          <Box component="dd">{personName}</Box>
        </Box>
        <Box className={classes.definition}>
          <Box component="dt" className={classes.title}>
            {translator.i18n('status')}
          </Box>
          <Box component="dd" style={{ textTransform: 'capitalize' }}>
            {status}
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
}

export default ShiftDetail;
