import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useConfirm } from 'material-ui-confirm';

import { transactionReverse } from '@store/reducers/reverse';

export function useReverse() {
  const confirm = useConfirm();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const reverse = async ({ paymentId, merchantId }) => {
    setLoading(true);
    try {
      await confirm({
        confirmationButtonProps: {
          variant: 'contained',
          color: 'primary',
        },
        cancellationButtonProps: {
          variant: 'contained',
          color: 'secondary',
        },
        description: `Are you sure you want to release the transaction with id ${paymentId}`,
      });
      await dispatch(transactionReverse({ paymentId, merchantId }));
    } catch (_) {
      console.log(`${paymentId} failed!`);
    } finally {
      setLoading(false);
    }
  };

  return { loading, reverse };
}
