import { createTheme } from '@mui/material/styles';
import { enUS, ptPT, esES, deDE, frFR, nlNL } from '@mui/material/locale';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { hexAToRGBA } from 'utils';

export const colors = {
  dark: {
    text: '#F5F5F5',
    header: '#222222',
    default: '#181818',
    paper: '#202020',
    inverse: '#FFFFFF',
    black: '#333333',
    white: '#FFFFFF',
    main: '#6339EF',
    purple: '#7F73EB',
    blue: '#695ef9',
    yellow: '#EAB308',
    red: '#EF4444',
    orange: '#ff9140',
    green: '#22C55E',
    'light-gray-2': '#505050',
    'light-gray': '#272729',
    'medium-gray': '#83858F',
    'medium-gray-2': '#48494f',
    'dark-gray-2': '#363243',
    'dark-gray': '#202020',
    'baby-green': '#75FEA6',
    'gray-300': '#71717A',
    'gray-400': '#D4D4D8',
    'gray-500': '#2E2E2E',
    'gray-600': '#52525B',
    'gray-700': '#3F3F46',
    'gray-800': '#27272A',
    'thumb-track': '#333',
    thumb: '#484c50',
    'thumb-track-hover': '#666',
  },
  light: {
    text: '#202020',
    header: '#FBFCFE',
    default: '#F5F5F5',
    paper: '#FFFFFF',
    inverse: '#202020',
    black: '#333333',
    white: '#FFFFFF',
    main: '#6339EF',
    purple: '#7F73EB',
    blue: '#695ef9',
    yellow: '#EAB308',
    red: '#EF4444',
    orange: '#ff9140',
    green: '#22C55E',
    'light-gray-2': '#f2f2f2',
    'light-gray': '#fff',
    'medium-gray': '#83858F',
    'medium-gray-2': '#48494f',
    'dark-gray-2': '#363243',
    'dark-gray': '#202020',
    'baby-green': '#75FEA6',
    'gray-300': '#71717A',
    'gray-400': '#3F3F46',
    'gray-500': '#f2f2f2',
    'gray-600': '#52525B',
    'gray-700': '#E4E4E7',
    'gray-800': '#fff',
    'thumb-track': '#e4e4e6',
    thumb: '#a1a1a9',
    'thumb-track-hover': '#a1a1a9',
  },
};

const getLocale = (locale) => {
  switch (locale) {
    case 'en':
      return enUS;
    case 'pt':
      return ptPT;
    case 'es':
      return esES;
    case 'de':
      return deDE;
    case 'fr':
      return frFR;
    case 'nl':
      return nlNL;
    default:
      return enUS;
  }
};

export const makeTheme = (type = 'dark', locale) => {
  return createTheme(
    {
      components: {
        MuiButtonBase: {
          defaultProps: {
            disableRipple: true,
          },
        },
        MuiCssBaseline: {
          styleOverrides: {
            html: {
              WebkitFontSmoothing: 'auto',
            },
            '*::-webkit-scrollbar': {
              width: '13px',
              height: '13px',
            },
            '*::-webkit-scrollbar-thumb': {
              background: colors[type]['thumb'],
              borderRadius: '1px',
            },
            '*::-webkit-scrollbar-thumb:hover': {
              background: colors[type]['thumb-track-hover'],
            },
            '*::-webkit-scrollbar-track': {
              background: colors[type]['thumb-track'],
              borderRadius: '0px',
            },
          },
        },
        MuiSelect: {
          styleOverrides: {
            root: {
              height: 20,
              color: colors[type].text,
              fontSize: 14,
              '&:hover, &:hover ~ .MuiOutlinedInput-notchedOutline': {
                borderColor: colors[type].purple,
                borderWidth: 1,
              },
              '&.MuiInput-input': {
                fontSize: 16,
                '&.Mui-disabled': {
                  color: 'currentColor',
                },
              },
            },
            iconOutlined: {
              right: 12,
            },
            select: {
              '&:focus': {
                backgroundColor: 'transparent',
              },
            },
          },
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              '&.transparent': {
                '& fieldset': {
                  border: 'none',
                  backgroundColor: 'transparent',
                },
                '.MuiOutlinedInput-root': {
                  border: 'none',
                  backgroundColor: 'transparent',
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                  backgroundColor: 'transparent',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                  backgroundColor: 'transparent',
                },
              },
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              color: colors[type].text,
              border: `1px solid ${colors[type]['gray-700']}`,
              backgroundColor: colors[type]['gray-800'],
              minHeight: '44px',
              '&.MuiInputBase-root': {
                '&.MuiOutlinedInput-root': {
                  border: 'none',
                },
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${colors[type]['gray-700']}`,
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${colors[type]['gray-700']}`,
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${colors[type]['gray-700']}`,
              },
              '&.Mui-disabled': {
                backgroundColor: colors[type]['gray-700'],
                '& fieldset': {
                  border: 'none',
                },
                '& .MuiInputBase-input': {
                  backgroundColor: colors[type]['gray-700'],
                  borderRadius: '4px',
                  border: `1px solid ${colors[type]['gray-700']}`,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              },
            },
            input: {
              padding: '8px 10px',
            },
          },
        },
        MuiLinearProgress: {
          styleOverrides: {
            colorPrimary: {
              backgroundColor: 'rgba(51, 51, 51, .15)',
            },
            barColorPrimary: {
              backgroundColor: colors[type].white,
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              padding: 24,
              backgroundImage: 'none',
            },
            rounded: {
              borderRadius: 12,
            },
          },
        },
        MuiTabs: {
          styleOverrides: {
            root: {
              paddingBottom: '0px !important',
              color: colors[type].text,
            },
            indicator: {
              backgroundColor: colors[type].main,
            },
          },
        },
        MuiTab: {
          styleOverrides: {
            root: {
              minWidth: '0',
              padding: '6px 0',
              marginRight: '20px',
              textTransform: 'none',
            },
          },
        },
        MuiSlider: {
          styleOverrides: {
            root: {
              height: '1px',
            },
            thumb: {
              height: 16,
              width: 16,
            },
          },
        },
        PrivateTabIndicator: {
          styleOverrides: {
            colorSecondary: {
              backgroundColor: colors[type].main,
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            head: {
              borderBottom: 'none',
              color: colors[type]['text'],
              fontSize: 14,
              fontWeight: 400,
              padding: '12px 12px 12px 20px',
            },
            body: {
              padding: '12px 12px 12px 20px',
            },
            alignRight: {
              padding: 8,
            },
          },
        },
        MuiTable: {
          styleOverrides: {
            root: {
              minWidth: 650,
            },
          },
        },
        MuiPopover: {
          styleOverrides: {
            paper: {
              border: `1px solid ${colors[type].paper}`,
              backgroundColor: colors[type].paper,
              padding: '0',
            },
          },
        },
        MuiTableRow: {
          styleOverrides: {
            root: {
              '& .MuiTableCell-body': {
                borderBottom: 'none',
              },
              '&:last-of-type': {
                '& .MuiTableCell-body': {
                  borderBottom: 'none',
                },
              },
            },
            hover: {
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.03) !important',
              },
            },
          },
        },
        MuiChip: {
          styleOverrides: {
            root: {
              borderRadius: '4px',
            },
            sizeMedium: {
              height: '44px',
            },
            sizeSmall: {
              height: '24px',
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              fontWeight: 500,
              fontStyle: 'normal',
              height: 44,
              color: colors[type].text,
              textTransform: 'none',
              borderRadius: 4,
              padding: '6px 15px',
              borderColor: colors[type].white,
              lineHeight: 'unset',
              '&.MuiButton-outlinedSecondary': {
                borderColor: colors[type].purple,
                borderWidth: 1,
                backgroundColor: colors[type]['light-gray'],
              },
              '&.MuiButton-outlinedSecondary:hover': {
                borderColor: colors[type].purple,
                borderWidth: 1,
                backgroundColor: hexAToRGBA(`${colors[type]['light-gray']}33`),
              },
              '&.MuiButton-outlinedPrimary': {
                borderColor: colors[type]['gray-700'],
                borderWidth: 1,
                backgroundColor: colors[type]['light-gray'],
              },
              '&.MuiButton-outlinedPrimary:hover': {
                borderColor: colors[type]['gray-700'],
                borderWidth: 1,
                backgroundColor: colors[type]['gray-700'],
              },
              '&:hover .MuiButton-outlinedSecondary': {
                backgroundColor: hexAToRGBA(`${colors[type].purple}d4`),
              },
            },
            normal: {
              textTransform: 'none',
              backgroundColor: colors[type]['gray-800'],
              border: `1px solid ${colors[type]['gray-700']}`,
            },
            contained: {
              color: colors[type]['white'],
            },
            endIcon: {
              marginRight: 3,
            },
            label: {
              whiteSpace: 'nowrap',
            },
          },
        },
        MuiToggleButton: {
          styleOverrides: {
            root: {
              height: 44,
              color: colors[type].text,
              textTransform: 'none',
              borderRadius: '4px',
              padding: '6px 15px',
              backgroundColor: `${colors[type].default} !important`,
              '&.Mui-selected': {
                color: colors[type]['white'],
                borderRadius: '4px',
                backgroundColor: `${colors[type].main} !important`,
              },
              '&.MuiToggleButton-primary': {
                borderWidth: 0,
                borderRadius: '4px',
                backgroundColor: colors[type]['light-gray'],
              },
              '&.MuiToggleButton-primary:hover': {
                borderWidth: 0,
                backgroundColor: hexAToRGBA(`${colors[type]['light-gray']}33`),
              },
            },
          },
        },
        MuiToggleButtonGroup: {
          styleOverrides: {
            root: {
              padding: '8px',
              backgroundColor: colors[type]['light-gray'],
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              backgroundColor: `${colors[type]['light-gray']} !important`,
            },
          },
        },
        MuiCardHeader: {
          styleOverrides: {
            root: {
              padding: 0,
            },
          },
        },
        MuiCardContent: {
          styleOverrides: {
            root: {
              padding: 0,
            },
          },
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          },
        },
        MuiMenu: {
          styleOverrides: {
            paper: {
              backgroundColor: colors[type].white,
              color: colors[type].black,
              borderRadius: 4,
            },
          },
        },
        MuiList: {
          styleOverrides: {
            root: {
              paddingTop: 0,
              paddingBottom: 0,
            },
          },
        },
        MuiListItemIcon: {
          styleOverrides: {
            root: {
              color: colors[type].black,
              minWidth: 40,
            },
          },
        },
        MuiListItem: {
          styleOverrides: {
            root: {
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          },
        },
        MuiDialog: {
          styleOverrides: {
            root: {
              '& .MuiDialog-paper': {
                backgroundColor: colors[type]['gray-800'],
              },
            },
          },
        },
        MuiDialogActions: {
          styleOverrides: {
            root: {
              padding: '16px 0px 0px 0px',
              '& .MuiButton-textPrimary': {
                color: colors[type].white,
              },
            },
          },
        },
        MuiTablePagination: {
          styleOverrides: {
            toolbar: {
              display: 'inline-flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            },
          },
        },
        MuiPickersBasePicker: {
          styleOverrides: {
            pickerView: {
              minWidth: 188,
            },
          },
        },
        MuiCheckbox: {
          styleOverrides: {
            root: {
              '&:hover': {
                backgroundColor: 'transparent !important',
              },
            },
          },
        },
        MuiAutocomplete: {
          styleOverrides: {
            root: {
              overflowY: 'auto',
              overflowX: 'hidden',
              '& .MuiOutlinedInput-root': {
                padding: 0,
              },
            },
            popper: {
              '& .MuiAutocomplete-paper': {
                padding: 0,
                margin: 0,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              },
            },
          },
        },
        MuiLink: {
          styleOverrides: {
            root: {
              textDecoration: 'none',
              color: colors[type].text,
            },
          },
        },
      },
      palette: {
        mode: type,
        secondary: {
          main: colors[type].white,
        },
        primary: {
          main: colors[type].main,
          fade: colors[type].fade,
        },
        text: {
          main: colors[type].text,
        },
        paper: {
          main: colors[type].paper,
        },
        black: {
          main: colors[type].black,
        },
        background: {
          header: colors[type].header,
          default: colors[type].default,
          paper: colors[type].paper,
          inverse: colors[type].inverse,
        },
        mediumGray: {
          main: colors[type]['medium-gray'],
        },
        mediumGray2: {
          main: colors[type]['medium-gray-2'],
        },
        darkGray2: {
          main: colors[type]['dark-gray-2'],
        },
        purple: {
          main: colors[type].purple,
        },
        white: {
          main: colors[type].white,
        },
        lightBlue: {
          main: '#5cbdff',
        },
        blue: {
          main: colors[type].blue,
          badge: 'rgb(105, 93, 251, 0.1)',
        },
        darkGray: {
          main: colors[type]['dark-gray'],
        },
        babyGreen: {
          main: colors[type]['baby-green'],
        },
        yellow: {
          main: colors[type].yellow,
          badge: 'rgba(234, 179, 8, 0.10)',
        },
        red: {
          main: colors[type].red,
          badge: 'rgba(239, 68, 68, 0.10)',
        },
        orange: {
          main: colors[type].orange,
          badge: 'rgba(234, 179, 8, 0.10)',
        },
        green: {
          main: colors[type].green,
          badge: 'rgba(34, 197, 94, 0.10)',
        },
        lightGray2: {
          main: colors[type]['light-gray-2'],
        },
        lightGray: {
          main: colors[type]['light-gray'],
        },
        action: {
          selected: colors[type]['light-gray'],
          hover: colors[type]['light-gray'],
          disabled: '#9B9B9B',
          content: colors[type]['gray-400'],
          footer: colors[type]['gray-500'],
        },
        gray500: {
          main: colors[type]['gray-500'],
        },
        gray400: {
          main: colors[type]['gray-400'],
        },
        gray300: {
          main: colors[type]['gray-300'],
        },
        gray700: {
          main: colors[type]['gray-700'],
        },
        gray800: {
          main: colors[type]['gray-800'],
        },
      },
      typography: {
        fontFamily: [
          '"Montserrat"',
          'Arial',
          '"Helvetica Neue"',
          'Helvetica',
          'sans-serif',
        ].join(','),
        body1: {
          fontSize: 16,
        },
        body2: {
          fontSize: 14,
        },
        body3: {
          fontSize: 12,
        },
        h1: {
          fontSize: 24,
          fontWeight: 600,
        },
        h2: {
          fontSize: 20,
          fontWeight: 500,
        },
        h3: {
          fontSize: 20,
          fontWeight: 500,
        },
      },
    },
    getLocale(locale)
  );
};

export default makeTheme;
