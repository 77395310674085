import React, { useContext } from 'react';
import { selector } from 'recoil';
import { BananaContext } from '@wikimedia/react.i18n';

import DashboardIcon from '@assets/icons/DashboardIcon';
import SettingsIcon from '@assets/icons/SettingsIcon';
import DevelopersIcon from '@assets/icons/DevelopersIcon';
import AnalyticsIcon from '@assets/icons/AnalyticsIcon';
import ManagementIcon from '@assets/icons/ManagementIcon';
import PaymentsIcon from '@assets/icons/PaymentsIcon';
import LogoutIcon from '@assets/icons/LogoutIcon';

import { useAuth } from '@context/Auth0Context';
import { PERMISSIONS } from '@services/config';
import { Chip } from '@mui/material';

export const navigationRoutesSelector = selector({
  key: 'navigationRoutesSelector',
  get: () => {
    const translator = useContext(BananaContext);
    const { logout } = useAuth();

    const dashboardMenu = {
      label: translator.i18n('dashboard-title'),
      icon: <DashboardIcon />,
      slug: 'dashboard',
      children: [],
    };

    const analyticsMenu = {
      label: translator.i18n('analytics-title'),
      icon: <AnalyticsIcon />,
      slug: 'analytics',
      children: [
        {
          label: translator.i18n('statistics-title'),
          slug: 'statistics',
          permissions: PERMISSIONS.REPORTS_STATISTICS,
          children: [],
        },
      ],
    };

    const managementMenu = {
      label: translator.i18n('management-title'),
      icon: <ManagementIcon />,
      slug: 'management',
      children: [
        {
          label: translator.i18n('administration-title'),
          slug: 'administration',
          permissions: PERMISSIONS.ADMINISTRATION,
          children: [],
        },
        {
          label: translator.i18n('shifts-title'),
          slug: 'shifts',
          permissions: PERMISSIONS.SHIFTS,
          children: [],
        },
        {
          label: (
            <>
              {translator.i18n('orders-title')}
              <Chip
                sx={{ float: 'right' }}
                size="small"
                label="new"
                color="warning"
              />
            </>
          ),
          slug: 'orders-new',
          permissions: PERMISSIONS.REPORTS_TRANSACTIONS,
          children: [],
        },
        {
          label: translator.i18n('chargeback-title'),
          slug: 'chargebacks',
          children: [],
        },
      ],
    };

    const paymentsMenu = {
      label: translator.i18n('payments-title'),
      icon: <PaymentsIcon />,
      slug: 'payments',
      children: [
        /*{
          label: translator.i18n("transactions-title"),
          slug: "transactions",
          permissions: PERMISSIONS.REPORTS_TRANSACTIONS,
          children: [],
        },*/
        {
          label: (
            <>
              {translator.i18n('transactions-title')}
            </>
          ),
          slug: 'transactions-new',
          permissions: PERMISSIONS.REPORTS_TRANSACTIONS,
          children: [],
        },
        {
          label: translator.i18n('pay-by-link-title'),
          slug: 'paybylink',
          permissions: PERMISSIONS.PAYBYLINK,
          children: [],
        },
        {
          label: translator.i18n("settlements"),
          slug: "settlement-files",
          permissions: PERMISSIONS.PAYMENTS_SETTLEMENTS,
          children: [],
        },
        {
          label: translator.i18n("payouts-title"),
          slug: "payouts",
          permissions: PERMISSIONS.PAYMENTS_PAYOUTS,
          children: [],
        },
      ],
    };

    const settingsMenu = {
      label: translator.i18n('settings-title'),
      icon: <SettingsIcon />,
      slug: 'settings',
      children: [
        {
          label: translator.i18n('user'),
          slug: 'user',
          children: [],
        },
        {
          label: translator.i18n('units'),
          slug: 'stores',
          children: [],
        },
      ],
    };

    const developersMenu = {
      label: translator.i18n('developers-title'),
      icon: <DevelopersIcon />,
      slug: 'developers',
      permissions: PERMISSIONS.DEVELOPERS,
      children: [
        {
          label: translator.i18n('api-keys'),
          slug: 'apikeys',
          children: [],
        },
        {
          label: translator.i18n('webhooks'),
          slug: 'webhooks',
          children: [],
        },
      ],
    };

    const logoutMenu = {
      label: translator.i18n('logout'),
      icon: <LogoutIcon />,
      onClick: () => {
        logout({
          returnTo: window.location.origin,
        });
      },
    };

    return {
      topItems: [
        dashboardMenu,
        analyticsMenu,
        managementMenu,
        paymentsMenu,
        settingsMenu,
      ],
      bottomItems: [developersMenu, logoutMenu],
    };
  },
});
