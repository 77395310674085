import { call } from 'redux-saga/effects';
import { takeLatestAsync } from 'saga-toolkit';

import { api } from '@legacy/api';
import { getPeriodMonth } from 'utils';

import { fetchReports } from './reducer';

function* handleFetch({ meta: { arg } }) {
  try {
    const { units, date, currency } = arg;
    const { startDate, endDate } = getPeriodMonth(date);
    const data = yield call(api.getBalanceReports, {
      merchantIds: units,
      startDate,
      endDate,
      groupBy: 'brand',
      currency,
      interval: 'monthly',
    });

    return data;
  } catch (error) {
    throw new Error(error.message);
  }
}

export function* administrationSaga() {
  yield takeLatestAsync(fetchReports.type, handleFetch);
}
