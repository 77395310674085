import { call, put } from 'redux-saga/effects';
import { takeLatestAsync } from 'saga-toolkit';
import { differenceInDays, isDate, parseISO } from 'date-fns';

import { api } from '@legacy/api';
import { exportReport } from '@store/reducers/export';
import { enqueue } from '@store/reducers/notifications';

import { suitParamsToExport as suitParamsToTransactionsExport } from '@features/transactions/saga';

import { suitParamsToExport as suitParamsToOrdersExport } from '@features/orders/saga';

import { suitParamsToExport as suitParamsToShiftsExport } from '@features/shifts/saga';

const MAX_SUPPORTED_REPORT_INTERVAL = 31; // Days

export function* handleExport({ meta: { arg } }) {
  function* processTransactions(filter, type) {
    var normalizedParams = suitParamsToTransactionsExport(filter);

    const data = yield call(api.queueReportProcessing, normalizedParams, type);

    if (![200, 202].includes(data?.status)) {
      throw new Error(
        data?.data?.error?.internalMessage || data?.statusText || 'Unknown error while processing report'
      );
    }

    yield put(
      enqueue({
        message: 'Report queued for processing...',
        options: { variant: 'success' },
      })
    );
  }

  function* processOrders(filter, type) {
    var normalizedParams = suitParamsToOrdersExport(filter);

    const data = yield call(api.queueReportProcessing, normalizedParams, type);

    if (![200, 202].includes(data?.status)) {
      throw new Error(
        data?.data?.error?.internalMessage || data?.statusText || 'Unknown error while processing report'
      );
    }

    yield put(
      enqueue({
        message: 'Report queued for processing...',
        options: { variant: 'success' },
      })
    );
  }

  function* processShifts(filter, type) {
    var normalizedParams = suitParamsToShiftsExport(filter);

    const data = yield call(api.queueReportProcessing, normalizedParams, type);

    if (![200, 202].includes(data?.status)) {
      throw new Error(
        data?.data?.error?.internalMessage || data?.statusText || 'Unknown error while processing report'
      );
    }

    yield put(
      enqueue({
        message: 'Report queued for processing...',
        options: { variant: 'success' },
      })
    );
  }

  function* processSettlements(filter, type) {
    const data = yield call(
      api.queueReportProcessing,
      {
        ...filter,
        outputType: filter.exportType,
      },
      type
    );

    if (![200, 202].includes(data?.status)) {
      throw new Error(
        data?.data?.error?.internalMessage || data?.statusText || 'Unknown error while processing report'
      );
    }

    yield put(
      enqueue({
        message: 'Report queued for processing...',
        options: { variant: 'success' },
      })
    );
  }

  try {
    const {
      filters: { startDate, endDate },
    } = arg;

    if (isDate(parseISO(startDate)) && isDate(parseISO(endDate))) {
      if (differenceInDays(parseISO(endDate), parseISO(startDate)) > MAX_SUPPORTED_REPORT_INTERVAL) {
        yield put(
          enqueue({
            message: `Reports with more than ${MAX_SUPPORTED_REPORT_INTERVAL} days are not supported`,
            options: { variant: 'error' },
          })
        );
        return;
      }
    }

    switch (arg.type) {
      case 'transactions':
        return yield processTransactions(arg.filters, arg.type);
      case 'orders':
        return yield processOrders(arg.filters, arg.type);
      case 'shifts':
        return yield processShifts(arg.filters, arg.type);
      case 'settlements':
        return yield processSettlements(arg.filters, arg.type);
      default:
        throw new Error('Invalid report type');
    }
  } catch (error) {
    yield put(
      enqueue({
        message: `Error downloading the report: ${error?.message || 'Unknown error'}`,
        options: { variant: 'error' },
      })
    );
    throw new Error(error.message);
  }
}

export function* exportSaga() {
  yield takeLatestAsync(exportReport.type, handleExport);
}
