import React, { Fragment, useContext, useEffect, useState } from 'react';
import { BananaContext } from '@wikimedia/react.i18n';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { PERMISSIONS } from '@services/config';
import { isRefundable, isCapturable, isReversable, formatDateTimezone, translate, getLabelFromStatus } from 'utils';
import { useReverse } from '@hooks/useReverse';

import { useStyles } from './style';
import { useModals } from '../../useModals';
import { api } from '@legacy/api';
import { useUser } from '@hooks/useUser';
import { Typography } from '@mui/material';

export function Detail({ transaction }) {
  const { userHasScope } = useUser();
  const translator = useContext(BananaContext);
  const { openRefund, openCapture } = useModals();
  const { loading, reverse } = useReverse();
  const classes = useStyles();
  const [data, setTransaction] = useState(null);
  const { paymentId, orderId } = transaction || {};

  const handleRefund = () => openRefund({ transaction });
  const handleCapture = () => openCapture({ transaction });
  const handleRelease = () => reverse(transaction);

  useEffect(() => {
    const fetch = async () => {
      if (orderId) {
        const { data } = await api.getOrder(orderId, transaction.storeId);
        setTransaction(data);
      } else {
        const { data } = await api.getTransaction(paymentId, transaction.storeId);
        setTransaction(data);
      }
    };
    fetch();
  }, []);

  if (!data) {
    return (
      <Box
        style={{
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Typography>{orderId ? translator.i18n('loading-order') : translator.i18n('loading-transaction')}</Typography>
      </Box>
    );
  }

  return (
    <Fragment>
      <Box>
        <Fragment>
          <Box className={classes.header}>
            <span>{translator.i18n('details')}</span>
          </Box>
          <Box component="dl" className={classes.definitions}>
            <Box className={classes.definition}>
              <Box component="dt" className={classes.title}>
                {translator.i18n(!orderId ? 'transactionId' : 'orderId')}
              </Box>
              <Box component="dd" className={classes.description}>
                {data['id'] || '-'}
              </Box>
            </Box>
            <Box className={classes.definition}>
              <Box component="dt" className={classes.title}>
                {translator.i18n('order-ref')}
              </Box>
              <Box component="dd" className={classes.description}>
                {data['orderRef'] || '-'}
              </Box>
            </Box>
            <Box className={classes.definition}>
              <Box component="dt" className={classes.title}>
                {translator.i18n('status')}
              </Box>
              <Box component="dd" className={classes.description}>
                {getLabelFromStatus(data['status']?.toLowerCase() || '-', translator)}
              </Box>
            </Box>
            <Box className={classes.definition}>
              <Box component="dt" className={classes.title}>
                {translator.i18n('created-date')}
              </Box>
              <Box component="dd" className={classes.description}>
                {formatDateTimezone(data['transactionDate'] || data['orderDate']) || '-'}
              </Box>
            </Box>
            {!orderId && (
              <>
                <Box className={classes.definition}>
                  <Box component="dt" className={classes.title}>
                    {translator.i18n('relatedOperationStatus')}
                  </Box>
                  <Box component="dd" className={classes.description}>
                    {translator.i18n(data['relatedOperationStatus']?.toLowerCase() || '-')}
                  </Box>
                </Box>
                <Box className={classes.definition}>
                  <Box component="dt" className={classes.title}>
                    {translator.i18n('transaction-type')}
                  </Box>
                  <Box component="dd" className={classes.description}>
                    {translator.i18n(data['typeCode']?.toLowerCase() || '-')}
                  </Box>
                </Box>
                <Box className={classes.definition}>
                  <Box component="dt" className={classes.title}>
                    {translator.i18n('user')}
                  </Box>
                  <Box component="dd" className={classes.description}>
                    {translator.i18n(data['user']?.toLowerCase() || '-')}
                  </Box>
                </Box>
              </>
            )}
            {paymentId && !orderId && (
              <Box className={classes.definition}>
                <Box component="dt" className={classes.title}>
                  {translator.i18n('environment')}
                </Box>
                <Box component="dd" className={classes.description}>
                  {translator.i18n(data['isProduction'] ? 'prod' : 'test')}
                </Box>
              </Box>
            )}
          </Box>
        </Fragment>
        {data?.details?.map(({ displayName, values, id }, index) => (
          <Fragment key={index}>
            <Box className={classes.header}>
              <span>{translate(id, displayName, translator)}</span>
            </Box>
            <Box component="dl" className={classes.definitions}>
              {values
                ?.map(({ displayName, type, value, precision, id, isVisible = true }, index) => {
                  let displayValue = '-';

                  switch (type) {
                    case 'number': {
                      if (precision) {
                        displayValue = Number(value / Math.pow(10, precision)).toFixed(precision);
                      } else {
                        displayValue = value;
                      }

                      break;
                    }
                    case 'date': {
                      displayValue = formatDateTimezone(value);
                      break;
                    }
                    case 'object': {
                      displayValue = '-';
                      break;
                    }
                    case 'currency': {
                      displayValue = value;
                      break;
                    }
                    case 'boolean': {
                      displayValue = value ? translator.i18n('yes') : translator.i18n('no');
                      break;
                    }
                    default:
                      displayValue = value;
                  }

                  if (!isVisible) return null;

                  return (
                    <Box className={classes.definition} key={index}>
                      <Box component="dt" className={classes.title}>
                        {translate(id, displayName, translator)}
                      </Box>
                      <Box component="dd" className={classes.description}>
                        {displayValue}
                      </Box>
                    </Box>
                  );
                })
                .filter((x) => x)}
            </Box>
          </Fragment>
        ))}
      </Box>
      <Grid container direction="row" spacing={2} justifyContent="flex-end">
        {transaction && isReversable(transaction) && (
          <Grid item>
            <Button disabled={loading} variant="contained" onClick={handleRelease}>
              {translator.i18n('release')}
            </Button>
          </Grid>
        )}
        {transaction && isRefundable(transaction) && !userHasScope(PERMISSIONS.NO_REFUND) && (
          <Grid item>
            <Button disabled={loading} variant="contained" onClick={handleRefund}>
              {translator.i18n('refund')}
            </Button>
          </Grid>
        )}
        {transaction && isCapturable(transaction) && (
          <Grid item>
            <Button disabled={loading} variant="contained" onClick={handleCapture}>
              {translator.i18n('capture')}
            </Button>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
}

export default Detail;
