import React, { Fragment, useContext, useState } from 'react';
import { BananaContext } from '@wikimedia/react.i18n';
import Box from '@mui/material/Box';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/lab/Alert';
import Typography from '@mui/material/Typography';

import CopyIcon from '@assets/icons/CopyIcon';

import { formatDateTimezone, currencyFormat } from 'utils';

import { useStyles } from './style';

export function Detail({ socialPayment }) {
  const translator = useContext(BananaContext);
  const [copied, setCopied] = useState(null);
  const classes = useStyles();
  const {
    title,
    description,
    value,
    reference,
    stockQuantity,
    expireAt,
    collectShippingData,
    nationalShippingValue,
    urgentNationalShippingValue,
    internationalShippingValue,
    urgentInternationalShippingValue,
    url,
  } = socialPayment || {};

  return (
    <Fragment>
      <Box component="dl" className={classes.definitions}>
        <Box className={classes.definition}>
          <Box component="dt" className={classes.title}>
            {translator.i18n('title')}
          </Box>
          <Box component="dd">{title}</Box>
        </Box>
        <Box className={classes.definition}>
          <Box component="dt" className={classes.title}>
            {translator.i18n('description')}
          </Box>
          <Box component="dd">{description}</Box>
        </Box>
        <Box className={classes.definition}>
          <Box component="dt" className={classes.title}>
            {translator.i18n('amount')}
          </Box>
          <Box component="dd">{currencyFormat(value / 100 || 0)}</Box>
        </Box>
        <Box className={classes.definition}>
          <Box component="dt" className={classes.title}>
            {translator.i18n('expire-at')}
          </Box>
          <Box component="dd">{formatDateTimezone(expireAt)}</Box>
        </Box>
        <Box className={classes.definition}>
          <Box component="dt" className={classes.title}>
            {translator.i18n('reference')}
          </Box>
          <Box component="dd">{reference}</Box>
        </Box>
        <Box className={classes.definition}>
          <Box component="dt" className={classes.title}>
            {translator.i18n('stock-quantity')}
          </Box>
          <Box component="dd">{stockQuantity}</Box>
        </Box>
        {collectShippingData && (
          <>
            <Box className={classes.definition}>
              <Box component="dt" className={classes.title}>
                {translator.i18n('national-shipping-amount')}
              </Box>
              <Box component="dd">{currencyFormat(nationalShippingValue / 100 || 0)}</Box>
            </Box>
            <Box className={classes.definition}>
              <Box component="dt" className={classes.title}>
                {translator.i18n('international-shipping-amount')}
              </Box>
              <Box component="dd">{currencyFormat(internationalShippingValue / 100 || 0)}</Box>
            </Box>
            <Box className={classes.definition}>
              <Box component="dt" className={classes.title}>
                {translator.i18n('national-urgent-shipping-amount')}
              </Box>
              <Box component="dd">{currencyFormat(urgentNationalShippingValue / 100 || 0)}</Box>
            </Box>
            <Box className={classes.definition}>
              <Box component="dt" className={classes.title}>
                {translator.i18n('international-urgent-shipping-amount')}
              </Box>
              <Box component="dd">{currencyFormat(urgentInternationalShippingValue / 100 || 0)}</Box>
            </Box>
          </>
        )}
        <Box className={classes.definition}>
          <Box component="dt" className={classes.title}>
            {translator.i18n('url')}
          </Box>
          <Box component="dd" display="flex">
            <Typography>{url}</Typography>
            <CopyToClipboard text={url} onCopy={() => setCopied(url)} style={{ marginLeft: '10px', cursor: 'pointer' }}>
              <CopyIcon style={{ cursor: 'pointer' }} />
            </CopyToClipboard>
          </Box>
        </Box>
      </Box>
      <Snackbar open={!!copied} autoHideDuration={6000} onClose={() => setCopied(null)}>
        <Alert severity="info" color="info">
          {translator.i18n('copied-link', copied)}
        </Alert>
      </Snackbar>
    </Fragment>
  );
}

export default Detail;
