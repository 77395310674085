import { call, put } from 'redux-saga/effects';
import { takeEveryAsync } from 'saga-toolkit';

import { api } from '@legacy/api';

import { enqueue } from '@store/reducers/notifications';
import { transactionReverse } from '@store/reducers/reverse';
import { updateTransaction } from '@features/transactions/reducer';

export function* handleReverse({ meta: { arg } }) {
  const { paymentId, merchantId } = arg;

  /* try { */
  // Try to release the transaction
  const { code, description } = yield call(api.release, paymentId, merchantId);

  if (code === 'BYRD200') {
    // Uppon success update the transaction data locally
    yield put(
      updateTransaction({
        paymentId,
        allowedOperations: null,
        status: 'Canceled',
      })
    );
    // Notify success
    yield put(
      enqueue({
        message: `Transaction ${paymentId} was released successfully!`,
      })
    );

    return true;
  } else {
    yield put(
      enqueue({
        message: `An error occurred releasing transaction ${paymentId}: ${description}`,
        options: { variant: 'error' },
      })
    );
    throw new Error(description);
  }
  //throw new Error(description);
  /* } catch(error) {
		// On error notify error
		yield put(enqueue({ message: `An error occurred releasing transaction ${paymentId}: ${error.message}`, options: { variant: 'error' } }));
		throw error;
	} */
}

export function* reverseSaga() {
  yield takeEveryAsync(transactionReverse.type, handleReverse);
}
