import { put } from 'redux-saga/effects';
import { takeEveryAsync } from 'saga-toolkit';

import { enqueue } from '@store/reducers/notifications';
import { api } from '@legacy/api';

import { sendPayByLink } from './reducer';

function* handleSendPayByLink({ meta: { arg } }) {
  try {
    const results = yield Promise.allSettled(arg.links.map((link) => api.createPayByLink(link)));

    for (let i = 0, l = results.length; i < l; i++) {
      const { status, value } = results[i];
      if (status === 'fulfilled') {
        const {
          data: { checkoutUrl, description },
          status,
        } = value;
        if (status >= 200 && status < 300) {
          yield put(
            enqueue({
              message: `${arg.translator.i18n('payment-link-success')} ${checkoutUrl}`,
              options: {
                persist: true,
                actions: {
                  copy: { text: checkoutUrl },
                },
              },
            })
          );
        } else {
          yield put(
            enqueue({
              message: `${arg.translator.i18n('payment-link-error')}${description ? ` - ${description}` : ''}`,
              options: { variant: 'error' },
            })
          );
          //throw new Error("An error occurred while sending the links");
          //return false;
        }
      } else {
        yield put(
          enqueue({
            message: arg.translator.i18n('payment-link-error'),
            options: { variant: 'error' },
          })
        );
      }
    }

    return true;
  } catch (error) {
    yield put(
      enqueue({
        message: arg.translator.i18n('payment-link-error'),
        options: { variant: 'error' },
      })
    );
    throw error;
  }
}

export function* sendPayByLinkSaga() {
  yield takeEveryAsync(sendPayByLink.type, handleSendPayByLink);
}
