import React from 'react';
import { useAuth } from '@context/Auth0Context';
import { Redirect } from 'react-router-dom';
import usePageTitle from '@hooks/usePageTitle';

import Login from '@components/pages/Login';

export const Home = () => {
  const { user } = useAuth();

  usePageTitle('APM Home');

  if (user) {
    return <Redirect to="/dashboard" />;
  }

  return <Login />;
};

export default Home;
