import React, { useState, Fragment, useEffect, useContext } from 'react';
import { BananaContext } from '@wikimedia/react.i18n';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import PaybyrdLoginLogo from '@legacy/components/paybyrdLoginLogo';

import { useAuth } from '@context/Auth0Context';
import { useStyles } from './style';
import { MERCHANT_ONBOARDING_URL } from '@services/config';

const schema = yup
  .object()
  .shape({
    email: yup.string().email().required(),
    password: yup.string().when('isLogin', {
      is: true,
      then: yup.string().required(),
      otherwise: yup.string().notRequired(),
    }),
    isLogin: yup.boolean(),
  })
  .required();

export const Login = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const translator = useContext(BananaContext);
  const [recoverSuccess, setRecoverSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [showText, setShowText] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const theme = useTheme();
  const classes = useStyles();
  const { login, changePassword } = useAuth();
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
      isLogin,
    },
  });

  useEffect(() => {
    reset({ email: '', password: '', isLogin });
    setError(null);
    setRecoverSuccess(null);
  }, [isLogin]);

  const handleLogin = (data) => {
    const { email, password } = data;
    login(
      {
        email,
        password,
      },
      (error, resp) => {
        if (error) {
          setError(error.description);
        } else {
          console.log(resp);
        }
      }
    );
  };

  const handleChangePassword = (data) => {
    changePassword(data.email, (error) => {
      if (error) {
        setError(error.description);
      } else {
        setRecoverSuccess(translator.i18n('recovery-password-success'));
      }
    });
  };

  const handleKeyUp = (event) => {
    const { code } = event;

    if (code && code === 'Enter') {
      if (isValid) {
        handleSubmit(handleLogin)();
      }
    }
  };

  const handleForgotPassword = () => setIsLogin(false);
  const handleBack = () => setIsLogin(true);
  const handleClickShowPassword = () => setShowText(!showText);
  const handleMouseDownPassword = (event) => event.preventDefault();

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.header}>
        <Box className={classes.container}>
          <PaybyrdLoginLogo />
          <Link
            data-testid="registerbutton"
            href={MERCHANT_ONBOARDING_URL}
            target="_blank"
            className={classes.btnRound}
          >
            {translator.i18n('register-now')}
          </Link>
        </Box>
      </Box>
      <Box id="main">
        <Box className={classes.contentSection}>
          <Box className={classes.contentWrapper}>
            <Box className={classes.textWrap}>
              {params && params.reason ? (
                <Typography
                  style={{ color: theme.palette.black.main, marginBottom: 20, display: 'inline-block' }}
                  variant="p"
                >
                  {translator.i18n(params.reason, '')}
                </Typography>
              ) : (
                ''
              )}
              {!isLogin && (
                <Box sx={{ display: 'flex' }}>
                  <IconButton aria-label="back" onClick={handleBack} sx={{ color: theme.palette.primary.main }}>
                    <ArrowBackIcon fontSize="inherit" />
                  </IconButton>
                </Box>
              )}
              <Typography className={classes.textTitle} variant="h2">
                {isLogin ? translator.i18n('log-into-paybyrd') : translator.i18n('recover-paybyrd-password')}
              </Typography>
            </Box>
            <Box className={classes.formRow} onKeyUp={handleKeyUp}>
              <Box className={classes.formGroup}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      inputProps={{
                        'data-testid': 'email',
                      }}
                      placeholder={translator.i18n('email')}
                      fullWidth={true}
                      InputProps={{
                        className: classes.formControl,
                        disableunderline: 'true',
                        startAdornment: (
                          <InputAdornment position="start" sx={{ color: theme.palette.primary.main }}>
                            <MailOutlineIcon />
                          </InputAdornment>
                        ),
                      }}
                      {...field}
                    />
                  )}
                />
              </Box>
              {isLogin && (
                <Fragment>
                  <Box className={classes.formGroup}>
                    <Controller
                      name="password"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          inputProps={{
                            'data-testid': 'password',
                          }}
                          placeholder={translator.i18n('password')}
                          type={showText ? 'text' : 'password'}
                          fullWidth={true}
                          InputProps={{
                            className: classes.formControl,
                            disableunderline: 'true',
                            startAdornment: (
                              <InputAdornment position="start" sx={{ color: theme.palette.primary.main }}>
                                <LockOutlinedIcon />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  data-testid="showhide"
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                  size="large"
                                >
                                  {showText ? <VisibilityOff color="primary" /> : <Visibility color="primary" />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...field}
                        />
                      )}
                    />
                  </Box>
                  <Box className={classes.formGroup}>
                    <Button
                      data-testid="recoverbutton"
                      className={classes.textButton}
                      variant="text"
                      onClick={handleForgotPassword}
                    >
                      {translator.i18n('forgot-password')}
                    </Button>
                  </Box>
                  <Box className={classes.formGroup}>
                    <Button
                      data-testid="loginbutton"
                      disabled={!isValid}
                      className={classes.loginButton}
                      onClick={handleSubmit(handleLogin)}
                    >
                      {translator.i18n('login')}
                    </Button>
                  </Box>
                </Fragment>
              )}
              {!isLogin && (
                <Box className={classes.buttonsContainer}>
                  <Box className={[classes.formGroup, classes.doubleGroup]}>
                    <Button
                      data-testid="sendemailbutton"
                      disabled={!isValid}
                      className={classes.loginButton}
                      onClick={handleSubmit(handleChangePassword)}
                    >
                      {translator.i18n('send-recover-email')}
                    </Button>
                  </Box>
                </Box>
              )}
              {error && <Typography className={classes.formError}>{error}</Typography>}
              {recoverSuccess && <Typography className={classes.formSuccess}>{recoverSuccess}</Typography>}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
