import { fork, call } from 'redux-saga/effects';
import { takeEveryAsync } from 'saga-toolkit';

import { api } from '@legacy/api';

import { fetchStatistics, fetchTableStatistics, fetchChartStatistics } from './reducer';

function* handleFetch({ meta: { arg } }) {
  try {
    const data = yield call(api.getBalanceReports, { ...arg.filters });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
}

function* getStatistics() {
  yield takeEveryAsync(fetchStatistics.type, handleFetch);
}

function* getTableStatistics() {
  yield takeEveryAsync(fetchTableStatistics.type, handleFetch);
}

function* getChartStatistics() {
  yield takeEveryAsync(fetchChartStatistics.type, handleFetch);
}

export function* statisticsSaga() {
  yield fork(getStatistics);
  yield fork(getTableStatistics);
  yield fork(getChartStatistics);
}
