import { useEffect } from 'react';

const usePageTitle = (title) => {
  useEffect(() => {
    document.title = `Paybyrd - ${title}`;

    // Send new page title for Google Tag Manager
    const pageTitle = document.title;
    const pagePath = `${location.pathname}${location.search}`;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pageview',
      page_title: pageTitle,
      page_path: pagePath,
    });
  }, [title]);
};

export default usePageTitle;
