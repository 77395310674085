import React, { useState, forwardRef } from 'react';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import FormInput from '@legacy/components/formInput';
import { useStyles } from './styles';

export const FormPasswordInput = (
  { value = null, error, label, onChange, labelAction = null, multiline, autoFocus, max, tabIndex, ...props },
  ref
) => {
  const classes = useStyles();
  const [showText, setShowText] = useState(false);
  const handleClickShowPassword = () => setShowText(!showText);

  return (
    <FormInput label={label} labelAction={labelAction} {...props}>
      <TextField
        ref={ref}
        placeholder={props.placeholder || ''}
        autoFocus={autoFocus}
        tabIndex={tabIndex}
        type={showText ? 'text' : 'password'}
        error={!!error}
        fullWidth
        multiline={multiline}
        rows={multiline && 5}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                data-testid="showhide"
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
                tabIndex="-1"
                size="large"
              >
                {showText ? <VisibilityOff color="primary" /> : <Visibility color="primary" />}
              </IconButton>
            </InputAdornment>
          ),
          disableunderline: 'true',
          classes: {
            input: classes.input,
            error: classes.error,
          },
        }}
        value={value || ''}
        onChange={(e) => {
          if (max && e.target.value.length > max) {
            return;
          }

          return onChange(e);
        }}
      />
      {!!error && <div className={classes.errorField}>{error}</div>}
    </FormInput>
  );
};

export default forwardRef(FormPasswordInput);
