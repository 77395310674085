import React, { createContext, useState, useEffect, useContext } from 'react';
import auth0 from 'auth0-js';

import Login from '@components/pages/Login';
import { APP_AUTH0_DOMAIN, APP_AUTH0_CLIENT_ID, API_AUDIENCE, SCOPES, PERMISSIONS } from '@services/config';

import AxiosInterceptor from '../legacy/common/auth/AxiosInterceptor';
import { useRecoilState } from 'recoil';
import navigationAtom from '../recoil/atoms/navigation';

const AuthContext = createContext();

export const useAuth = () => {
  const authContext = useContext(AuthContext);
  return authContext;
};

export const withAuthenticationRequired = (Component, options = {}) => {
  return function WithAuthenticationRequired(props) {
    const { isAuthenticated, isLoading } = useAuth();
    const { returnTo } = options;

    useEffect(() => {
      if (isLoading || isAuthenticated) {
        return;
      }
    }, [isLoading, isAuthenticated]);

    return isAuthenticated ? <Component {...props} /> : <Login returnTo={returnTo} />;
  };
};

const options = {
  audience: API_AUDIENCE,
  scope: `${Object.values(PERMISSIONS).concat(Object.values(SCOPES)).join(' ')}`,
  domain: APP_AUTH0_DOMAIN,
  clientID: APP_AUTH0_CLIENT_ID,
  redirectUri: `${window.location.origin}`,
};

const AuthProvider = ({ children }) => {
  const [navigation, setNavigation] = useRecoilState(navigationAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [needsLogin, setNeedsLogin] = useState(false);

  const handleAuthResult = (error, authResult) => {
    const webAuth = new auth0.WebAuth(options);

    if (error) {
      const { code } = error;

      if (code && code === 'login_required') {
        setNeedsLogin(true);
        setIsLoading(false);
      } else {
        setError(true);
      }
    }

    if (authResult) {
      webAuth.client.userInfo(authResult.accessToken, function (err, user) {
        setNavigation({ ...navigation, user });
        setUser(user);
        setAccessToken(authResult.accessToken);
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    const webAuth = new auth0.WebAuth(options);
    const hash = window.location.hash.replace('/', '');

    if (hash && hash.includes('accessToken')) {
      webAuth.parseHash({ hash, state: 'paybyrd' }, (error, authResult) => {
        handleAuthResult(error, authResult);
      });
    } else {
      webAuth.checkSession({ responseType: 'token id_token', state: 'paybyrd' }, function (error, authResult) {
        handleAuthResult(error, authResult);
      });
    }
  }, []);

  const handleLogin = (login, cb) => {
    const webAuth = new auth0.WebAuth(options);
    webAuth.login(
      {
        ...login,
        realm: 'Username-Password-Authentication',
        responseType: 'token id_token',
        state: 'paybyrd',
      },
      cb
    );
  };

  const handleLogout = ({ returnTo }) => {
    const webAuth = new auth0.WebAuth(options);
    webAuth.logout({ returnTo });
  };

  const handleChangePassword = (email, cb) => {
    const webAuth = new auth0.WebAuth(options);
    webAuth.changePassword({ connection: 'Username-Password-Authentication', email }, cb);
  };

  const refreshToken = async () => {
    const webAuth = new auth0.WebAuth(options);
    webAuth.checkSession({ responseType: 'token id_token', state: 'paybyrd' }, function (error, authResult) {
      if (authResult) {
        setAccessToken(authResult.accessToken);
        return;
      }
      if (error?.code && error?.code === 'login_required') {
        setAccessToken(null);
        setNeedsLogin(true);
        setIsLoading(false);
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        login: handleLogin,
        logout: handleLogout,
        changePassword: handleChangePassword,
        user,
        error,
        accessToken,
        isLoading,
        isAuthenticated: !!user,
        needsLogin,
        refreshToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const Auth0ProviderWithHistory = ({ children }) => {
  return (
    <AuthProvider>
      <AxiosInterceptor>{children}</AxiosInterceptor>
    </AuthProvider>
  );
};

export default Auth0ProviderWithHistory;
