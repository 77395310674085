export default {
  en: {
    '3ds': '3DS',
    '3ds-acs-reference-number': '3DS ACS Reference Number',
    '3ds-authentication-type': '3DS Authentication Type',
    '3ds-challenged': '3DS Challenged',
    '3ds-requested': '3DS Requested',
    '3ds-version': '3DS Version',
    accept: 'Accept',
    accepted: 'Accepted',
    'account-holder': 'Acount Holder',
    acquirer: 'Acquirer',
    'acquirer-entity': 'Acquirer Entity',
    'acquirer-reference': 'Acquirer Reference',
    'acquirer-response': 'Acquirer Response',
    'acquirer-response-code': 'Acquirer Response Code',
    'acquirer-response-message': 'Acquirer Response Message',
    acquirerfailed: 'Failed',
    acquirerReferenceNumber: 'Acquirer Reference Number (ARN)',
    acquirerId: 'Acquirer Id',
    'acquirer-processing': 'Acquirer Processing',
    acquirerResponseCode: 'Acquirer Response Code',
    acquirerResponseMessage: 'Acquirer Response Message',
    acquirersuccess: 'Success',
    actions: 'Actions',
    add: 'Create',
    'add-filter': 'Add Filter',
    'additional-filters': 'Additional Filters',
    'administration-title': 'Administration',
    all: 'All',
    allAlt: 'All',
    amount: 'Amount',
    'amount-greater-than-limit':
      'The amount is greater than the transaction amount',
    'analytics-title': 'Analytics',
    'api-key-not-created': 'API Key not created',
    'api-keys': 'API Keys',
    channel: 'Channel',
    apply: 'Apply',
    'apply-filters': 'Apply Filters',
    'applied-filters': 'Filter will be applied to your dashboard',
    'aquirer-authorization-code': 'Aquirer Authorization Code',
    aquirerAuthorizationCode: 'Aquirer Authorization Code',
    'attachment-not-found-error': 'Attachment not found',
    attempt: 'Attempt',
    attempts: 'Attempts',
    'authorization-code': 'Authorization  Code',
    authorizationCode: 'Authorization Code',
    authorizationStatus: 'Authorization Status',
    back: 'Back',
    'bank-identifier-code': 'Bank Identifier Code',
    brand: 'Payment Method',
    cardScheme: 'Card Scheme',
    businessName: 'Business Name',
    cancel: 'Cancel',
    canceled: 'Canceled',
    capture: 'Capture',
    'capture-create-success': 'Capture successfully created',
    'capture-transaction': 'Capture Transaction',
    captured: 'Captured',
    capturing: 'Capturing...',
    'card-details': 'Card Details',
    'card-holder': 'Card Holder',
    'card-holder-name': 'Card Holder Name',
    'card-number': 'Card Number',
    cardHolderName: 'Card Holder Name',
    cardIssuerCountry: 'Card Issuer Country',
    cardNumber: 'Card Number',
    cardType: 'Card Type',
    category: 'Category',
    'custom-filters': 'Custom Filters',
    'channel-information': 'Processing Data',
    modes: 'Modes',
    challengeOptions: 'Challenge Options',
    chargeback: 'Chargeback',
    'chargeback-chat': 'Chargeback process comunitaction',
    'chargeback-create-success': 'Chargeback successfully created',
    'chargeback-confirm-attachment-delete': 'Delete attachment?',
    'chargeback-files': 'Chargeback process files',
    'chargeback-information': 'General Information',
    'chargeback-order-creationReason': 'Chargeback creation Reason',
    'chargeback-order-information': 'Order Information',
    'chargeback-ref': 'Chargeback Ref',
    'chargeback-status-closed': 'Closed',
    'chargeback-status-created': 'Created',
    'chargeback-status-disputing': 'Disputing',
    'chargeback-status-lost': 'Lost',
    'chargeback-status-pendingaction': 'Pending Action',
    'chargeback-status-won': 'Won',
    'chargeback-store-information': 'Store Information',
    'chargeback-store-name': 'Store Name',
    'chargeback-title': 'Chargebacks',
    'chargeback-title-add': 'Start Chargeback Process',
    'chargeback-transaction-information': 'Transation Information',
    chargebackRef: 'Chargeback Reference',
    chargebacks: 'Chargebacks',
    'choose-file-type': 'Choose File Type',
    clear: 'Clear',
    'click-to-toggle': 'Click to Toggle Menu',
    'click-to-upload': 'Click here to upload',
    'client-frequent-flyer-number': 'Frequent Flyer Number',
    clientFrequentFlyerNumber: 'Frequent Flyer Number',
    clientFrequentFlightNumber: 'Frequent Flyer Number',
    closed: 'Closed',
    code: 'Code',
    'collect-shipping-data': 'Collect Shipping Data',
    'comission-amount': 'Comission Amount',
    content: 'Content',
    'copied-key': 'Key $1 copied to clipboard!',
    'copied-link': 'Link $1 copied to the clipboard!',
    'copy-key': 'Copy Key',
    costs: 'Costs',
    country: 'Country',
    'country-code': 'Country Code',
    'create-chargeback-from-transaction': 'Create Chargeback',
    'create-checkout': 'Create Checkout',
    created: 'Created',
    'created-date': 'Created Date',
    creating: 'Creating...',
    currency: 'Currency',
    'current-balance': 'Current Balance',
    'customer-address': 'Customer Address',
    'customer-name': 'Customer Name',
    'dashboard-title': 'Dashboard',
    date: 'Date',
    day: 'Day',
    days: 'Days',
    dccAmount: 'DCC Amount',
    declined: 'Declined',
    deductions: 'Deductions',
    'delete-attachment-forbidden-error': "You can't delete this attachment",
    denied: 'Denied',
    deposit: 'Deposit',
    description: 'Description',
    details: 'Details',
    'developers-description': 'Please keep them private as much as possible.',
    'developers-link': 'Read more about integrating our API',
    'developers-subtitle':
      'Use the Test API key to test your integration, and the Live API key to create real payments.',
    'developers-title': 'Developers',
    disputing: 'Disputing',
    'drag-drop-file-here': 'Drop file here',
    domain: 'Domain',
    'domain-not-available': 'Domain not available',
    download: 'Download',
    downloading: 'Downloading...',
    'dst-description': 'This timezone is currently in DST',
    doYouWannaFullChargeback:
      'Do you want to partially chargeback or full chargeback?',
    doYouWannaFullRefund: 'Do you want to partially refund or full refund?',
    dutch: 'Dutch',
    eci: 'ECI',
    ecommerce: 'Ecommerce',
    'edit-checkout': 'Edit Checkout',
    'edit-checkout-success': 'Social Payment successfully edited',
    'edit-data': 'Edit Data',
    editing: 'Editing...',
    email: 'Email',
    'end-date': 'End Date',
    'end-time': 'End time',
    english: 'English',
    'entity-code': 'Entity Code',
    environment: 'Environment',
    'environment-hint':
      'Check to see Production transactions only. Uncheck to see Test transactions only',
    error: 'Error',
    'event-type': 'Event Type',
    'expire-at': 'Expire at',
    expired: 'Expired',
    'expired-token': 'You were redirected because your session has expired',
    'expiry-date': 'Expiry Date',
    export: 'Export $1',
    'export-details': 'Export',
    'export-details-help':
      'When you start exporting your file, our system will process your request. Once processing is complete, we will send the exported file directly to the e-mail address associated with your account ($1).',
    'export-details-alert': 'Attention',
    externalTransactionId: 'External Transaction Id',
    externalCustomId: 'Acquirer Id',
    'extra-filters-title': 'Custom Filtering',
    failed: 'Failed',
    fees: 'Fees',
    'file-sent': '$1 was sent.',
    'file-upload-helper': 'To upload a file, click in the label of the input',
    'filter-by': 'Filter by $1',
    'filter-by-enter': 'Filter by $1 (Press Enter to Search)',
    'fin-product': 'Fin. Product',
    firstname: 'First Name',
    flightData: 'Flight Data',
    'forgot-password': 'Forgot Password?',
    'fraud-scoring': 'Fraud Scoring',
    french: 'French',
    fulfilled: 'Fulfilled',
    'full-chargeback': 'Full chargeback',
    'full-refund': 'Full refund',
    'full-capture': 'Full capture',
    'funding-source': 'Funding Source',
    'generate-checkout': 'Generate Checkout',
    german: 'German',
    'go-to': 'Go to $1',
    'group': 'Group',
    'group-id': 'Group ID',
    'group-name': 'Group Name',
    groupId: 'Group',
    hasChargeback: 'Chargeback Associated',
    'hide-all': 'Show All',
    holder: 'Holder',
    hour: 'Hour',
    hours: 'Hours',
    history: 'History',
    'transaction-history': 'Transaction History',
    iban: 'IBAN',
    id: 'ID',
    'in-transactions': 'in $1 transactions',
    'initiated-from': 'Initiated From',
    initiatedFrom: 'Initiated From',
    installmentAmount: 'Installment Amount',
    installments: 'Installments',
    international: 'International',
    'international-shipping-amount': 'International Shipping Amount',
    'international-urgent': 'International Urgent',
    'international-urgent-shipping-amount':
      'International Urgent Shipping Amount',
    'invite-error': 'There was an error sending the invite.',
    'invite-merchants': 'Invite Merchants',
    'invite-sent':
      "The invitation was sent to '$1'. Onboarding form generated at: $2",
    invoice: 'Invoice',
    invoices: 'Invoices',
    ip: 'IP',
    'is-one-click-payment': 'Is one click',
    isoAmount: 'Amount',
    isOneClickPayment: 'Is one click',
    'item-service-amount': 'Item/Service Amount',
    'item-service-description': 'Item/Service Description',
    'item-service-image': 'Upload Item/Service Image',
    'item-service-name': 'Item/Service Name',
    'journal-date': 'Journal Date',
    language: 'Language',
    'last-4-card-numbers': 'last 4 card numbers',
    'last-chargebacks': 'Last $1 chargebacks',
    'last-month': 'Last Month',
    'last-refunds': 'Last $1 refunds',
    'last-revenue': 'Last $1 revenue',
    'last-week': 'Last Week',
    lastFourCardNumber: 'Last Four Card Number',
    lastname: 'Last Name',
    'latest-payments': 'Latest Payments (production only)',
    'liability-shift': 'Liability Shift',
    LiabilityShift: 'Liability Shift',
    'link-validity': 'Link validity',
    'live-api-key': 'Live API Key',
    loading: 'Loading...',
    'loading-order': 'Loading Order Data',
    'loading-ref-transactions': 'Loading Related Transactions',
    'loading-transaction': 'Loading Transaction Data',
    'loading-webhook-attempts': 'Loading Webhook Attempts',
    'log-into-paybyrd': 'Log into Paybyrd',
    login: 'Login',
    logout: 'Logout',
    lost: 'Lost',
    'management-title': 'Management',
    market: 'Market',
    maskedCardNumber: 'Masked Card Number',
    merchant: 'Merchant',
    'merchant-id': 'Merchant ID',
    'merchant-information': 'Merchant Information',
    'merchant-name': 'Merchant Name',
    merchantId: 'Merchant',
    merchantIds: 'Merchants',
    'merchant-pendent': 'Merchant Pendent',
    'message-label': 'Message',
    mode: 'Mode',
    month: 'Month',
    'monthly-revenue': 'Monthly Revenue (production only)',
    months: 'Months',
    name: 'Name',
    national: 'National',
    'national-shipping-amount': 'National Shipping Amount',
    'national-urgent': 'National Urgent',
    'national-urgent-shipping-amount': 'National Urgent Shipping Amount',
    no: 'No',
    'no-balance': " We couldn't find a balance for the selected filters",
    'no-balance-description': 'Try selecting another month, or units above',
    'no-chargebacks': "We couldn't find chargebacks for the selected filters",
    'no-chargebacks-subtitle':
      'Try selecting another range, units or status above',
    'no-info': 'No Info',
    'no-invoices': 'There is no invoices for the selected period',
    'no-invoices-description': 'Try selecting another year',
    'no-onboardings':
      "We couldn't find onboarding processes for the current filters",
    'no-onboardings-subtitle': 'Try another combination',
    'no-options': 'No Options',
    'no-orders': "We couldn't find orders for the selected filters",
    'no-orders-subtitle': 'Try changing the filters',
    'no-payments': 'There is no recent transactions',
    'no-payments-subtitle':
      'Refresh the grid to check if there are new transactions available',
    'no-reconciliation-files': 'There are no reconciliation files available',
    'no-ref-transactions':
      "We couldn't find related transactions for this order",
    'no-ref-transactions-subtitle':
      'Refresh the grid to check if there is new transactions available',
    'no-results': 'No Results',
    'no-settlements': 'There are no settlements for the selected period',
    'no-settlements-description': 'Try selecting another month',
    'no-payouts': 'There are no payouts for the selected period',
    'no-shifts': " We couldn't find transactions for the selected filters",
    'no-shifts-subtitle':
      'Try selecting another range, units, status or modes above',
    'no-statistics': "We couldn't find statistics for the selected filters",
    'no-statistics-subtitle': 'Try selecting another period or units above',
    'no-totals': "We couldn't find statistics for the current month",
    'no-totals-subtitle': 'Check statistics pages for other periods',
    'no-transactions': "We couldn't find transactions for the selected filters",
    'no-transactions-subtitle':
      'Try selecting another range, units, status or modes above',
    'no-webhooks': "We couldn't find webhooks for the selected filters",
    none: 'None',
    'not-visible-columns': 'Hidden Columns',
    now: 'Now',
    onboarding: 'Onboarding',
    'onboarding-details': 'Onboarding Details',
    onboardings: 'Onboardings',
    opened: 'Opened',
    'order-amount': 'Order Amount',
    'order-date': 'Order Date',
    'order-ref': 'Order Ref',
    'order-reference': 'Order Reference',
    order: 'Order',
    orderProcessing: 'Order Processing',
    orderDenied: 'Order Denied',
    orderPaid: 'Order Paid',
    orderPending: 'Order Pending',
    orderRefunded: 'Order Refunded',
    orderCanceled: 'Order Canceled',
    orderError: 'Order Error',
    orderFailed: 'Order Failed',
    orderExpired: 'Order Expired',
    orderDeclined: 'Order Declined',
    orderCreated: 'Order Created',
    orderClosed: 'Order Closed',
    orderOpened: 'Order Opened',
    noOrderInfo: 'No Order Info',
    'order-details': 'Order Details',
    orderPaymentInformation: 'Order Payment Information',
    orderId: 'Order Id',
    orderRef: 'Order Ref',
    orderref: 'Order Ref',
    orders: 'Orders',
    'orders-title': 'Orders',
    paid: 'Paid',
    'parent-payment-id': 'Parent Payment ID',
    'partially captured': 'Partially captured',
    'partially refunded': 'Partially refunded',
    'partial-chargeback': 'Partial chargeback',
    'partial-refund': 'Partial refund',
    'partial-capture': 'Partial capture',
    'partners-title': 'Partners',
    'passenger-name-record': 'Passenger Name Record',
    password: 'Password',
    'pay-by-link': 'PayByLink',
    'pay-by-link-create': 'Create new pay link',
    'pay-by-link-description': 'Create, send pay links and get paid online',
    'pay-by-link-title': 'Pay by Link',
    'pay-link': 'Pay link',
    'pay-links': 'pay links',
    payment: 'Payment',
    'payment-amount': 'Payment Amount',
    'payment-id': 'Payment ID',
    'payment-information': 'Payment Information',
    'payment-link-error': 'An error occurred while sending the links',
    'payment-link-success': 'Payment link was successfully sent.',
    'payment-method': 'Payment Method',
    'payment-purpose': 'Payment Purpose',
    paymentDate: 'Payment Date',
    paymentId: 'Transaction Id',
    paymentInformation: 'Payment Information',
    paymentMethod: 'Payment Method',
    payments: 'Payments',
    'payments-title': 'Payments',
    'payouts-title': 'Payouts',
    'payout-title': 'Detail $1',
    'payouts-warning-message':
      'This feature is temporarily unavailable. We are making improvements to our system to serve you even better. We apologize for the inconvenience caused and will be quick to restore this functionality. For any further clarification, you may contact us through the usual channels.',
    pending: 'Pending',
    pendingaction: 'Pending Action',
    pendingmerchantaction: 'Pending Merchant Action',
    period: 'Period',
    'phone-number': 'Phone Number',
    phoneNumber: 'Phone Number',
    pnr: 'PNR',
    portuguese: 'Portuguese',
    pos: 'POS',
    'pre-auth': 'Pre-Auth',
    preauth: 'PreAuth',
    'predictions-dispute': 'I want to dispute',
    'predictions-question': 'I have a question',
    'preferred-payment-methods': 'Preferred Payment Methods',
    'previous-revenue': 'Previous Revenue',
    processing: 'Processing',
    processingData: 'Processing Data',
    prod: 'Production',
    'product-name': 'Product Name',
    quarter: 'Quarter',
    quarters: 'Quarters',
    range: 'Range',
    reason: 'Reason for initiating Chargeback',
    'recipient-email': 'Recipient Email',
    'recipient-name': 'Recipient name',
    'recipient-number': 'Recipient Phone Number',
    'reconciliation-date': 'Reconciliation date',
    'reconciliation-file': 'Reconciliation file',
    'reconciliation-files': 'Reconciliation files',
    'recover-paybyrd-password': 'Recover password',
    'recovery-password-success':
      "We've sent you an email to reset your password.",
    reference: 'Reference',
    'reference-ids': 'Reference Ids',
    refresh: 'Refresh',
    refund: 'Refund',
    'refund-amount': 'Refund Amount',
    refundDate: 'Refund Date',
    'refund-confirm-message':
      'Do you really want to refund this transaction in the amount of $1?',
    'refund-transaction': 'Refund Transaction',
    refunded: 'Refunded',
    refunding: 'Refunding...',
    refunds: 'Refunds',
    'refund-create-success': 'Refund successfully created',
    'register-now': 'Register Now',
    'related-operation-status': 'Related Operation Status',
    'related-shift': 'Related Shift',
    'related-status': 'Related Status',
    'related-transactions': 'Related Transactions',
    relatedOperationStatus: 'Related Operation Status',
    release: 'Release',
    releasing: 'Releasing...',
    remark: 'Remark',
    'request-info': 'Request Info',
    'required-field': 'This field is required',
    resend: 'Resend',
    'resend-error': "There was an error when resending the webhook '$1'.",
    'resend-success': "The webhook '$1' was resent successfully",
    resending: 'Resending...',
    reservation: 'Reservation',
    response: 'Response',
    revenue: 'Revenue',
    'reverse-confirmation':
      'Are you sure you want to release the transaction with id $1?',
    'reverse-create-success': 'Reverse successfully created',
    'search-filters': 'Search filters',
    'search-order-ref':
      'Search order reference value. At least 3 characters required to start searching',
    'search-transaction': 'Search Transaction',
    'select-all': 'Select All',
    'deselect-all': 'Deselect all',
    'select-filter': 'Select Filter',
    selected: 'selected',
    'selected-s': '$1 $2 selected',
    'send-files': 'Send Files',
    'send-invite': 'Send Invite',
    'send-link': 'Send link',
    'send-links': 'Send links',
    'send-message': 'Send Message',
    'send-message-and-dispute': 'Send message and open Dispute',
    'send-recover-email': 'Send email',
    sending: 'Sending...',
    'sent-at': 'Sent At',
    'settings-title': 'Settings',
    'settlement-date': 'Settlement Date',
    'settlement-details': 'Settlement Details',
    settlements: 'Settlements',
    shift: 'Shift $1',
    'shift-details': 'Shift Details',
    'shifts-title': 'Shifts',
    'shipping-address': 'Shipping Address',
    'shipping-amount': 'Shipping Amount',
    shopper: 'Shopper',
    'shopper-country': 'Shopper Country',
    'shopper-details': 'Shopper Details',
    'shopper-email': 'Shopper Email',
    'shopper-interaction': 'Shopper Interaction',
    'shopper-ip-address': 'Shopper IP Address',
    shopperEmail: 'Shopper Email',
    shopperInformation: 'Shopper Information',
    shopperIp: 'Shopper IP Address',
    'show-all': 'Hide All',
    'show-details': 'Show details',
    'social-payments-title': 'Social Payments',
    spanish: 'Spanish',
    'start-date': 'Start Date',
    'start-time': 'Start time',
    'statistics-title': 'Statistics',
    status: 'Status',
    stock: 'Stock',
    'stock-quantity': 'Stock Quantity',
    store: 'Store',
    'store-name': 'Store Name',
    storeId: 'Store',
    success: 'Success',
    'system-language': 'System Language',
    'system-timezone': 'System Timezone',
    'temporary-failed': 'Temporary Failed',
    'terminal-code': 'Terminal Code',
    'terminal-serial-number': 'Terminal Serial Number',
    terminalCode: 'Terminal Code',
    terminalPassword: 'Terminal Password',
    'new-password': 'New Password',
    'confirm-new-password': 'Confirm New Password',
    'save-password': 'Save Password',
    'changing-password': 'Changing Password...',
    'confirm-change-password-message': 'Confirm terminal password change?',
    'passwords-do-not-match-message': 'Passwords do not match!',
    'change-terminal-password': 'Change Terminal Password',
    'password-must-have-six-numbers': 'The password must have six numbers!',
    'password-changed-successfully': 'Password changed successfully!',
    terminalSerialNumber: 'Terminal Serial Number',
    test: 'Test',
    'test-api-key': 'Test API Key',
    'this-month': 'This Month',
    'this-week': 'This Week',
    threeDSecure: '3DS',
    title: 'Title',
    today: 'Today',
    total: 'Total',
    'total-amount': 'Total Amount',
    'total-revenue': 'Total Revenue',
    totals: 'Totals',
    'totals-per': 'Totals per $1',
    'transaction-amount': 'Transaction Amount',
    'transaction-details': 'Transaction Details',
    'transaction-type': 'Transaction Type',
    transactionId: 'Transaction Id',
    transactions: 'transactions',
    transactionMbWay: 'Transaction MBWAY',
    transactionMultibanco: 'Transaction Multibanco',
    transactionMulticaixa: 'Transaction Multicaixa',
    transactionPayPal: 'Transaction PayPal',
    transactionIdeal: 'Transaction IDeal',
    'transactions-count': 'Transactions Count',
    'transactions-title': 'Transactions',
    'transactions-volume': 'Transactions Volume',
    'transactions-multiple-currencies-found':
      'When multiple currencies are selected, the total revenue/amount may not be displayed.',
    'transation-processing-title': 'Processing',
    transfer: 'Transfer',
    tranStatus: '3DS Trans Status',
    type: 'Type',
    unit: 'Store',
    units: 'Stores',
    user: 'User',
    'validation-max-chars':
      'The maximum length for this field is $1 characters',
    'verification-method': 'Verification Method',
    version: 'Version',
    'view-by': 'View by $1',
    'view-details': 'View details',
    'view-less': 'View less',
    'view-more': '+$1 More',
    'view-transaction-details-hint': 'Click to view transaction details.',
    'visible-columns': 'Visible Columns',
    volume: 'Volume',
    webhooks: 'Webhooks',
    week: 'Week',
    weeks: 'Weeks',
    'with-value': 'with value',
    withdrawal: 'Withdrawal',
    won: 'Won',
    year: 'Year',
    years: 'Years',
    yes: 'Yes',
    yesterday: 'Yesterday',
  },
  pt: {
    '3ds': '3DS',
    '3ds-acs-reference-number': '3DS ACS Reference Number',
    '3ds-authentication-type': '3DS Authentication Type',
    '3ds-challenged': '3DS Challenged',
    '3ds-requested': '3DS Requested',
    '3ds-version': '3DS Version',
    accept: 'Aceitar',
    accepted: 'Aceite',
    'account-holder': 'Titular da Conta',
    acquirer: 'Adquirente',
    'acquirer-entity': 'Entidade do Adquirente',
    'acquirer-reference': 'Referência do Adquirente',
    'acquirer-response': 'Resposta do Adquirente',
    'acquirer-response-code': 'Código de Resposta da Adquirente',
    'acquirer-response-message': 'Mensagem de Resposta da Adquirente',
    acquirerfailed: 'Falhou',
    acquirerReferenceNumber: 'Referência do Adquirente (ARN)',
    acquirerId: 'Identificador do adquirente',
    'acquirer-processing': 'Processamento do Adquirente',
    acquirerResponseCode: 'Código de resposta da Adquirente',
    acquirerResponseMessage: 'Mensagem de resposta da Adquirente',
    acquirersuccess: 'Successo',
    actions: 'Ações',
    add: 'Adicionar',
    'add-filter': 'Adicionar filtro',
    'additional-filters': 'Filtros Adicionais',
    'administration-title': 'Administração',
    all: 'Todos(as)',
    allAlt: 'Todos(as)',
    amount: 'Valor',
    'amount-greater-than-limit': 'O valor é maior do que o valor da transação',
    'analytics-title': 'Analytics',
    'api-key-not-created': 'API Key não foi criada',
    'api-keys': 'API Keys',
    channel: 'Canal',
    apply: 'Aplicar',
    'apply-filters': 'Aplicar Filtros',
    'applied-filters': 'O filtro será aplicado ao seu dashboard',
    'aquirer-authorization-code': 'Código de autorização da adquirente',
    aquirerAuthorizationCode: 'Código de autorização da adquirente',
    'attachment-not-found-error': 'Anexo não encontrado',
    attempt: 'Tentativa',
    attempts: 'Tentativas',
    'authorization-code': 'Código de autorização',
    authorizationCode: 'Status de Autorização',
    authorizationStatus: 'Authorization Status',
    back: 'Voltar',
    'bank-identifier-code': 'Código de identificação do banco',
    brand: 'Método de Pagamento',
    cardScheme: 'Bandeira',
    businessName: 'Nome da Loja',
    cancel: 'Cancelar',
    canceled: 'Cancelado',
    capture: 'Cativação',
    'capture-create-success': 'Captura criada com sucesso',
    'capture-transaction': 'Transação de cativação',
    captured: 'Capturada',
    capturing: 'Efetuando a captura',
    'card-details': 'Detalhes do cartão',
    'card-holder': 'Titular do cartão',
    'card-holder-name': 'Nome no cartão',
    'card-number': 'Número do cartão',
    cardHolderName: 'Titular do cartão',
    cardIssuerCountry: 'País emissor do cartão',
    cardNumber: 'Número do cartão',
    cardType: 'Tipo do cartão',
    category: 'Categoria',
    'custom-filters': 'Filtros personalizados',
    'channel-information': 'Dados do Processamento',
    modes: 'Modalidades',
    challengeOptions: 'Opções do desafio',
    chargeback: 'Disputa',
    'chargeback-chat': 'Comunicações do processo de Disputa',
    'chargeback-create-success': 'Chargeback criado com sucesso',
    'chargeback-confirm-attachment-delete': 'Eliminar anexo?',
    'chargeback-files': 'Ficheiros associados',
    'chargeback-information': 'Informação geral',
    'chargeback-order-creationReason': 'Razão para criação do Chargeback',
    'chargeback-order-information': 'Informação da Order',
    'chargeback-ref': 'Referencia',
    'chargeback-status-closed': 'Fechado',
    'chargeback-status-created': 'Criado',
    'chargeback-status-disputing': 'Em disputa',
    'chargeback-status-lost': 'Perdido',
    'chargeback-status-pendingaction': 'Ação Pendente',
    'chargeback-status-won': 'Ganho',
    'chargeback-store-information': 'Informação da Loja',
    'chargeback-store-name': 'Nome da Loja',
    'chargeback-title': 'Disputas',
    'chargeback-title-add': 'Iniciar processo de Disputa',
    'chargeback-transaction-information': 'Informação da Transação',
    chargebackRef: 'Referencia da disputa',
    chargebacks: 'Chargebacks',
    'choose-file-type': 'Escolhe o tipo de ficheiro',
    clear: 'Limpar',
    'click-to-toggle': 'Clique para alternar o menu',
    'click-to-upload': 'Clique aqui para carregar',
    'client-frequent-flyer-number': 'Número de passageiro frequente',
    clientFrequentFlyerNumber: 'Número de Passageiro Frequente',
    clientFrequentFlightNumber: 'Número de Passageiro Frequente',
    closed: 'Fechado',
    code: 'Código',
    'collect-shipping-data': 'Dados de envio',
    'comission-amount': 'Valor da Comissão',
    content: 'Conteúdo',
    'copied-key': 'Key $1 copiada para área de transferência!',
    'copied-link': 'Link {1} copiado para a área de transferência!',
    'copy-key': 'Copiar Key',
    costs: 'Custos',
    country: 'País',
    'country-code': 'Código do país',
    'create-chargeback-from-transaction': 'Criar Disputa',
    'create-checkout': 'Criar Check-out',
    created: 'Criado',
    'created-date': 'Data de criação',
    creating: 'Criando...',
    currency: 'Moeda',
    'current-balance': 'Balanço corrente',
    'customer-address': 'Morada do cliente',
    'customer-name': 'Nome do cliente',
    'dashboard-title': 'Painel de Controlo',
    date: 'Data',
    day: 'Dia',
    days: 'Dias',
    dccAmount: 'Valor DCC',
    declined: 'Recusado',
    deductions: 'Deduções',
    'delete-attachment-forbidden-error': 'Deleção do anexo não permitida',
    denied: 'Negado',
    deposit: 'Deposito',
    description: 'Descrição',
    details: 'Detalhes',
    'developers-description':
      'Por favor, mantenha-os privados tanto quanto possível.',
    'developers-link': 'Ler mais sobre como integrar a API',
    'developers-subtitle':
      'Use a API Key de teste para testar a sua integração, e a Live API key para criar pagamentos reais.',
    'developers-title': 'Desenvolvedores',
    disputing: 'Em disputa',
    'drag-drop-file-here': 'Arrastar ficheiro para aqui',
    domain: 'Domínio',
    'domain-not-available': 'Domínio não se encontra disponível',
    download: 'Download',
    downloading: 'Descarregando...',
    'dst-description': 'Esta timezone está atualmente em DST',
    doYouWannaFullChargeback: 'Você quer fazer um estorno parcial ou completo?',
    doYouWannaFullRefund: 'Você quer fazer um reembolso parcial ou total?',
    dutch: 'Holandês',
    eci: 'ECI',
    ecommerce: 'E-commerce',
    'edit-checkout': 'Editar check-out',
    'edit-checkout-success': 'Social Payment editado com sucesso',
    'edit-data': 'Editar dados',
    editing: 'Editando...',
    email: 'E-mail',
    'end-date': 'Data fim',
    'end-time': 'Hora de fim',
    english: 'Inglês',
    'entity-code': 'Código da entidade',
    environment: 'Ambiente',
    'environment-hint':
      'Marcar para ver transações de Produção. Desmarcar para ver transações de Teste.',
    error: 'Erro',
    'event-type': 'Tipo de Evento',
    'expire-at': 'Expira em',
    expired: 'Expirado',
    'expired-token': 'Foi redirecionado, porque sua sessão expirou',
    'expiry-date': 'Data de expiração',
    export: 'Exportar $1',
    'export-details': 'Exportação',
    'export-details-help':
      'Quando começar a exportar o seu ficheiro, o nosso sistema irá processar o seu pedido. Depois da conclusão do processamento, enviaremos o ficheiro exportado directamente para o e-mail associado à sua conta ($1).',
    'export-details-alert': 'Atenção',
    externalTransactionId: 'ID da transação externa',
    externalCustomId: 'Identificador do adquirente',
    'extra-filters-title': 'Filtros customizados',
    failed: 'Falhou',
    fees: 'Taxas',
    'file-sent': '$1 foi enviado',
    'file-upload-helper':
      'Para efetuar upload de um arquivo, clique no rótulo da entrada',
    'filter-by': 'Filtrar por $1',
    'filter-by-enter': 'Filtrar por $1 (Pressione Enter para Pesquisar)',
    'fin-product': 'Produto final',
    firstname: 'Primeiro nome',
    flightData: 'Dados do Voo',
    'forgot-password': 'Esqueceu-se da sua Password?',
    'fraud-scoring': 'Fraud Scoring',
    french: 'Francês',
    fulfilled: 'Realizada',
    'full-chargeback': 'Estorno completo',
    'full-refund': 'Reembolso total',
    'full-capture': 'Captura completa',
    'funding-source': 'Funding Source',
    'generate-checkout': 'Gerar Check-out',
    german: 'Alemão',
    'go-to': 'Ir para $1',
    'group': 'Grupo',
    'group-id': 'ID do grupo',
    'group-name': 'Nome do grupo',
    groupId: 'Grupo',
    hasChargeback: 'Tem Chargeback',
    'hide-all': 'Mostrar Tudo',
    holder: 'nome',
    hour: 'Hora',
    hours: 'Horas',
    history: 'Histórico',
    'transaction-history': 'Histórico de Transações',
    iban: 'IBAN',
    id: 'ID',
    'in-transactions': 'em $1 transações',
    'initiated-from': 'Iniciado a partir de',
    initiatedFrom: 'Inciado por',
    installmentAmount: 'Valor da prestação',
    installments: 'Prestações',
    international: 'Internacional',
    'international-shipping-amount': 'Valor do envio internacional',
    'international-urgent': 'Internacional Urgente',
    'international-urgent-shipping-amount':
      'Valor do envio internacional urgente',
    'invite-error': 'Ocorreu um erro ao enviar o convite.',
    'invite-merchants': 'Convidar Comerciantes',
    'invite-sent':
      'O convite foi enviado para $1. Formulário de Onboarding gerado em: $2',
    invoice: 'Fatura',
    invoices: 'Faturas',
    ip: 'IP',
    'is-one-click-payment': 'Pagamento com um clique',
    isoAmount: 'Valor',
    isOneClickPayment: 'Pagamento um clique',
    'item-service-amount': 'Preço do produto/serviço',
    'item-service-description': 'Descrição do produto/serviço',
    'item-service-image': 'Upload de imagem de produto/serviço',
    'item-service-name': 'Nome do produto/serviço',
    'journal-date': 'Data do Diário',
    language: 'Idioma',
    'last-4-card-numbers': 'últimos 4 digitos do cartão',
    'last-chargebacks': 'Ultimo $1 de estorno',
    'last-month': 'Mês passado',
    'last-refunds': 'Ultimo $1 de devoluções',
    'last-revenue': 'Ultimo $1 de receita',
    'last-week': 'Semana passada',
    lastFourCardNumber: 'Últimos digitos do cartão',
    lastname: 'Último nome',
    'latest-payments': 'Últimos Pagamentos (somente produção)',
    'liability-shift': 'Liability Shift',
    LiabilityShift: 'Liability Shift',
    'link-validity': 'Validade do link',
    'live-api-key': 'Live API Key',
    loading: 'A carregar...',
    'loading-order': 'A carregar dados do Pedido',
    'loading-ref-transactions': 'A carregar transacções relacionadas',
    'loading-transaction': 'A carregar dados da Transação',
    'loading-webhook-attempts': 'A carregar tentativas de webhook',
    'log-into-paybyrd': 'Log into Paybyrd',
    login: 'Login',
    logout: 'Logout',
    lost: 'Perdida',
    'management-title': 'Gestão',
    market: 'Mercado',
    maskedCardNumber: 'Número do cartão',
    merchant: 'Comerciante',
    'merchant-id': 'ID do comerciante',
    'merchant-information': 'Informações do comerciante',
    'merchant-name': 'Nome do comerciante',
    merchantId: 'Comerciante',
    merchantIds: 'Comerciantes',
    'merchant-pendent': 'Comerciante Pendente',
    'message-label': 'Mensagem',
    mode: 'Modo',
    month: 'Mês',
    'monthly-revenue': 'Receita Mensal (somente produção)',
    months: 'Meses',
    name: 'Nome',
    national: 'Nacional',
    'national-shipping-amount': 'Valor do envio nacional',
    'national-urgent': 'Nacional Urgente',
    'national-urgent-shipping-amount': 'Valor do envio nacional urgente',
    no: 'Não',
    'no-balance':
      'Não foi possível encontrar saldo para os filtros selecionados',
    'no-balance-description': 'Tente selecionar outro mês, ou unidades acima ',
    'no-chargebacks':
      'Não houve ocorrências para a seleção de filtros efetuada',
    'no-chargebacks-subtitle':
      'Tente selecionar outra combinação de filtros acima',
    'no-info': 'Sem informação',
    'no-invoices': 'Não há faturas para o período selecionado',
    'no-invoices-description': 'Tente selecionar outro ano',
    'no-onboardings':
      'Não foi possível encontrar processos de Onboarding para os filtros selecionados',
    'no-onboardings-subtitle': 'Tente outra combinação de filtros',
    'no-options': 'Sem opções',
    'no-orders': 'Não houve ocorrências para a seleção de filtros efetuada',
    'no-orders-subtitle': 'Experimente mudar os filtros',
    'no-payments': 'Nao há registo de transações recentes',
    'no-payments-subtitle':
      'Atualize os dados para verificar se existem novas transações',
    'no-reconciliation-files': 'Não existem ficheiros de reconciliação',
    'no-ref-transactions': 'Não houve ocorrências relacionadas com este pedido',
    'no-ref-transactions-subtitle':
      'Efetue refresh para verificar se existem novas transações,',
    'no-results': 'Sem ocorrências',
    'no-settlements':
      'Não foram encontradas liquidações no período selecionado',
    'no-settlements-description': 'Tente selecionar outro mês',
    'no-payouts': 'Não foram encontrados payouts no período selecionado',
    'no-shifts': 'Não houve ocorrências para a seleção de filtros efetuada',
    'no-shifts-subtitle':
      'Tente selecionar outro espaço de tempo, unidade, status ou canais acima',
    'no-statistics':
      'Não há dados estatísticos para a seleção de filtros efetuada',
    'no-statistics-subtitle': 'Selecione outro período ou unidades acima',
    'no-totals': 'Não há estatísticas dispponíveis para o mês corrente',
    'no-totals-subtitle':
      'Verificar páginas de estatísticas para outros períodos',
    'no-transactions':
      'Não houve ocorrências para a seleção de filtros efetuada',
    'no-transactions-subtitle':
      'Tente selecionar outro espaço de tempo, unidade, status ou canais acima',
    'no-webhooks': 'Não houve ocorrências para a seleção de filtros efetuada',
    none: 'Nenhum',
    'not-visible-columns': 'Colunas Escondidas',
    now: 'Agora',
    onboarding: 'Onboarding',
    'onboarding-details': 'Detalhe do Onboarding',
    onboardings: 'Onboardings',
    opened: 'Aberto',
    'order-amount': 'Montante pedido',
    'order-date': 'Data do pedido',
    'order-ref': 'Ref. do Pedido',
    'order-reference': 'Referencia do Pedido',
    order: 'Pedido',
    orderProcessing: 'Processando Pedido',
    orderDenied: 'Pedido Negado',
    orderPaid: 'Pedido Pago',
    orderPending: 'Pedido Pendente',
    orderRefunded: 'Pedido Reembolsado',
    orderCanceled: 'Pedido Cancelado',
    orderError: 'Erro no Pedido',
    orderFailed: 'Pedido Falhou',
    orderExpired: 'Pedido Expirado',
    orderDeclined: 'Pedido Recusado',
    orderCreated: 'Pedido Criado',
    orderClosed: 'Pedido Fechado',
    orderOpened: 'Pedido Aberto',
    noOrderInfo: 'Sem Informações do Pedido',
    'order-details': 'Detalhes do Pedido',
    orderPaymentInformation: 'Informações de Pagamento do Pedido',
    orderId: 'Id do pedido',
    orderRef: 'Ref. do pedido',
    orderref: 'Ref. do Pedido',
    orders: 'Pedidos',
    'orders-title': 'Pedidos',
    paid: 'Pago',
    'parent-payment-id': 'ID de pagamento Original',
    'partially captured': 'Parcialmente capturada',
    'partially refunded': 'Parcialmente devolvida',
    'partial-chargeback': 'Estorno parcial',
    'partial-refund': 'Reembolso parcial',
    'partial-capture': 'Captura parcial',
    'partners-title': 'Parceiros',
    'passenger-name-record': 'Registo do nome do passageiro',
    password: 'Password',
    'pay-by-link': 'PayByLink',
    'pay-by-link-create': 'Criar novo link de pagamento',
    'pay-by-link-description':
      'Criar, enviar links de pagamento e receber online',
    'pay-by-link-title': 'Pay by Link',
    'pay-link': 'Link de pagamento',
    'pay-links': 'links de pagamento',
    payment: 'Pagamento',
    'payment-amount': 'Valor do Pagamento',
    'payment-id': 'Código de pagamento',
    'payment-information': 'Informações de pagamento',
    'payment-link-error': 'Ocorreu um erro ao enviar os links.',
    'payment-link-success': 'Link de pagamento enviado com sucesso.',
    'payment-method': 'Método de pagamento',
    'payment-purpose': 'Finalidade do pagamento',
    paymentDate: 'Data de Pagamento',
    paymentId: 'Código de Pagamento',
    paymentInformation: 'Informação de pagamento',
    paymentMethod: 'Método de pagamento',
    payments: 'Pagamentos',
    'payments-title': 'Pagamentos',
    'payouts-title': 'Pagamentos',
    'payout-title': 'Detalhe $1',
    'payouts-warning-message':
      'Esta funcionalidade encontra-se temporariamente indisponível. Estamos a proceder a melhorias no nosso sistema para o servir ainda melhor. Lamentamos o transtorno causado e seremos breves na reposição desta funcionalidade. Para qualquer esclarecimento adicional, poderão contactar-nos pelos canais habituais.',
    pending: 'Pendente',
    pendingaction: 'Ação pendente',
    pendingmerchantaction: 'Ação pendente do comerciante',
    period: 'Período',
    'phone-number': 'Número de telefone',
    phoneNumber: 'Número de telefone',
    pnr: 'PNR',
    portuguese: 'Português',
    pos: 'POS',
    'pre-auth': 'Pré-Autorização',
    preauth: 'Pre-autorização',
    'predictions-dispute': 'Quero abrir uma disputa',
    'predictions-question': 'Tenho uma pergunta',
    'preferred-payment-methods': 'Métodos de Pagamento Preferidos',
    'previous-revenue': 'Receita Anterior',
    processing: 'Processando',
    processingData: 'Dados de Processamento',
    prod: 'Produção',
    'product-name': 'Nome do Produto/Serviço',
    quarter: 'Trimestre',
    quarters: 'Trimestres',
    range: 'Calendário',
    reason: 'Razão para iniciar o processo de Chargeback',
    'recipient-email': 'E-mail do destinatário',
    'recipient-name': 'Nome do destinatário ',
    'recipient-number': 'Contacto do destinatário',
    'reconciliation-date': 'Data de reconciliação',
    'reconciliation-file': 'Arquivo de reconciliação',
    'reconciliation-files': 'Arquivos de Reconciliação',
    'recover-paybyrd-password': 'Recuperar password',
    'recovery-password-success':
      'Foi enviado um e-mail para  redefinir sua password.',
    reference: 'Referência',
    'reference-ids': 'IDs de Referência',
    refresh: 'Atualizar',
    refund: 'Devolução',
    'refund-amount': 'Quantia da devolução',
    refundDate: 'Data da Devolução',
    'refund-confirm-message':
      'Deseja avançar com o refund desta transacção no valor de $1?',
    'refund-transaction': 'Transação de devolução',
    refunded: 'Devolvida',
    refunding: 'Efetuando a devolução',
    refunds: 'Devoluções',
    'refund-create-success': 'Reembolso criado com sucesso',
    'register-now': 'Registar Agora',
    'related-operation-status': 'Status de Operação Relacionado',
    'related-shift': 'Turnos Relacionados',
    'related-status': 'Estado Relacionado',
    'related-transactions': 'Transações relacionadas',
    relatedOperationStatus: 'Estado da operação associada',
    release: 'Cancelar autorização',
    releasing: 'Cancelando autorização...',
    remark: 'Detalhe',
    'request-info': 'Pedir Informação',
    'required-field': 'Campo é obrigatório',
    resend: 'Reenviar',
    'resend-error': "Ocorreu um erro ao reenviar o webhook '$1'.",
    'resend-success': "O webhook '$1' foi reenviado com sucesso.",
    resending: 'A reenviar...',
    reservation: 'Reserva',
    response: 'Resposta',
    revenue: 'Receita',
    'reverse-confirmation':
      'Tem certeza de que deseja liberar a transação com o id $1?',
    'reverse-create-success': 'Liberação efetuada com sucesso',
    'search-filters': 'Procurar filtros',
    'search-order-ref':
      'Buscar valor da referencia. Pelo menos 3 caracteres necessários para iniciar a busca.',
    'search-transaction': 'Pesquisa de Transações',
    'select-all': 'Selecionar todos',
    'deselect-all': 'Desselecionar todos',
    'select-filter': 'Seleccionar Filtro',
    selected: 'selecionado',
    'selected-s': '$1 $2 selecionado(s)',
    'send-files': 'Enviar ficheiros',
    'send-invite': 'Enviar convite',
    'send-link': 'Enviar link',
    'send-links': 'Enviar links',
    'send-message': 'Enviar mensagem',
    'send-message-and-dispute': 'Enviar mensagem e abrir Disputa',
    'send-recover-email': 'Enviar e-mail',
    sending: 'Enviando...',
    'sent-at': 'Enviado em',
    'settings-title': 'Definições',
    'settlement-date': 'Data de Liquidação',
    'settlement-details': 'Detalhe da Liquidação',
    settlements: 'Liquidações',
    shift: 'Turno $1',
    'shift-details': 'Detalhes do turno',
    'shifts-title': 'Turnos',
    'shipping-address': 'Morada de entrega',
    'shipping-amount': 'Valor do envio',
    shopper: 'Comprador',
    'shopper-country': 'País do comprador',
    'shopper-details': 'Detalhes do comprador',
    'shopper-email': 'Email do comprador',
    'shopper-interaction': 'Interação com o comprador',
    'shopper-ip-address': 'Endereço de IP do comprador',
    shopperEmail: 'Email do comprador',
    shopperInformation: 'Informações do comprador',
    shopperIp: 'Endereço de IP do comprador',
    'show-all': 'Esconder Tudo',
    'show-details': 'Mostrar detalhes',
    'social-payments-title': 'Social Payments',
    spanish: 'Espanhol',
    'start-date': 'Data início',
    'start-time': 'Hora de início',
    'statistics-title': 'Estatísticas',
    status: 'Estado',
    stock: 'Estoque',
    'stock-quantity': 'Quantidade de stock',
    store: 'Loja',
    'store-name': 'Nome da loja',
    storeId: 'Loja',
    success: 'Successo',
    'system-language': 'Idioma do Sistema',
    'system-timezone': 'Timezone do sistema',
    'temporary-failed': 'Falha Temporária',
    'terminal-code': 'Código do Terminal',
    'terminal-serial-number': 'Número de série do terminal',
    terminalCode: 'Código do Terminal',
    terminalPassword: 'Palavra-Passe do Terminal',
    'new-password': 'Nova Palavra-Passe',
    'confirm-new-password': 'Confirme a Palavra-Passe',
    'save-password': 'Salvar Palavra-Passe',
    'changing-password': 'Alterando Palavra-Passe...',
    'confirm-change-password-message':
      'Confirma a alteração da Palavra-Passe do Terminal?',
    'passwords-do-not-match-message': 'As Palavras-Passe não coincidem!',
    'change-terminal-password': 'Alterar a Palavra-Passe do Terminal',
    'password-must-have-six-numbers': 'A Palavra-Passe deve ter seis números!',
    'password-changed-successfully': 'Palavra-passe alterada com sucesso!',
    terminalSerialNumber: 'Número de serie do Terminal',
    test: 'Teste',
    'test-api-key': 'API Key de teste',
    'this-month': 'Este mês',
    'this-week': 'Esta semana',
    threeDSecure: '3DS',
    title: 'Título',
    today: 'Hoje',
    total: 'Total',
    'total-amount': 'Valor total',
    'total-revenue': 'Receita total',
    totals: 'Totais',
    'totals-per': 'Totais por $1',
    'transaction-amount': 'Valor da Transação',
    'transaction-details': 'Detalhes das transações',
    'transaction-type': 'Tipo de Transação',
    transactionId: 'Código de pagamento',
    transactions: 'Transações',
    transactionMbWay: 'Transação MBWAY',
    transactionMultibanco: 'Transação Multibanco',
    transactionMulticaixa: 'Transação Multicaixa',
    transactionPayPal: 'Transação PayPal',
    transactionIdeal: 'Transação IDeal',
    'transactions-count': 'Número de Transações',
    'transactions-title': 'Transações',
    'transactions-volume': 'Volume de Transações',
    'transactions-multiple-currencies-found':
      'Quando várias moedas são selecionadas, o total da receita/montante pode não ser exibido.',
    'transation-processing-title': 'Em processamento',
    transfer: 'Transferência',
    tranStatus: 'Estado 3DS Trans',
    type: 'Tipo',
    unit: 'Loja',
    units: 'Lojas',
    user: 'Usuário',
    'validation-max-chars':
      'O comprimento máximo deste campo é de $1 caracteres',
    'verification-method': 'Método de Verificação',
    version: 'Versão',
    'view-by': 'Visualizar por $1',
    'view-details': 'Visualizar detalhes',
    'view-less': 'Ver menos',
    'view-more': '+$1 mais',
    'view-transaction-details-hint':
      'Clique para visualizar os detalhes da transação.',
    'visible-columns': 'Colunas Visíveis',
    volume: 'Volume',
    webhooks: 'Webhooks',
    week: 'Semana',
    weeks: 'Semanas',
    'with-value': 'com o valor',
    withdrawal: 'Levantamento',
    won: 'Ganha',
    year: 'Ano',
    years: 'Anos',
    yes: 'Sim',
    yesterday: 'Ontem',
  },
  es: {
    '3ds': '3DS',
    '3ds-acs-reference-number': '3DS ACS Reference Number',
    '3ds-authentication-type': '3DS Authentication Type',
    '3ds-challenged': '3DS Challenged',
    '3ds-requested': '3DS Requested',
    '3ds-version': '3DS Version',
    accept: 'Aceptar',
    accepted: 'Aceptado',
    'account-holder': 'Titular de la Cuenta',
    acquirer: 'Adquirente',
    'acquirer-entity': 'Entidad del adquirente',
    'acquirer-reference': 'Referencia del adquirente',
    'acquirer-response': 'Respuesta del adquirente',
    'acquirer-response-code': 'Código de respuesta del adquirente',
    'acquirer-response-message': 'Mensaje de respuesta del adquirente',
    acquirerfailed: 'Falló',
    acquirerReferenceNumber: 'Número de referencia del adquirente (ARN)',
    acquirerId: 'Identificación del adquirente',
    'acquirer-processing': 'Procesamiento del Adquirente',
    acquirerResponseCode: 'Código de respuesta del adquirente',
    acquirerResponseMessage: 'Mensaje de respuesta del adquirente',
    acquirersuccess: 'Éxito',
    actions: 'Acciones/Opciones',
    add: 'Crear',
    'add-filter': 'Añadir filtro',
    'additional-filters': 'Filtros adicionales',
    'administration-title': 'Administración',
    all: 'Todo',
    allAlt: 'Todo',
    amount: 'Cantidad',
    'amount-greater-than-limit':
      'El monto es mayor que el monto de la transacción',
    'analytics-title': 'Analítica',
    'api-key-not-created': 'API Key no ha sido creada',
    'api-keys': 'API Keys',
    channel: 'Canal',
    apply: 'Aplicar',
    'apply-filters': 'Aplicar filtros',
    'applied-filters': 'El filtro se aplicará a tu tablero',
    'aquirer-authorization-code': 'Código de autorización del adquirente',
    aquirerAuthorizationCode: 'Código de autorización del adquirente',
    'attachment-not-found-error': 'No se encontró el archivo',
    attempt: 'Intento',
    attempts: 'Tentativas',
    'authorization-code': 'Código de Autorización',
    authorizationCode: 'Código de Autorización',
    authorizationStatus: 'Estado de Autorización',
    back: 'Regresar',
    'bank-identifier-code': 'Código de identificación bancaria',
    brand: 'Marca',
    cardScheme: 'Card Scheme',
    businessName: 'Nombre comercial',
    cancel: 'Cancelar',
    canceled: 'Cancelado',
    capture: 'Capturar',
    'capture-create-success': 'Captura creada con éxito',
    'capture-transaction': 'Captura de transacciones',
    captured: 'Capturado',
    capturing: 'Capturando...',
    'card-details': 'Detalles de la tarjeta',
    'card-holder': 'Titular de la tarjeta',
    'card-holder-name': 'Titular de la tarjeta',
    'card-number': 'Número de tarjeta',
    cardHolderName: 'Titular de la tarjeta',
    cardIssuerCountry: 'País emisor de la tarjeta',
    cardNumber: 'Número de tarjeta',
    cardType: 'Tipo de tarjeta',
    category: 'Categoría',
    'custom-filters': 'Filtros personalizados',
    'channel-information': 'Información del Canal',
    modes: 'Modos',
    challengeOptions: 'Opciones de Desafío',
    chargeback: 'Contracargo',
    'chargeback-chat': 'Comunicación del proceso de Contracargo',
    'chargeback-create-success': 'Contracargo creado con éxito',
    'chargeback-confirm-attachment-delete': '¿Eliminar archivo?',
    'chargeback-files': 'Ficheros del proceso de Contracargo',
    'chargeback-information': 'Informaciones generales',
    'chargeback-order-creationReason': 'Creación del motivo de Contracargo',
    'chargeback-order-information': 'Información de pedido',
    'chargeback-ref': 'Referencia',
    'chargeback-status-closed': 'Cerrado',
    'chargeback-status-created': 'Creado',
    'chargeback-status-disputing': 'En disputa',
    'chargeback-status-lost': 'Perdido',
    'chargeback-status-pendingaction': 'Acción pendiente',
    'chargeback-status-won': 'Ganado',
    'chargeback-store-information': 'Información de la tienda',
    'chargeback-store-name': 'Nombre de la tienda',
    'chargeback-title': 'Contracargos',
    'chargeback-title-add': 'Iniciar el proceso de Contracargo',
    'chargeback-transaction-information': 'Información sobre la transación',
    chargebackRef: 'Referencia del Contracargo',
    chargebacks: 'Contracargos',
    'choose-file-type': 'Elija el tipo de archivo',
    clear: 'Limpiar',
    'click-to-toggle': 'Haga clic para alternar el menú',
    'click-to-upload': 'Haga clic aquí para cargarlo',
    'client-frequent-flyer-number': 'Número de Viajero Frecuente',
    clientFrequentFlyerNumber: 'Número de Viajero Frecuente',
    clientFrequentFlightNumber: 'Número de Viajero Frecuente',
    closed: 'Terminado',
    code: 'Código',
    'collect-shipping-data': 'Recopilar datos de envío',
    'comission-amount': 'Monto de la Comisión',
    content: 'Contenido',
    'copied-key': 'Key $1 copiada para el Clipboard!',
    'copied-link': '¡Enlace {1} copiado al área de transferencia!',
    'copy-key': 'Copiar Key',
    costs: 'Costes',
    country: 'País',
    'country-code': 'Código postal',
    'create-chargeback-from-transaction': 'Crear Contracargo',
    'create-checkout': 'Crear pago',
    created: 'Creado',
    'created-date': 'Fecha de creación',
    creating: 'Creando...',
    currency: 'Moneda',
    'current-balance': 'Saldo actual',
    'customer-address': 'Dirección del cliente',
    'customer-name': 'Nombre del cliente',
    'dashboard-title': 'Panel de Control',
    date: 'Fecha',
    day: 'Dia',
    days: 'Dias',
    dccAmount: 'Monto de DCC',
    declined: 'Rechazado',
    deductions: 'Deducciones',
    'delete-attachment-forbidden-error': 'No se puede eliminar el archivo',
    denied: 'Denegado',
    deposit: 'Depósito',
    description: 'Descripción',
    details: 'Detalles',
    'developers-description':
      'Por favor, manténgalos en privado en la medida de lo posible.',
    'developers-link': 'Más información sobre cómo integrar la API',
    'developers-subtitle':
      'Utilizar la API Key de prueba para probar su integración, y la Live API Key en vivo para crear pagos reales.',
    'developers-title': 'Desarrolladores',
    disputing: 'En Disputa',
    'drag-drop-file-here': 'Arrastre el archivo aquí',
    domain: 'Dominio',
    'domain-not-available': 'El dominio no está disponible',
    download: 'Descargar',
    downloading: 'Descargando...',
    'dst-description': 'Esta Timezone está actualmente em DST',
    doYouWannaFullChargeback: '¿Quieres hacer un reembolso parcial o completo?',
    doYouWannaFullRefund: '¿Quieres hacer un reembolso parcial o total?',
    dutch: 'Holandés',
    eci: 'ECI',
    ecommerce: 'E-commerce',
    'edit-checkout': 'Editar pago',
    'edit-checkout-success': 'Social Payment editado con éxito',
    'edit-data': 'Editar Datos',
    editing: 'Edición...',
    email: 'E-mail',
    'end-date': 'Fecha de cierre',
    'end-time': 'Hora de finalización',
    english: 'Inglés',
    'entity-code': 'Código de entidad',
    environment: 'Ambiente',
    'environment-hint':
      'Seleccione esta opción para ver sólo las transacciones de producción. Desmarque esta opción para ver sólo las transacciones de prueba',
    error: 'Error',
    'event-type': 'Tipo de Evento',
    'expire-at': 'Caduca a las',
    expired: 'Expirado',
    'expired-token': 'Ha sido redirigido porque su sesión ha expirado',
    'expiry-date': 'Fecha de Expiración',
    export: ' Exportar $1',
    'export-details': 'Exportación',
    'export-details-help':
      'Cuando inicia la exportación de su archivo, nuestro sistema procesará su solicitud. Al finalizar el proceso, enviaremos el archivo exportado directamente al correo asociado a su cuenta ($1).',
    'export-details-alert': 'Atención',
    externalTransactionId: 'ID de Transacción Externa',
    externalCustomId: 'Identificación del adquirente',
    'extra-filters-title': 'Filtros personalizados',
    failed: 'Falló',
    fees: 'Tasas',
    'file-sent': '$1 fue enviado.',
    'file-upload-helper':
      'Para cargar un archivo, haga clic en la etiqueta de la entrada',
    'filter-by': 'Filtrar por $1',
    'filter-by-enter': 'Filtrar por $1 (Presione Enter para Buscar)',
    'fin-product': 'Producto final',
    firstname: 'Nombre',
    flightData: 'Datos del Vuelo',
    'forgot-password': '¿Ha olvidado su contraseña?',
    'fraud-scoring': 'Fraud Scoring',
    french: 'Francés',
    fulfilled: 'Realizado',
    'full-chargeback': 'Reembolso completo',
    'full-refund': 'Reembolso total',
    'full-capture': 'Captura completa',
    'funding-source': 'Funding Source',
    'generate-checkout': 'Generar pago',
    german: 'Alemán',
    'go-to': 'Ir para $1',
    'group': 'Grupo',
    'group-id': 'Identificación del grupo',
    'group-name': 'Nombre del grupo',
    groupId: 'Grupo',
    hasChargeback: 'Contracargos asociados',
    'hide-all': 'Mostrar todo',
    holder: 'poseedor',
    hour: 'Hora',
    hours: 'Horas',
    history: 'Historial',
    'transaction-history': 'Historial de Transacciones',
    iban: 'IBAN',
    id: 'ID',
    'in-transactions': 'en $1 transacciones',
    'initiated-from': 'Iniciado desde',
    initiatedFrom: 'Iniciado desde',
    installmentAmount: 'Monto de la Cuota',
    installments: 'Cuotas',
    international: 'Internacional',
    'international-shipping-amount': 'Importe del envío internacional',
    'international-urgent': 'Internacional Urgente',
    'international-urgent-shipping-amount':
      'Importe del envío urgente internacional',
    'invite-error': 'Hubo un error al enviar la invitación.',
    'invite-merchants': 'Invitar Comerciantes',
    'invite-sent': 'La invitación fue enviada a $1. Onboarding generado: $2',
    invoice: 'Factura',
    invoices: 'Facturas',
    ip: 'IP',
    'is-one-click-payment': 'Is one click',
    isoAmount: 'Monto',
    isOneClickPayment: 'Is one click',
    'item-service-amount': 'Importe del artículo/servicio',
    'item-service-description': 'Descripción del Artículo/Servicio',
    'item-service-image': 'Cargar imagen de artículo/servicio',
    'item-service-name': 'Nombre del produto/servicio',
    'journal-date': 'Fecha del diario',
    language: 'Idioma',
    'last-4-card-numbers': 'últimos 4 dígitos de la tarjeta',
    'last-chargebacks': 'Último $1 de estorno',
    'last-month': 'El mes pasado',
    'last-refunds': 'Último $1 de devoluciones',
    'last-revenue': 'Último $1 de ingresos',
    'last-week': 'La semana pasada',
    lastFourCardNumber: 'Últimos Cuatro Dígitos de la Tarjeta',
    lastname: 'Apellido',
    'latest-payments': 'Últimos pagos (sólo producción)',
    'liability-shift': 'Liability Shift',
    LiabilityShift: 'Liability Shift',
    'link-validity': 'Vigencia del Link',
    'live-api-key': 'Live API Key',
    loading: 'Cargando...',
    'loading-order': 'Cargando Datos del Pedido',
    'loading-ref-transactions': 'Cargando Transacciones Relacionadas',
    'loading-transaction': 'Cargando Transacción',
    'loading-webhook-attempts': 'Cargando intentos de webhook',
    'log-into-paybyrd': 'Login para Paybyrd',
    login: 'Iniciar sesión',
    logout: 'Terminar sesión',
    lost: 'Perdido',
    'management-title': 'Gestión',
    market: 'Mercado',
    maskedCardNumber: 'Masked Card Number',
    merchant: 'Comerciante',
    'merchant-id': 'Identificación del comerciante',
    'merchant-information': 'Información del Comerciante',
    'merchant-name': 'Nombre del comerciante',
    merchantId: 'Comerciante',
    merchantIds: 'Comerciantes',
    'merchant-pendent': 'Comerciante Pendiente',
    'message-label': 'Mensaje',
    mode: 'Modo',
    month: 'Mês',
    'monthly-revenue': 'Ingresos mensuales (sólo producción)',
    months: 'Meses',
    name: 'Nombre',
    national: 'Nacional',
    'national-shipping-amount': 'Importe del envío nacional',
    'national-urgent': 'Nacional Urgente',
    'national-urgent-shipping-amount': 'Importe del envío urgente nacional',
    no: 'No',
    'no-balance':
      'No pudimos encontrar el saldo para los filtros seleccionados,',
    'no-balance-description': 'Intente cambiar el filtro',
    'no-chargebacks':
      'No hemos podido encontrar Contracargos para los filtros seleccionados',
    'no-chargebacks-subtitle': 'Intente cambiar el filtro',
    'no-info': 'Sin información',
    'no-invoices': 'Parece que no hay facturas para el periodo seleccionado',
    'no-invoices-description': 'Intente seleccionar otro año',
    'no-onboardings':
      'No pudimos encontrar procesos de onboarding para los filtros actuales,',
    'no-onboardings-subtitle': 'Pruebe otra combinación de filtros',
    'no-options': 'Sin opciones',
    'no-orders':
      'No hemos podido encontrar transacciones para los filtros seleccionados',
    'no-orders-subtitle':
      'Pruebe a seleccionar otra franja horaria, unidad, estado o canales anteriores',
    'no-payments': 'No hay registro de transacciones recientes',
    'no-payments-subtitle':
      'Pruebe en actualizar la tabla para comprobar si hay nuevas transacciones',
    'no-reconciliation-files': 'No existen archivos de reconciliación',
    'no-ref-transactions':
      'No hemos podido encontrar transacciones para los filtros seleccionados',
    'no-ref-transactions-subtitle':
      'Pruebe en actualizar la tabla para comprobar si hay nuevas transacciones',
    'no-results': 'No hay resultados',
    'no-settlements':
      'No se encontraron liquidaciones en el periodo seleccionado',
    'no-settlements-description': 'Intenta seleccionar otro mes',
    'no-payouts': 'No se encontraron liquidaciones en el periodo seleccionado',
    'no-shifts':
      'No hemos podido encontrar transacciones para los filtros seleccionados',
    'no-shifts-subtitle':
      'Pruebe a seleccionar otra franja horaria, unidad, estado o canales anteriores',
    'no-statistics':
      'No hemos podido encontrar estadísticas para los filtros seleccionados',
    'no-statistics-subtitle':
      'Intenta seleccionar otro periodo o unidades arriba',
    'no-totals': 'No hemos podido encontrar estadísticas para el mes actual,',
    'no-totals-subtitle':
      'Consulte las páginas de estadísticas de otros periodos',
    'no-transactions':
      'No hemos podido encontrar transacciones para los filtros seleccionados',
    'no-transactions-subtitle':
      'Pruebe a seleccionar otra franja horaria, unidad, estado o canales anteriores',
    'no-webhooks':
      'No hemos podido encontrar webhooks para los filtros seleccionados',
    none: 'None',
    'not-visible-columns': 'Columnas ocultas',
    now: 'Ahora',
    onboarding: 'Onboarding',
    'onboarding-details': 'Detalles del Onboarding',
    onboardings: 'Onboardings',
    opened: 'Abierto',
    'order-amount': 'Cantidad solicitada',
    'order-date': 'Fecha de la solicitud',
    'order-ref': 'Referencia de la solicitud',
    'order-reference': 'Referencia de la solicitud',
    order: 'Solicitud',
    orderProcessing: 'Procesando Pedido',
    orderDenied: 'Pedido Denegado',
    orderPaid: 'Pedido Pagado',
    orderPending: 'Pedido Pendiente',
    orderRefunded: 'Pedido Reembolsado',
    orderCanceled: 'Pedido Cancelado',
    orderError: 'Error en el Pedido',
    orderFailed: 'Pedido Fallido',
    orderExpired: 'Pedido Expirado',
    orderDeclined: 'Pedido Rechazado',
    orderCreated: 'Pedido Creado',
    orderClosed: 'Pedido Cerrado',
    orderOpened: 'Pedido Abierto',
    noOrderInfo: 'Sin Información del Pedido',
    'order-details': 'Detalles del Pedido',
    orderPaymentInformation: 'Información de Pago del Pedido',
    orderId: 'Id de la solicitud',
    orderRef: 'Referencia de la solicitud',
    orderref: 'Referencia de la solicitud',
    orders: 'Solicitudes',
    'orders-title': 'Solicitudes',
    paid: 'Pago',
    'parent-payment-id': 'ID de pago principal',
    'partially captured': 'Capturado parcialmente',
    'partially refunded': 'Reembolsado parcialmente',
    'partial-chargeback': 'Reembolso parcial',
    'partial-refund': 'Reembolso parcial',
    'partial-capture': 'Captura parcial',
    'partners-title': 'Socios',
    'passenger-name-record': 'Passenger Name Record',
    password: 'Contraseña',
    'pay-by-link': 'PayByLink',
    'pay-by-link-create': 'Crear un nuevo link de pago',
    'pay-by-link-description': 'Crear, enviar links de pago y recibir en línea',
    'pay-by-link-title': 'Pay by Link',
    'pay-link': 'Link de Pago',
    'pay-links': 'link de pago',
    payment: 'Pago',
    'payment-amount': 'Importe del pago',
    'payment-id': 'ID de pago',
    'payment-information': 'Información de pago',
    'payment-link-error': 'Se ha producido un error al enviar los links',
    'payment-link-success': 'El link de pago se ha enviado con éxito.',
    'payment-method': 'Métodos de pago',
    'payment-purpose': 'Propósito del pago',
    paymentDate: 'Fecha de Pago',
    paymentId: 'ID de Transacción',
    paymentInformation: 'Información de pago',
    paymentMethod: 'Métodos de pago',
    payments: 'Pagos',
    'payments-title': 'Pagos',
    'payouts-title': 'Liquidaciones',
    'payout-title': 'Detalhe $1',
    'payouts-warning-message':
      'Esta función no está disponible temporalmente. Estamos realizando mejoras en nuestro sistema para servirle aún mejor. Le pedimos disculpas por las molestias causadas y nos apresuraremos a restablecer esta funcionalidad. Para cualquier aclaración, puede ponerse en contacto con nosotros a través de los canales habituales.',
    pending: 'Pendiente',
    pendingaction: 'Acción Pendiente',
    pendingmerchantaction: 'Acción comercial pendiente',
    period: 'Período',
    'phone-number': 'Número de Teléfono',
    phoneNumber: 'Número de Teléfono',
    pnr: 'PNR',
    portuguese: 'Portugués',
    pos: 'TPV Físico',
    'pre-auth': 'Pre-Autorizaciones',
    preauth: 'PreAuth',
    'predictions-dispute': 'Quiero abrir una disputa',
    'predictions-question': 'Tengo una pergunta',
    'preferred-payment-methods': 'Métodos de pago preferentes',
    'previous-revenue': ' Ingresos anteriores',
    processing: 'Procesando',
    processingData: 'Procesando Datos',
    prod: 'Producción',
    'product-name': 'Nombre del producto',
    quarter: 'Trimestre',
    quarters: 'Trimestres',
    range: 'Calendario',
    reason: 'Motivo para iniciar un Contracargo',
    'recipient-email': 'Email del destinatario',
    'recipient-name': 'Nombre del Destinatario',
    'recipient-number': 'Contacto del destinatario',
    'reconciliation-date': 'Fecha de reconciliación',
    'reconciliation-file': 'Archivo de reconciliación',
    'reconciliation-files': 'Archivo de reconciliación',
    'recover-paybyrd-password': 'Recuperar Contraseña',
    'recovery-password-success':
      'Le enviamos un correo electrónico para restablecer su contraseña.',
    reference: 'Referencia',
    'reference-ids': 'IDs de referencia',
    refresh: 'Actualizar',
    refund: 'Devoluciones',
    'refund-amount': 'Importe de devolución',
    refundDate: 'Fecha de reembolso',
    'refund-confirm-message':
      '¿Realmente deseas reembolsar esta transacción por un monto de $1?',
    'refund-transaction': 'Transacción de devolución',
    refunded: 'Reembolsado',
    refunding: 'Reembolsando...',
    refunds: 'Devoluciones',
    'refund-create-success': 'Reembolso creado con éxito',
    'register-now': 'Regístrate ahora',
    'related-operation-status': 'Estado de la operación relacionada',
    'related-shift': 'Turnos Relacionados',
    'related-status': 'Estado relacionado',
    'related-transactions': 'Transacciones Relacionadas',
    relatedOperationStatus: 'Estado de la Operación Relacionada',
    release: 'Cancelar la autorización',
    releasing: 'Cancelando la autorización...',
    remark: 'Comentario',
    'request-info': 'Solicitar información',
    'required-field': 'Este campo es obligatorio',
    resend: 'Reenviar',
    'resend-error': "Hubo un error al reenviar el webhook '$1'.",
    'resend-success': "El webhook '$1' se reenvió con éxito.",
    resending: 'Reenviando...',
    reservation: 'Reserva',
    response: 'Respuesta',
    revenue: 'Ingresos',
    'reverse-confirmation':
      '¿Estás seguro de que deseas liberar la transacción con el id $1?',
    'reverse-create-success': 'Reversión creada con éxito',
    'search-filters': 'Buscar filtros',
    'search-order-ref':
      'Valor de referencia de la orden de búsqueda. Se requieren al menos 3 caracteres para iniciar la búsqueda.',
    'search-transaction': 'Buscar transacción',
    'select-all': 'Seleccionar todos',
    'deselect-all': 'Deseleccionar todos',
    'select-filter': 'Seleccionar filtro',
    selected: 'seleccionado',
    'selected-s': '$1 $2 seleccionado(s)',
    'send-files': 'Enviar archivos',
    'send-invite': 'Enviar invitación',
    'send-link': 'Enviar Link',
    'send-links': 'Enviar Links',
    'send-message': 'Enviar mensaje',
    'send-message-and-dispute': 'Enviar mensaje y abrir Disputa',
    'send-recover-email': 'Enviar email',
    sending: 'Enviando...',
    'sent-at': 'Enviado el',
    'settings-title': 'Definiciones',
    'settlement-date': 'Fecha de Liquidación',
    'settlement-details': 'Detalles del Acuerdo',
    settlements: 'Liquidaciones',
    shift: 'Turno $1',
    'shift-details': 'Detalles del turno',
    'shifts-title': 'Turnos',
    'shipping-address': 'Dirección del Envío',
    'shipping-amount': 'Importe del envío nacional',
    shopper: 'Comprador',
    'shopper-country': 'País del comprador',
    'shopper-details': 'Detalles del comprador',
    'shopper-email': 'Correo electrónico del comprador',
    'shopper-interaction': 'Interacción con el comprador',
    'shopper-ip-address': 'Dirección IP del comprador',
    shopperEmail: 'Correo electrónico del comprador',
    shopperInformation: 'Información del comprador',
    shopperIp: 'Dirección IP del comprador',
    'show-all': 'Ocultar todo',
    'show-details': 'Mostrar detalles',
    'social-payments-title': 'Social Payments',
    spanish: 'Español',
    'start-date': 'Fecha de inicio',
    'start-time': 'Hora de inicio',
    'statistics-title': 'Estadísticas',
    status: 'Estado',
    stock: 'Cantidad',
    'stock-quantity': 'Cantidad de stock',
    store: 'Tienda',
    'store-name': 'Nombre de la tienda',
    storeId: 'tienda',
    success: 'Éxito',
    'system-language': 'Idioma del sistema',
    'system-timezone': 'Timezone del sistema',
    'temporary-failed': 'Fallo Temporal',
    'terminal-code': 'Código de Terminal',
    'terminal-serial-number': 'Número de serie de la Terminal',
    terminalCode: 'Código de Terminal',
    terminalPassword: 'Contraseña del Terminal',
    'new-password': 'Nueva contraseña',
    'confirm-new-password': 'Confirmar nueva contraseña',
    'save-password': 'Guardar Contraseña',
    'changing-password': 'Cambiar Contraseña...',
    'confirm-change-password-message':
      '¿Confirmar cambio de contraseña del Terminal?',
    'passwords-do-not-match-message': '¡Las contraseñas no coinciden!',
    'change-terminal-password': 'Cambiar la contraseña del Terminal',
    'password-must-have-six-numbers': '¡La contraseña debe tener seis números!',
    'password-changed-successfully': '¡Contraseña cambiada con éxito!',
    terminalSerialNumber: 'Número de Serie del Terminal',
    test: 'Test',
    'test-api-key': 'API Keys de prueba',
    'this-month': 'Este mes',
    'this-week': 'Esta semana',
    threeDSecure: '3DS',
    title: 'Título',
    today: 'Hoy dia',
    total: 'Total',
    'total-amount': 'Importe total',
    'total-revenue': 'Ingresos Totales',
    totals: 'Totales',
    'totals-per': 'Totales por $1',
    'transaction-amount': 'Cantidad de Transacción',
    'transaction-details': 'Detalles de la transacción',
    'transaction-type': 'Tipo de Transacción',
    transactionId: 'ID de Transacción',
    transactions: 'transacciones',
    transactionMbWay: 'Transacción MBWAY',
    transactionMultibanco: 'Transacción Multibanco',
    transactionMulticaixa: 'Transacción Multicaixa',
    transactionPayPal: 'Transacción PayPal',
    transactionIdeal: 'Transactión IDeal',
    'transactions-count': 'Recuento de transacciones',
    'transactions-title': 'Transacciones',
    'transactions-volume': 'Volumen de transacciones',
    'transactions-multiple-currencies-found':
      'Cuando se seleccionan varias monedas, el total de ingresos/monto puede no mostrarse.',
    'transation-processing-title': 'En proceso',
    transfer: 'En transferencia',
    tranStatus: '3DS Trans Status',
    type: 'Tipo',
    unit: 'Tienda',
    units: 'Tiendas',
    user: 'Usuario',
    'validation-max-chars':
      'La longitud máxima de este campo es de $1 carácteres',
    'verification-method': 'Método de verificación',
    version: 'Versión',
    'view-by': 'Consulte por $1',
    'view-details': 'Ver detalles',
    'view-less': 'Ver menos',
    'view-more': '+$1 más',
    'view-transaction-details-hint':
      'Pulse para ver los detalles de la transacción',
    'visible-columns': 'Columnas visibles',
    volume: 'Volumen',
    webhooks: 'Webhooks',
    week: 'Semana',
    weeks: 'Semanas',
    'with-value': 'con valor',
    withdrawal: 'Retirada',
    won: 'Ganado',
    year: 'Año',
    years: 'Años',
    yes: 'Sí',
    yesterday: 'Ayer',
  },
  fr: {
    '3ds': '3DS',
    '3ds-acs-reference-number': '3DS ACS Reference Number',
    '3ds-authentication-type': '3DS Authentication Type',
    '3ds-challenged': '3DS Challenged',
    '3ds-requested': '3DS Requested',
    '3ds-version': '3DS Version',
    accept: 'Accept',
    accepted: 'Accepted',
    'account-holder': 'Titulaire du Compte',
    acquirer: 'Acquirer',
    'acquirer-entity': "Entité de l'Acquéreur",
    'acquirer-processing': "Traitement par l'Acquéreur",
    'acquirer-reference': "Référence de l'Acquéreur",
    'acquirer-response': "Réponse de l'Acquéreur",
    'acquirer-response-code': "Code de Réponse de l'Acquéreur",
    'acquirer-response-message': "Message de Réponse de l'Acquéreur",
    acquirerfailed: 'Échoué',
    acquirerReferenceNumber: "Numéro de Référence de l'Acquéreur (ARN)",
    acquirerId: "Identifiant de l'acquéreur",
    acquirerResponseCode: "Code de Réponse de l'Acquéreur",
    acquirerResponseMessage: "Message de Réponse de l'Acquéreur",
    acquirersuccess: 'Succès',
    actions: 'Actions/Options',
    add: 'Create',
    'add-filter': 'Ajouter un Filtre',
    'additional-filters': 'Filtres Supplémentaires',
    'administration-title': 'Administration',
    all: 'Tous',
    allAlt: 'Tous',
    amount: 'Montant',
    'amount-greater-than-limit':
      'Le montant est supérieur au montant de la transaction',
    'analytics-title': 'Analytique',
    'api-key-not-created': "API Key n'a pas été créée",
    'api-keys': 'API Keys',
    channel: 'Canal',
    apply: 'Appliquer',
    'apply-filters': 'Appliquer les Filtres',
    'applied-filters': 'Le filtre sera appliqué à votre tableau de bord',
    'aquirer-authorization-code': "Code d'Autorisation de l'Acquéreur",
    aquirerAuthorizationCode: "Code d'Autorisation de l'Acquéreur",
    'attachment-not-found-error': 'Pièce jointe non trouvée',
    attempt: 'Tentative',
    attempts: 'Tentatives',
    'authorization-code': "Code d'autorisation",
    authorizationCode: 'Authorization Code',
    authorizationStatus: "Statut d'Autorisation",
    back: 'Retour à la page',
    'bank-identifier-code': 'Identifiant de la Banque',
    brand: 'Marque',
    cardScheme: 'Card Scheme',
    businessName: 'Business Name',
    cancel: 'Annuler',
    canceled: 'Annulé',
    capture: 'Capture',
    'capture-create-success': 'Capture créée avec succès',
    'capture-transaction': 'Capture des transactions',
    captured: 'Captured',
    capturing: 'Capturer...',
    'card-details': 'Détails de la Carte',
    'card-holder': 'Titulaire de la Carte',
    'card-holder-name': 'Nom du Titulaire de la Carte',
    'card-number': 'Numéro de Carte',
    cardHolderName: 'Nom du Titulaire de la Carte',
    cardIssuerCountry: 'Pays émetteur de la carte',
    cardNumber: 'Numéro de Carte',
    cardType: 'Type de carte',
    category: 'Category',
    'custom-filters': 'Filtres personnalisés',
    'channel-information': 'Informations sur la Chaîne',
    modes: 'Modes',
    challengeOptions: 'Options de Défi',
    chargeback: 'Chargeback',
    'chargeback-chat': 'Chargeback process comunitaction',
    'chargeback-create-success': 'Chargeback successfully created',
    'chargeback-confirm-attachment-delete': 'Supprimer la pièce jointe?',
    'chargeback-files': 'Chargeback process files',
    'chargeback-information': 'General Information',
    'chargeback-order-creationReason': 'Chargeback creation Reason',
    'chargeback-order-information': 'Order Information',
    'chargeback-ref': 'Référence de Rétrofacturation',
    'chargeback-status-closed': 'Closed',
    'chargeback-status-created': 'Created',
    'chargeback-status-disputing': 'Disputing',
    'chargeback-status-lost': 'Lost',
    'chargeback-status-pendingaction': 'Pending Action',
    'chargeback-status-won': 'Won',
    'chargeback-store-information': 'Store Information',
    'chargeback-store-name': 'Nom du magasin',
    'chargeback-title': 'Rétrofacturation',
    'chargeback-title-add': 'Iniciar processo de Disputa',
    'chargeback-transaction-information': 'Transation Information',
    chargebackRef: 'Chargebacks-Référence',
    chargebacks: 'Chargebacks',
    'choose-file-type': 'Choisir le type de fichier',
    clear: 'Effacer',
    'click-to-toggle': 'Cliquez pour basculer le menu',
    'click-to-upload': 'Cliquez ici pour télécharger',
    'client-frequent-flyer-number': 'Numéro de Voyageur Fréquent',
    clientFrequentFlyerNumber: 'Numéro de Voyageur Fréquent',
    clientFrequentFlightNumber: 'Numéro de Voyageur Fréquent',
    closed: 'Complété',
    code: 'Code',
    'collect-shipping-data': "Recueillir des données d'expédition",
    'comission-amount': 'Montant de la commission',
    content: 'Contenu',
    'copied-key': 'Key copiée dans le ClipBoard!',
    'copied-link': 'Lien {1} copié dans la zone de transfert !',
    'copy-key': 'Copier Key',
    costs: 'Coûts',
    country: 'Pays',
    'country-code': 'Code postal',
    'create-chargeback-from-transaction': 'Create Chargeback',
    'create-checkout': 'Créer un paiement',
    created: 'Créé',
    'created-date': 'Date de création',
    creating: 'Créer...',
    currency: 'Devise',
    'current-balance': 'Solde actuel',
    'customer-address': 'Adresse du client',
    'customer-name': 'Nom du client',
    'dashboard-title': 'Dashboard',
    date: 'Date',
    day: 'Jour',
    days: 'Jours',
    dccAmount: 'DCC Amount',
    declined: 'Rejeté',
    deductions: 'Déductions',
    'delete-attachment-forbidden-error':
      'Vous ne pouvez pas supprimer cette pièce jointe',
    denied: 'Refusé',
    deposit: 'Deposit',
    description: 'Description',
    details: 'Détails',
    'developers-description': "En savoir plus sur la façon d'intégrer l'API",
    'developers-link': 'Read more about integrating our API',
    'developers-subtitle':
      'Utilisez la test clé API de pour tester votre intégration, et la live clé API en direct pour effectuer de vrais paiements.',
    'developers-title': 'Développeurs',
    disputing: 'Disputing',
    'drag-drop-file-here': 'Drop your file here',
    domain: 'Domaine',
    'domain-not-available': "Le domaine n'est pas disponible",
    download: 'Télécharger',
    downloading: 'Téléchargement...',
    'dst-description': 'Ce fuseau horaire est actuellement en DST',
    doYouWannaFullChargeback:
      'Voulez-vous effectuer une rétrofacturation partielle ou complète ?',
    doYouWannaFullRefund:
      'Voulez-vous effectuer un remboursement partiel ou total ?',
    dutch: 'Hollandais',
    eci: 'ECI',
    ecommerce: 'Ecommerce',
    'edit-checkout': 'Modifier le paiement',
    'edit-checkout-success': 'Social Payment erfolgreich bearbeitet',
    'edit-data': 'Modifier les données',
    editing: 'Modification...',
    email: 'Email',
    'end-date': 'Date de fermeture',
    'end-time': 'Heure de fin',
    english: 'Anglais',
    'entity-code': "Code de l'Entité",
    environment: 'Environnement',
    'environment-hint':
      'Check to see Production transactions only. Uncheck to see Test transactions only',
    error: 'Erreur',
    'event-type': "Type d'événement",
    'expire-at': 'Ablaufen am',
    expired: 'Expiré',
    'expired-token': 'Vous avez été redirigé car votre session a expiré.',
    'expiry-date': 'Expiry Date',
    export: 'Exporter $1',
    'export-details': 'Exporter',
    'export-details-help':
      "Lorsque vous commencez à exporter votre fichier, notre système traite votre demande. Une fois le traitement terminé, nous enverrons le fichier exporté directement à l'adresse électronique associée à votre compte ($1).",
    'export-details-alert': 'Attention',
    externalTransactionId: 'ID de Transaction Externe',
    externalCustomId: `Identifiant de l'acquéreur`,
    'extra-filters-title': 'Filtrage Personnalisé',
    failed: 'Échoué',
    fees: 'Taux',
    'file-sent': '$1 was sent.',
    'file-upload-helper':
      "Pour télécharger un fichier, cliquez sur le libellé de l'entrée",
    'filter-by': 'Filtrer par $1',
    'filter-by-enter': 'Filtrer par $1 (Appuyez sur Entrée pour Rechercher)',
    'fin-product': 'produit fini',
    firstname: 'Prénom',
    flightData: 'Données de Vol',
    'forgot-password': 'Vous avez oublié votre mot de passe?',
    'fraud-scoring': 'Fraud Scoring',
    french: 'Français',
    fulfilled: 'Complété',
    'full-chargeback': 'Rétrofacturation complète',
    'full-refund': 'Remboursement complet',
    'full-capture': 'Capture complète',
    'funding-source': 'Funding Source',
    'generate-checkout': 'Générer le paiement',
    german: 'Allemand',
    'go-to': 'Accéder à $1',
    'group': 'Groupe',
    'group-id': 'ID de groupe',
    'group-name': 'Nom de groupe',
    groupId: 'Groupe',
    hasChargeback: 'Chargeback Associée',
    'hide-all': 'Tout Afficher',
    holder: 'titulaire',
    hour: 'Heure',
    hours: 'Heures',
    history: 'Historique',
    'transaction-history': 'Historique des Transactions',
    iban: 'IBAN',
    id: 'ID',
    'in-transactions': 'en $1 transactions',
    'initiated-from': 'Initié à partir de',
    initiatedFrom: 'Initiated From',
    installmentAmount: 'Installment Amount',
    installments: 'Installments',
    international: 'International',
    'international-shipping-amount': "Montant de l'expédition internationale",
    'international-urgent': 'Urgent Internationale',
    'international-urgent-shipping-amount':
      "Montant de l'expédition urgent internationale",
    'invite-error': "Il y a eu une erreur dans l'envoi de l'invitation.",
    'invite-merchants': 'Inviter les marchands',
    'invite-sent':
      "L'invitation a été envoyée à $1. Onboarding a été généré à: $2",
    invoice: 'Facture',
    invoices: 'Factures',
    ip: 'IP',
    'is-one-click-payment': 'Is one click',
    isoAmount: 'Amount',
    isOneClickPayment: 'Is one click',
    'item-service-amount': "Montant de l'article/du service",
    'item-service-description': "Description de l'article/du service",
    'item-service-image': "Télécharger l'image de l'article/du service",
    'item-service-name': "Nom de l'article/du service",
    'journal-date': 'Date du journal',
    language: 'Langue',
    'last-4-card-numbers': 'les 4 derniers chiffres de la carte',
    'last-chargebacks': 'Dernier $1 de chargeback',
    'last-month': 'Le mois dernier',
    'last-refunds': 'Dernier $1 de remboursements',
    'last-revenue': 'Dernier $1 de revenu',
    'last-week': 'La semaine dernière',
    lastFourCardNumber: 'les Quatre derniers chiffres de la carte',
    lastname: 'Nom de Famille',
    'latest-payments': 'Derniers paiements (production uniquement)',
    'liability-shift': 'Liability Shift',
    LiabilityShift: 'Liability Shift',
    'link-validity': 'Validité du lien',
    'live-api-key': 'Live API Key',
    loading: 'Loading...',
    'loading-order': "Chargement des données de l'ordre",
    'loading-ref-transactions': 'Loadinf related Transactions',
    'loading-transaction': 'Loading Transaction',
    'loading-webhook-attempts': 'Chargement des tentatives de webhook',
    'log-into-paybyrd': 'Connecter à Paybyrd',
    login: 'Login',
    logout: 'Déconnexion',
    lost: 'Lost',
    'management-title': 'Gestion',
    market: 'Marché',
    maskedCardNumber: 'Masked Card Number',
    merchant: 'Marchand',
    'merchant-id': 'ID marchand',
    'merchant-information': 'Informations sur le Marchand',
    'merchant-name': 'Nom du commerçant',
    merchantId: 'Marchand',
    merchantIds: 'Marchands',
    'merchant-pendent': 'Commerçant en Attente',
    'message-label': 'Message',
    mode: 'Mode',
    month: 'Mois',
    'monthly-revenue': 'Revenu mensuel (production uniquement)',
    months: 'Mois',
    name: 'Nom',
    national: 'National',
    'national-shipping-amount': "Montant d'expédition national",
    'national-urgent': 'Urgent Nationale',
    'national-urgent-shipping-amount': "Montant d'expédition urgent national",
    no: 'Non',
    'no-balance':
      "Nous n'avons pas pu trouver l'équilibre pour les filtres sélectionnés",
    'no-balance-description':
      'Essayez de sélectionner un autre mois, ou les unités précédentes.',
    'no-chargebacks':
      "Nous n'avons pas trouvé de Rétrofacturation pour les filtres sélectionnés",
    'no-chargebacks-subtitle':
      'Essayez de sélectionner un autre mois, ou les unités précédentes.',
    'no-info': 'Aucune information',
    'no-invoices':
      'Il ne semble pas y avoir de factures pour la période sélectionnée',
    'no-invoices-description': 'Essayez de choisir une autre année',
    'no-onboardings':
      "Nous n'avons pas pu trouver de procédures d'onboarding pour les filtres actuels,",
    'no-onboardings-subtitle': 'Essayez une autre combinaison de filtres',
    'no-options': 'No Options',
    'no-orders':
      "Nous n'avons pas pu trouver de transactions pour les filtres sélectionnés",
    'no-orders-subtitle':
      'Essayez de sélectionner un autre tranche horaire, une autre unité, un autre état ou des chaînes précédentes.',
    'no-payments': 'Aucun enregistrement des transactions récentes',
    'no-payments-subtitle':
      "Actualisez le tableau pour vérifier s'il y a de nouvelles transactions.",
    'no-reconciliation-files': "Aucun fichier de réconciliation n'existe.",
    'no-ref-transactions':
      "Nous n'avons pas pu trouver de transactions pour les filtres sélectionnés",
    'no-ref-transactions-subtitle':
      'Essayez de rafraîchir la table pour vérifier les nouvelles transactions.',
    'no-results': 'Aucun résultat trouvé',
    'no-settlements':
      "Aucune liquidation n'a été trouvé dans la période sélectionnée",
    'no-settlements-description': 'Essayez de sélectionner un autre mois',
    'no-payouts': "Aucune liquidation n'a été trouvé dans la période sélectionnée",
    'no-shifts':
      "Nous n'avons pas pu trouver de transactions pour les filtres sélectionnés",
    'no-shifts-subtitle':
      'Essayez de sélectionner une autre tranche horaire, une autre unité, un autre état ou des canaux précédents.',
    'no-statistics':
      "Nous n'avons pas pu trouver de statistiques pour les filtres sélectionnés,",
    'no-statistics-subtitle':
      "Essayez de sélectionner une autre période ou d'autres unités ci-dessus",
    'no-totals':
      "Nous n'avons pas pu trouver de statistiques pour le mois en cours",
    'no-totals-subtitle':
      'Consulter les pages de statistiques pour les autres périodes',
    'no-transactions':
      "Nous n'avons pas pu trouver de transactions pour les filtres sélectionnés",
    'no-transactions-subtitle':
      'Essayez de sélectionner une autre tranche horaire, une autre unité, un autre état ou des canaux précédents.',
    'no-webhooks':
      "Nous n'avons pas pu trouver de webhooks pour les filtres sélectionnés",
    none: 'None',
    'not-visible-columns': 'Colonnes Masquées',
    now: 'Maintenant',
    onboarding: 'Onboarding',
    'onboarding-details': 'Détails du Onboarding',
    onboardings: 'Onboardings',
    opened: 'Ouvert',
    'order-amount': 'Quantité demandée',
    'order-date': 'Order Date',
    'order-ref': 'Demande Ref',
    'order-reference': 'Référence de la demande',
    order: 'Commande',
    orderProcessing: 'Traitement de la Commande',
    orderDenied: 'Commande Refusée',
    orderPaid: 'Commande Payée',
    orderPending: 'Commande en Attente',
    orderRefunded: 'Commande Remboursée',
    orderCanceled: 'Commande Annulée',
    orderError: 'Erreur de Commande',
    orderFailed: 'Commande Échouée',
    orderExpired: 'Commande Expirée',
    orderDeclined: 'Commande Déclinée',
    orderCreated: 'Commande Créée',
    orderClosed: 'Commande Fermée',
    orderOpened: 'Commande Ouverte',
    noOrderInfo: 'Aucune Information sur la Commande',
    'order-details': 'Détails de la Commande',
    orderPaymentInformation: 'Informations de Paiement de la Commande',
    orderId: 'Id de commande',
    orderRef: 'Réf commande',
    orderref: 'Réf commande',
    orders: 'Ordres',
    'orders-title': 'Demandes',
    paid: 'Paiement',
    'parent-payment-id': 'ID de paiement parent',
    'partially captured': 'Partially captured',
    'partially refunded': 'Partially refunded',
    'partial-chargeback': 'Rétrofacturation partielle',
    'partial-refund': 'Remboursement partiel',
    'partial-capture': 'Capture partielle',
    'partners-title': 'Partenaires',
    'passenger-name-record': 'Passenger Name Record',
    password: 'Mot de passe',
    'pay-by-link': 'PayByLink',
    'pay-by-link-create': 'Créer un nouveau lien de paiement',
    'pay-by-link-description':
      'Créer, envoyer et recevoir des liens de paiement en ligne',
    'pay-by-link-title': 'Pay by Link',
    'pay-link': 'Lien de paiement',
    'pay-links': 'Payer par lien',
    payment: 'Paiement',
    'payment-amount': 'Montant du paiement',
    'payment-id': 'ID de paiement',
    'payment-information': 'Payment Information',
    'payment-link-error':
      "Une erreur s'est produite lors de l'envoi des liens.",
    'payment-link-success': 'Le lien de paiement a été envoyé avec succès.',
    'payment-method': 'Méthodes de paiement',
    'payment-purpose': 'Objet du Paiement',
    paymentDate: 'Payment Date',
    paymentId: 'Transaction Id',
    paymentInformation: 'Payment Information',
    paymentMethod: 'Payment Method',
    payments: 'Paiements',
    'payments-title': 'Paiements',
    'payouts-title': 'Paiements',
    'payout-title': 'Détail $1',
    'payouts-warning-message':
      'Cette fonction est temporairement indisponible. Nous apportons des améliorations à notre système afin de mieux vous servir. Nous nous excusons pour la gêne occasionnée et nous nous empresserons de rétablir cette fonctionnalité. Pour toute clarification supplémentaire, vous pouvez nous contacter par les canaux habituels.',
    pending: 'En attente',
    pendingaction: 'Pending Action',
    pendingmerchantaction: 'Action du marchand en attente',
    period: 'Période',
    'phone-number': 'Numéro de Téléphone',
    phoneNumber: 'Numéro de Téléphone',
    pnr: 'PNR',
    portuguese: 'Portugais',
    pos: 'Point de vente physique',
    'pre-auth': 'Pré-autorisations',
    preauth: 'PreAuth',
    'predictions-dispute': 'I want to dispute',
    'predictions-question': 'I have a question',
    'preferred-payment-methods': 'Méthodes de paiement préférées',
    'previous-revenue': 'Revenus antérieurs',
    processing: 'En traitement',
    processingData: 'Processing Data',
    prod: 'Production',
    'product-name': 'Nom du produit',
    quarter: 'Trimestre',
    quarters: 'Trimestres',
    range: 'Varier',
    reason: 'Reason for initiating Chargeback',
    'recipient-email': 'Courriel du destinataire',
    'recipient-name': 'Nom du bénéficiaire',
    'recipient-number': 'Contact du destinataire',
    'reconciliation-date': 'Date de réconciliation',
    'reconciliation-file': 'Fichier de réconciliation',
    'reconciliation-files': 'Fichiers de réconciliation',
    'recover-paybyrd-password': 'Récupérer le mot de passe',
    'recovery-password-success':
      'Nous vous avons envoyé un e-mail pour réinitialiser votre mot de passe.',
    reference: 'Référence',
    'reference-ids': 'Identifiants de référence',
    refresh: 'Actualiser',
    refund: 'Remboursement',
    'refund-amount': 'Montant du remboursement',
    refundDate: 'Date du remboursement',
    'refund-confirm-message':
      'Do you really want to refund this transaction in the amount of $1?',
    'refund-transaction': 'Remboursement de la transaction',
    refunded: 'Remboursé',
    refunding: 'Rembourser...',
    refunds: 'Remboursements',
    'refund-create-success': 'Remboursement créé avec succès',
    'register-now': 'Inscrivez-vous maintenant',
    'related-operation-status': "Statut d'opération connexe",
    'related-shift': 'Postes associés',
    'related-status': 'Statut associé',
    'related-transactions': 'Transactions Associées',
    relatedOperationStatus: 'Related Operation Status',
    release: "Annuler l'autorisation",
    releasing: "Annulation de l'autorisation...",
    remark: 'Remark',
    'request-info': 'Request Info',
    'required-field': 'Ce champ est requis',
    resend: 'Renvoyer',
    'resend-error':
      "Une erreur s'est produite lors de la tentative de renvoi du webhook '$1'.",
    'resend-success': "Le webhook '$1' a été renvoyé avec succès.",
    resending: 'Renvoi en cours...',
    reservation: 'Réservation',
    response: 'Réponse',
    revenue: 'Revenu',
    'reverse-confirmation':
      "Êtes-vous sûr de vouloir libérer la transaction avec l'id $1?",
    'reverse-create-success': 'Annulation créée avec succès',
    'search-filters': 'Rechercher des filtres',
    'search-order-ref':
      'Search order reference value. At least 3 characters required to start searching',
    'search-transaction': 'Search Transaction',
    'select-all': 'Sélectionner tout',
    'deselect-all': 'Désélectionner tous',
    'select-filter': 'Sélectionner le Filtre',
    selected: 'sélectionné',
    'selected-s': '$1 $2 sélectionné(s)',
    'send-files': 'Send Files',
    'send-invite': 'Envoyer une invitation',
    'send-link': 'Envoyer le lien',
    'send-links': 'Envoyer des liens',
    'send-message': 'Send Message',
    'send-message-and-dispute': 'Send message and open Dispute',
    'send-recover-email': 'Envoyer un e-mail',
    sending: 'Envoyer...',
    'sent-at': 'Envoyé à',
    'settings-title': 'Définitions',
    'settlement-date': 'Date du règlement',
    'settlement-details': 'Détails du règlement',
    settlements: 'Liquidations',
    shift: 'Poste $1',
    'shift-details': 'Détails de horaires',
    'shifts-title': 'Horaires',
    'shipping-address': 'Adresse de livraison',
    'shipping-amount': "Montant d'expédition",
    shopper: 'Acheteur',
    'shopper-country': "Pays de l'acheteur",
    'shopper-details': "Détails de l'acheteur",
    'shopper-email': "Adresse e-mail de l'acheteur",
    'shopper-interaction': 'Shopper Interaction',
    'shopper-ip-address': "Adresse IP de l'acheteur",
    shopperEmail: "Adresse e-mail de l'acheteur",
    shopperInformation: "Informations sur l'acheteur",
    shopperIp: "Adresse IP de l'acheteur",
    'show-all': 'Masquer Tout',
    'show-details': 'Afficher les détails',
    'social-payments-title': 'Social Payments',
    spanish: 'Espagnol',
    'start-date': 'Date de départ',
    'start-time': 'Heure de début',
    'statistics-title': 'Statistiques',
    status: 'Status',
    stock: 'Quantité',
    'stock-quantity': 'Bestandsmenge',
    store: 'Magasin',
    'store-name': 'Nom du magasin',
    storeId: 'Magasin',
    success: 'Succès',
    'system-language': 'Langue du système',
    'system-timezone': 'Fuseau horaire du système',
    'temporary-failed': 'Échec Temporaire',
    'terminal-code': 'Terminal Code',
    'terminal-serial-number': 'Numéro de série du terminal',
    terminalCode: 'Terminal Code',
    terminalPassword: 'Mot de passe du Terminal',
    'nouveau-mot-de-passe': 'Nouveau mot de passe',
    'confirm-new-password': 'Confirmer le nouveau mot de passe',
    'save-password': 'Enregistrer le mot de passe',
    'changing-password': 'Changer le mot de passe...',
    'confirm-change-password-message':
      'Confirmation du changement de mot de passe du Terminal ?',
    'passwords-do-not-match-message':
      'Les mots de passe ne correspondent pas !',
    'change-terminal-password': 'Modifier le mot de passe du Terminal',
    'password-must-have-six-numbers':
      'Le mot de passe doit comporter six chiffres !',
    'password-changed-successfully':
      'Le mot de passe a été modifié avec succès !',
    terminalSerialNumber: 'Terminal Serial Number',
    test: 'Test',
    'test-api-key': 'Test des API Key',
    'this-month': 'Ce mois-ci',
    'this-week': 'Cette semaine',
    threeDSecure: '3DS',
    title: 'Titel',
    today: 'Aujourd hui',
    total: 'Total',
    'total-amount': 'Montant total',
    'total-revenue': 'Total des revenus',
    totals: 'Totaux',
    'totals-per': 'Totaux pour $1',
    'transaction-amount': 'Montant de la transaction',
    'transaction-details': 'Détails de la transaction',
    'transaction-type': 'Type de transaction',
    transactionId: 'Transaction Id',
    transactions: 'transactions',
    transactionMbWay: 'Transaction MBWAY',
    transactionMultibanco: 'Transaction Multibanco',
    transactionMulticaixa: 'Transaction Multicaixa',
    transactionPayPal: 'Transaction PayPal',
    transactionIdeal: 'Transaction IDeal',
    'transactions-count': 'Nombre de transactions',
    'transactions-title': 'Transactions',
    'transactions-volume': 'Volume des transactions',
    'transactions-multiple-currencies-found':
      'Lorsque plusieurs devises sont sélectionnées, le montant total/les revenus peuvent ne pas être affichés.',
    'transation-processing-title': 'Processing',
    transfer: 'Transfer',
    tranStatus: '3DS Trans Status',
    type: 'Type',
    unit: 'Magasin',
    units: 'Magasins',
    user: 'Utilisateur',
    'validation-max-chars':
      'La longueur maximale de ce champ est de $1 caractères',
    'verification-method': 'Verification Method',
    version: 'Version',
    'view-by': 'Consulter pour $1',
    'view-details': 'Voir les détails',
    'view-less': 'Voir moins',
    'view-more': '+$1 de plus',
    'view-transaction-details-hint':
      'Cliquez pour voir les détails de la transaction',
    'visible-columns': 'Colonnes Visibles',
    volume: 'Volume',
    webhooks: 'Webhooks',
    week: 'Semaine',
    weeks: 'Semaines',
    'with-value': 'with value',
    withdrawal: 'Withdrawal',
    won: 'Won',
    year: 'Année',
    years: 'Années',
    yes: 'Oui',
    yesterday: 'Hier',
  },
  de: {
    '3ds': '3DS',
    '3ds-acs-reference-number': '3DS ACS Reference Number',
    '3ds-authentication-type': '3DS Authentication Type',
    '3ds-challenged': '3DS Challenged',
    '3ds-requested': '3DS Requested',
    '3ds-version': '3DS Version',
    accept: 'Accept',
    accepted: 'Accepted',
    'account-holder': 'Kontoinhaber',
    acquirer: 'Akquirierer',
    'acquirer-entity': 'Akquirierer-Entität',
    'acquirer-processing': 'Erwerberverarbeitung',
    'acquirer-reference': 'Akquirierer-Referenz',
    'acquirer-response': 'Antwort des Akquirierers',
    'acquirer-response-code': 'Akquirierer-Antwortcode',
    'acquirer-response-message': 'Akquirierer-Antwortnachricht',
    acquirerfailed: 'Gescheitert',
    acquirerReferenceNumber: 'Akquirierer-Referenznummer (ARN)',
    acquirerId: 'Akquirierer-Id',
    acquirerResponseCode: 'Akquirierer-Antwortcode',
    acquirerResponseMessage: 'Akquirierer-Antwortnachricht',
    acquirersuccess: 'Erfolg',
    actions: 'Aktionen',
    add: 'Create',
    'add-filter': 'Filter hinzufügen',
    'additional-filters': 'Zusätzliche Filter',
    'administration-title': 'Verwaltung',
    all: 'Alle',
    allAlt: 'Alle',
    amount: 'Betrag',
    'amount-greater-than-limit':
      'Der Betrag ist größer als der Transaktionsbetrag',
    'analytics-title': 'Analytiks',
    'api-key-not-created': 'API-Schlüssel nicht erstellt',
    'api-keys': 'API-Schlüssel',
    channel: 'Kanal',
    apply: 'Anwenden',
    'apply-filters': 'Filter anwenden',
    'applied-filters': 'Der Filter wird auf Ihr Dashboard angewendet',
    'aquirer-authorization-code': 'Autorisierungscode des Akquirierers',
    aquirerAuthorizationCode: 'Autorisierungscode des Akquirierers',
    'attachment-not-found-error': 'Anhang nicht gefunden',
    attempt: 'Versuch',
    attempts: 'Versuche',
    'authorization-code': 'Autorisierungscode',
    authorizationCode: 'Autorisierungscode',
    authorizationStatus: 'Autorisierungsstatus',
    back: 'Zurück',
    'bank-identifier-code': 'Bankkennzeichen',
    brand: 'Marke',
    cardScheme: 'Card Scheme',
    businessName: 'Business Name',
    cancel: 'Abbrechen',
    canceled: 'Abgesagt',
    capture: 'Buchen',
    'capture-create-success': 'Erfassung erfolgreich erstellt',
    'capture-transaction': 'Transaktion buchen',
    captured: 'Captured',
    capturing: 'Erfassen der Buchung...',
    'card-details': 'Kartendetails',
    'card-holder': 'Karteninhaber',
    'card-holder-name': 'Name des Karteninhabers',
    'card-number': 'Kartennummer',
    cardHolderName: 'Name des Karteninhabers',
    cardIssuerCountry: 'Kartenausgebendes Land',
    cardNumber: 'Kartennummer',
    cardType: 'Kartentyp',
    category: 'Category',
    'custom-filters': 'Benutzerdefinierte Filter',
    'channel-information': 'Kanalinformationen',
    modes: 'Modes',
    challengeOptions: 'Herausforderungsoptionen',
    chargeback: 'Chargeback',
    'chargeback-chat': 'Chargeback process comunitaction',
    'chargeback-create-success': 'Chargeback successfully created',
    'chargeback-confirm-attachment-delete': 'Anhang löschen?',
    'chargeback-files': 'Chargeback process files',
    'chargeback-information': 'General Information',
    'chargeback-order-creationReason': 'Chargeback creation Reason',
    'chargeback-order-information': 'Order Information',
    'chargeback-ref': 'Referenz',
    'chargeback-status-closed': 'Closed',
    'chargeback-status-created': 'Created',
    'chargeback-status-disputing': 'Disputing',
    'chargeback-status-lost': 'Lost',
    'chargeback-status-pendingaction': 'Pending Action',
    'chargeback-status-won': 'Won',
    'chargeback-store-information': 'Store Information',
    'chargeback-store-name': 'Geschäftsname',
    'chargeback-title': 'Chargebacks',
    'chargeback-title-add': 'Iniciar processo de Disputa',
    'chargeback-transaction-information': 'Transation Information',
    chargebackRef: 'Chargeback-Referenz',
    chargebacks: 'Chargebacks',
    'choose-file-type': 'Dateityp auswählen',
    clear: 'Löschen',
    'click-to-toggle': 'Klicken Sie hier, um das Menü umzuschalten',
    'click-to-upload': 'Klicken Sie hier zum Hochladen',
    'client-frequent-flyer-number': 'Vielfliegernummer',
    clientFrequentFlyerNumber: 'Vielfliegernummer',
    clientFrequentFlightNumber: 'Vielfliegernummer',
    closed: 'Abgeschlossen',
    code: 'Code',
    'collect-shipping-data': 'Versanddaten',
    'comission-amount': 'Provisionsbetrag',
    content: 'Inhalt',
    'copied-key': 'Schlüssel $1 in Zwischenablage kopiert!',
    'copied-link': 'Link {1} in den Transferbereich kopiert!',
    'copy-key': 'Schlüssel kopieren',
    costs: 'Kosten',
    country: 'Land',
    'country-code': 'Landesvorwahl',
    'create-chargeback-from-transaction': 'Create Chargeback',
    'create-checkout': 'Kasse erstellen',
    created: 'Erstellt',
    'created-date': 'Erstellungsdatum',
    creating: 'Erstellen...',
    currency: 'Währung',
    'current-balance': 'Aktueller Kontostand',
    'customer-address': 'Kundenadresse',
    'customer-name': 'Kundenname',
    'dashboard-title': 'Dashboard',
    date: 'Datum',
    day: 'Tag',
    days: 'Tage',
    dccAmount: 'DCC Amount',
    declined: 'Abgelehnt',
    deductions: 'Abzüge',
    'delete-attachment-forbidden-error':
      'Sie können diesen Anhang nicht löschen',
    denied: 'Bestritten',
    deposit: 'Deposit',
    description: 'Beschreibung',
    details: 'Detail',
    'developers-description':
      'Bitte halten Sie die Daten so privat wie möglich.',
    'developers-link': 'Lesen Sie mehr über die Integration unserer APIs',
    'developers-subtitle':
      'Verwenden Sie den Test-API-Schlüssel, um Ihre Integration zu testen, und den Live-API-Schlüssel, um echte Zahlungen zu erstellen.',
    'developers-title': 'Developers',
    disputing: 'Disputing',
    'drag-drop-file-here': 'Drop your file here',
    domain: 'Domain',
    'domain-not-available': 'Domain nicht verfügbar',
    download: 'Herunterladen',
    downloading: 'Heruntergeladen...',
    'dst-description': 'Diese Zeitzone ist derzeit in DST',
    doYouWannaFullChargeback:
      'Möchten Sie eine teilweise oder vollständige Rückbuchung vornehmen?',
    doYouWannaFullRefund:
      'Möchten Sie eine teilweise oder vollständige Rückerstattung vornehmen?',
    dutch: 'Niederländisch',
    eci: 'ECI',
    ecommerce: 'Ecommerce',
    'edit-checkout': 'Kasse bearbeiten',
    'edit-checkout-success': 'Social Payment erfolgreich bearbeitet',
    'edit-data': 'Daten bearbeiten',
    editing: 'Bearbeitung...',
    email: 'Email',
    'end-date': 'Enddatum',
    'end-time': 'Endzeit',
    english: 'Englisch',
    'entity-code': 'Entitätscode',
    environment: 'Umgebung',
    'environment-hint':
      'Check to see Production transactions only. Uncheck to see Test transactions only',
    error: 'Fehler',
    'event-type': 'Ereignistyp',
    'expire-at': 'Ablaufen am',
    expired: 'Abgelaufen',
    'expired-token':
      'Sie wurden weitergeleitet, weil Ihre Sitzung abgelaufen ist',
    'expiry-date': 'Expiry Date',
    export: 'Exportieren $1',
    'export-details': 'Exportieren',
    'export-details-help':
      'Bevor Sie das Dateiexportieren starten, unser System verarbeitet Ihre Anfrage. Nach dem Abschluss der Bearbeitung, sendet uns das exportierte Datei direkt an die angegebene E-Mail-Adresse ($1).',
    'export-details-alert': 'Achtung',
    externalTransactionId: 'Externe Transaktions-ID',
    externalCustomId: 'Akquirierer-Id',
    'extra-filters-title': 'Individuelle Filterung',
    failed: 'Gescheitert',
    fees: 'Gebühren',
    'file-sent': '$1 was sent.',
    'file-upload-helper':
      'Um eine Datei hochzuladen, klicken Sie auf die Bezeichnung der Eingabe',
    'filter-by': 'Filtern nach $1',
    'filter-by-enter': 'Filtern nach $1 (Drücken Sie Enter, um zu suchen)',
    'fin-product': 'Endprodukt',
    firstname: 'Vorname',
    flightData: 'Flugdaten',
    'forgot-password': 'Passwort vergessen?',
    'fraud-scoring': 'Fraud Scoring',
    french: 'Französisch',
    fulfilled: 'Erfüllt',
    'full-chargeback': 'Vollständige Rückbuchung',
    'full-refund': 'Vollständige Rückerstattung',
    'full-capture': 'Volle Erfassung',
    'funding-source': 'Funding Source',
    'generate-checkout': 'Kasse generieren',
    german: 'Deutsch',
    'go-to': 'Gehe zu $1',
    'group': 'Gruppen',
    'group-id': 'Gruppen-ID',
    'group-name': 'Gruppenname',
    groupId: 'Gruppe',
    hasChargeback: 'Chargeback zugeordnet',
    'hide-all': 'Alle Anzeigen',
    holder: 'Halter',
    hour: 'Stunde',
    hours: 'Std',
    history: 'Historique',
    'transaction-history': 'Transaktionsverlauf',
    iban: 'IBAN',
    id: 'ID',
    'in-transactions': 'in $1 transaktionen',
    'initiated-from': 'Initiiert von',
    initiatedFrom: 'Initiated From',
    installmentAmount: 'Installment Amount',
    installments: 'Installments',
    international: 'International',
    'international-shipping-amount': 'Internationaler Versandbetrag',
    'international-urgent': 'International dringend',
    'international-urgent-shipping-amount':
      'Betrag für internationalen dringenden Versand',
    'invite-error':
      'Es ist ein Fehler beim versenden der Einkladung aufgetreten',
    'invite-merchants': 'Händler einladen',
    'invite-sent':
      'Die Einladung wurde an $1 versendet. Onboarding-Formular generiert in: $2',
    invoice: 'Rechnungen',
    invoices: 'Rechnungen',
    ip: 'IP',
    'is-one-click-payment': 'Is one click',
    isoAmount: 'Amount',
    isOneClickPayment: 'Is one click',
    'item-service-amount': 'Artikel-/Servicebetrag',
    'item-service-description': 'Produkt-/Dienstleistungsbeschreibung',
    'item-service-image': 'Produkt-/Servicebild hochladen',
    'item-service-name': 'Produkt-/Servicename',
    'journal-date': 'Tagebuchdatum',
    language: 'Sprache',
    'last-4-card-numbers': 'letzten 4 Ziffern der Karte',
    'last-chargebacks': 'Letzter $1 storno',
    'last-month': 'Letzten Monat',
    'last-refunds': 'Letzte $1 rückerstattung',
    'last-revenue': 'Letzter $1 umsatz',
    'last-week': 'Letzte Woche',
    lastFourCardNumber: 'Letzte vier Ziffern der Kartennummer',
    lastname: 'Nachname',
    'latest-payments': 'Letzte Zahlungen (nur Produktion)',
    'liability-shift': 'Liability Shift',
    LiabilityShift: 'Liability Shift',
    'link-validity': 'Linkgültigkeit',
    'live-api-key': 'Live-API-Schlüssel',
    loading: 'Loading...',
    'loading-order': 'Loading Order Data',
    'loading-ref-transactions': 'Loadinf related Transactions',
    'loading-transaction': 'Loading Transaction',
    'loading-webhook-attempts': 'Lade Webhook-Versuche',
    'log-into-paybyrd': 'Melden Sie sich bei Paybyrd an',
    login: 'Anmelden',
    logout: 'Ausloggen',
    lost: 'Lost',
    'management-title': 'Verwaltung',
    market: 'Markt',
    maskedCardNumber: 'Masked Card Number',
    merchant: 'Händler',
    'merchant-id': 'Händler-ID',
    'merchant-information': 'Händlerinformationen',
    'merchant-name': 'Händlername',
    merchantId: 'Händler',
    merchantIds: 'Händler',
    'merchant-pendent': 'Händler Ausstehend',
    'message-label': 'Message',
    mode: 'Modus',
    month: 'Monat',
    'monthly-revenue': 'Monatlicher Umsatz (nur Produktion)',
    months: 'Monate',
    name: 'Name',
    national: 'National',
    'national-shipping-amount': 'Nationaler Versandbetrag',
    'national-urgent': 'National dringend',
    'national-urgent-shipping-amount':
      'Nationaler Betrag für dringende Sendungen',
    no: 'Nein',
    'no-balance': 'Es gibt kein Saldo für die ausgewählten Filter',
    'no-balance-description':
      'Wählen Sie, einen anderen Monat oder eine andere Einheiten',
    'no-chargebacks':
      'Für die ausgewählten Filter konnten wir keine Rückbuchung finden',
    'no-chargebacks-subtitle':
      'Versuchen Sie es mit einer anderen Filter combination.',
    'no-info': 'Keine Informationen vorhanden',
    'no-invoices': 'Für den ausgewählten Zeitraum liegen keine Rechnungen vor',
    'no-invoices-description': 'Versuchen Sie, ein anderes Jahr auszuwählen',
    'no-onboardings':
      'Wir konnten keine Onboarding-Prozesse für die aktuellen Filter finden',
    'no-onboardings-subtitle':
      'Versuchen Sie es mit einer anderen Filter combination',
    'no-options': 'No Options',
    'no-orders':
      'Es wurden keine Transaktionen für die ausgewählten Filter gefunden',
    'no-orders-subtitle':
      'Wählen Sie  einen anderen Zeitschlitz, eine andere Einheit, einen anderen Status oder andere Kanäle aus',
    'no-payments': 'Es gibt keine kürylich erfolgte Transaktionen',
    'no-payments-subtitle':
      'Aktualisieren Sie die Daten, um neue Transaktionen zu finden',
    'no-reconciliation-files': 'Es sind keine Abgleichsdateien verfügbar',
    'no-ref-transactions':
      'Es gab keine Ergebnisse im Zusammenhang mit dieser Anfrage',
    'no-ref-transactions-subtitle':
      "'Auf Aktualisieren drücken, um neue Transaktionen zu prüfen'",
    'no-results': 'Keine Ergebnisse',
    'no-settlements':
      'Es gibt keine Abrechnungen für den ausgewählten Zeitraum',
    'no-settlements-description':
      'Versuchen Sie, einen anderen Monat auszuwählen',
    'no-payouts': 'Es gibt keine Abrechnungen für den ausgewählten Zeitraum',
    'no-shifts':
      'Es wurden keine Transaktionen für die ausgewählten Filter gefunden',
    'no-shifts-subtitle':
      'Wählen Sie  einen anderen Zeitschlitz, eine andere Einheit, einen anderen Status oder andere Kanäle aus',
    'no-statistics':
      'Es gibt keine statistischen Daten für die auswählten Filter',
    'no-statistics-subtitle':
      'Wählen Sie einen anderen Zeitraum oder Einheiten aus',
    'no-totals': 'Es sind keine Statistiks für den aktuellen Monat vorhanden',
    'no-totals-subtitle':
      'Es sind keine Statistiks für den gewahlten Monat vorhanden',
    'no-transactions':
      'Es wurden keine Transaktionen für die ausgewählten Filter gefunden',
    'no-transactions-subtitle':
      'Wählen Sie  einen anderen Zeitschlitz, eine andere Einheit, einen anderen Status oder andere Kanäle aus',
    'no-webhooks':
      'Es wurden keine Webhooks für die ausgewählten Filter gefunden',
    none: 'None',
    'not-visible-columns': 'Versteckte Spalten',
    now: 'Jetzt',
    onboarding: 'Benutzer',
    'onboarding-details': 'Onboarding-Details',
    onboardings: 'Onboardings',
    opened: 'Geöffnet',
    'order-amount': 'Auftragsbetrag',
    'order-date': 'Bestelldatum',
    'order-ref': 'Anfragenummer',
    'order-reference': 'Anfragenummer',
    order: 'Anfrage',
    orderProcessing: 'Bestellung in Bearbeitung',
    orderDenied: 'Bestellung Abgelehnt',
    orderPaid: 'Bestellung Bezahlt',
    orderPending: 'Bestellung Ausstehend',
    orderRefunded: 'Bestellung Erstattet',
    orderCanceled: 'Bestellung Storniert',
    orderError: 'Bestellfehler',
    orderFailed: 'Bestellung Fehlgeschlagen',
    orderExpired: 'Bestellung Abgelaufen',
    orderDeclined: 'Bestellung Abgelehnt',
    orderCreated: 'Bestellung Erstellt',
    orderClosed: 'Bestellung Geschlossen',
    orderOpened: 'Bestellung Geöffnet',
    noOrderInfo: 'Keine Bestellinformationen',
    'order-details': 'Bestelldetails',
    orderPaymentInformation: 'Bestellzahlungsinformationen',
    orderId: 'Anfrage id',
    orderRef: 'Anfragenummer',
    orderref: 'Anfragenummer',
    orders: 'Aufträge',
    'orders-title': 'Anfrage',
    paid: 'Bezahlt',
    'parent-payment-id': 'Übergeordnete Zahlungs-ID',
    'partially captured': 'Teilweise erfasst',
    'partially refunded': 'Teilweise zurückerstattet',
    'partial-chargeback': 'Teilweise Rückbuchung',
    'partial-refund': 'Teilweise Rückerstattung',
    'partial-capture': 'Teilweise Erfassung',
    'partners-title': 'Partner',
    'passenger-name-record': 'Passenger Name Record',
    password: 'Passwort',
    'pay-by-link': 'PayByLink',
    'pay-by-link-create': 'Neuen Bezahllink erstellen',
    'pay-by-link-description':
      'Erstellen, senden Sie Bezahllinks und lassen Sie sich online bezahlen',
    'pay-by-link-title': 'Pay by Link',
    'pay-link': 'Bezahllink',
    'pay-links': 'Bezahllinks',
    payment: 'Zahlung',
    'payment-amount': 'Zahlungsbetrag',
    'payment-id': 'Zahlungs-ID',
    'payment-information': 'Zahlungsinformation',
    'payment-link-error':
      'Es ist ein Fehler beim versenden der Links aufgetreten',
    'payment-link-success': 'Der Zahlungslink wurde erfolgreich versendet.',
    'payment-method': 'Bezahlverfahren',
    paymentDate: 'Payment Date',
    'payment-purpose': 'Zahlungszweck',
    paymentId: 'Transaction Id',
    paymentInformation: 'Zahlungsinformation',
    paymentMethod: 'Bezahlverfahren',
    payments: 'Zahlungen',
    'payments-title': 'Zahlungen',
    'payouts-title': 'Payouts',
    'payout-title': 'Einzelheiten $1',
    'payouts-warning-message':
      'Diese Funktion ist vorübergehend nicht verfügbar. Wir sind dabei, unser System zu verbessern, um Sie noch besser bedienen zu können. Wir entschuldigen uns für die entstandenen Unannehmlichkeiten und werden uns bemühen, diese Funktion schnellstmöglich wiederherzustellen. Wenn Sie weitere Fragen haben, können Sie uns über die üblichen Kanäle kontaktieren.',
    pending: 'Ausstehend',
    pendingaction: 'Pending Action',
    pendingmerchantaction: 'Ausstehende Händleraktion',
    period: 'Zeitraum',
    'phone-number': 'Telefonnummer',
    phoneNumber: 'Telefonnummer',
    pnr: 'PNR',
    portuguese: 'Portugiesisch',
    pos: 'POS',
    'pre-auth': 'Pre-Authorization',
    preauth: 'PreAuth',
    'predictions-dispute': 'I want to dispute',
    'predictions-question': 'I have a question',
    'preferred-payment-methods': 'Bevorzugte Zahlungsmethoden',
    'previous-revenue': 'Vorheriger Umsatz',
    processing: 'In Bearbeitung',
    processingData: 'Processing Data',
    prod: 'Produktion',
    'product-name': 'Produkt-/Servicename',
    quarter: 'Quartal',
    quarters: 'Quartale',
    range: 'Bereich',
    reason: 'Reason for initiating Chargeback',
    'recipient-email': 'Empfänger E-Mail',
    'recipient-name': 'Empfängername',
    'recipient-number': 'Telefonnummer des Empfängers',
    'reconciliation-date': 'Abgleichsdatum',
    'reconciliation-file': 'Abgleichsarchiv',
    'reconciliation-files': 'Abgleichsdateien',
    'recover-paybyrd-password': 'Passwort wiederherstellen',
    'recovery-password-success':
      'Es wurde einen E-Mail versendet, um Ihr Passwort zurückzusetzen.',
    reference: 'Referenz',
    'reference-ids': 'Referenz-IDs',
    refresh: 'Aktualisierung',
    refund: 'Rückerstattung',
    'refund-amount': 'Rückerstattungsbetrag',
    refundDate: 'Rückerstattungsdatum',
    'refund-confirm-message':
      'Do you really want to refund this transaction in the amount of $1?',
    'refund-transaction': 'Rückerstattungstransaktion',
    refunded: 'Zurückerstattet',
    refunding: 'Rückerstattung...',
    refunds: 'Rückerstattungen',
    'refund-create-success': 'Rückerstattung erfolgreich erstellt',
    'register-now': 'Jetzt registrieren',
    'related-operation-status': 'Zugehöriger Vorgangsstatus',
    'related-shift': 'Zugehörige Schicht',
    'related-status': 'Zugehöriger Status',
    'related-transactions': 'Zugehörige Transaktionen',
    relatedOperationStatus: 'Related Operation Status',
    release: 'Release',
    releasing: 'Releasing…',
    remark: 'Remark',
    'request-info': 'Request Info',
    'required-field': 'Dieses Feld ist erforderlich',
    resend: 'Erneut senden',
    'resend-error':
      'Es gab einen Fehler beim erneuten Senden des Webhooks ${1}.',
    'resend-success': "Der Webhook '$1' wurde erfolgreich erneut gesendet.",
    resending: 'Erneutes Senden...',
    reservation: 'Authorization',
    response: 'Antwort',
    revenue: 'Umsatz',
    'reverse-confirmation':
      'Sind Sie sicher, dass Sie die Transaktion mit der ID $1 freigeben möchten?',
    'reverse-create-success': 'Rückbuchung erfolgreich erstellt',
    'search-filters': 'Filter suchen',
    'search-order-ref':
      'Search order reference value. At least 3 characters required to start searching',
    'search-transaction': 'Search Transaction',
    'select-all': 'Wählen Sie Alle',
    'deselect-all': 'Alle abwählen',
    'select-filter': 'Filter auswählen',
    selected: 'ausgewählt',
    'selected-s': '$1 $2 ausgewählt',
    'send-files': 'Send Files',
    'send-invite': 'Einladungversendet',
    'send-link': 'Link versenden',
    'send-links': 'Links versenden',
    'send-message': 'Send Message',
    'send-message-and-dispute': 'Send message and open Dispute',
    'send-recover-email': 'Wiederherstellungs-E-Mail versenden',
    sending: 'Versenden...',
    'sent-at': 'Gesendet am',
    'settings-title': 'Einstellungen',
    'settlement-date': 'Abrechnungsdatum',
    'settlement-details': 'Abrechnungsdetail',
    settlements: 'Abgleich',
    shift: 'Schicht $1',
    'shift-details': 'Schichtdetails',
    'shifts-title': 'Schichten',
    'shipping-address': 'Lieferadresse',
    'shipping-amount': 'Versandbetrag',
    shopper: 'Käufer',
    'shopper-country': 'Land des Käufers',
    'shopper-details': 'Käuferdetails',
    'shopper-email': 'E-Mail des Käufers',
    'shopper-interaction': 'Shopper Interaction',
    'shopper-ip-address': 'IP-Adresse des Käufers',
    shopperEmail: 'E-Mail des Käufers',
    shopperInformation: 'Käuferinformationen',
    shopperIp: 'IP-Adresse des Käufers',
    'show-all': 'Alle Ausblenden',
    'show-details': 'Details zeigen',
    'social-payments-title': 'Social Payments',
    spanish: 'Spanisch',
    'start-date': 'Startdatum',
    'start-time': 'Startzeit',
    'statistics-title': 'Statistiken',
    status: 'Stand',
    stock: 'Menge',
    'stock-quantity': 'Bestandsmenge',
    store: 'Geschäft',
    'store-name': 'Geschäftsname',
    storeId: 'Geschäft',
    success: 'Erfolg',
    'system-language': 'Systemsprache',
    'system-timezone': 'Systemzeitzone',
    'temporary-failed': 'Vorübergehender Fehler',
    'terminal-code': 'Terminal Code',
    'terminal-serial-number': 'Seriennummer des Terminals',
    terminalCode: 'Terminal Code',
    terminalPassword: 'Neues Passwort',
    'new-password': 'New Password',
    'confirm-new-password': 'Neues Kennwort bestätigen',
    'save-password': 'Passwort Speichern',
    'changing-password': 'Passwort ändern...',
    'confirm-change-password-message':
      'Bestätigen Sie die Änderung des Terminal-Passworts?',
    'passwords-do-not-match-message': 'Die Passwörter stimmen nicht überein!',
    'change-terminal-password': 'Terminal-Passwort ändern',
    'password-must-have-six-numbers':
      'Das Passwort muss sechs Ziffern enthalten!',
    'password-changed-successfully': 'Passwort erfolgreich geändert!',
    terminalSerialNumber: 'Terminal Serial Number',
    test: 'Test',
    'test-api-key': 'API-Schlüssel testen',
    'this-month': 'Diesen Monat',
    'this-week': 'Diese Woche',
    threeDSecure: '3DS',
    title: 'Titel',
    today: 'Heute',
    total: 'Gesamt',
    'total-amount': 'Gesamtsumme',
    'total-revenue': 'Gesamtumsatz',
    totals: 'Gesamt',
    'totals-per': 'Summen pro $1',
    'transaction-amount': 'Transaktionsbetrag',
    'transaction-details': 'Einzelheiten der Transaktion',
    'transaction-type': 'Art der Transaktion',
    transactionId: 'Transaction Id',
    transactions: 'transaktionen',
    transactionMbWay: 'Transaktion MBWAY',
    transactionMultibanco: 'Transaktion Multibanco',
    transactionMulticaixa: 'Transaktion Multicaixa',
    transactionPayPal: 'Transaktion PayPal',
    transactionIdeal: 'Transaktion IDeal',
    'transactions-count': 'Anzahl von Transaktionen',
    'transactions-title': 'Transaktionen',
    'transactions-volume': 'Transaktionsvolumen',
    'transactions-multiple-currencies-found':
      'Wenn mehrere Währungen ausgewählt sind, wird der Gesamtumsatz/Betrag möglicherweise nicht angezeigt.',
    'transation-processing-title': 'Processing',
    transfer: 'Transfer',
    tranStatus: '3DS Trans Status',
    type: 'Typ',
    unit: 'Laden Sie',
    units: 'Läden',
    user: 'Benutzer',
    'validation-max-chars':
      'Die maximale Länge für dieses Feld beträgt $1 Zeichen',
    'verification-method': 'Methode zur Überprüfung',
    version: 'Version',
    'view-by': 'Ansicht pro $1',
    'view-details': 'Details anzeigen',
    'view-less': 'Weniger Anzeigen',
    'view-more': '+$1 mehr',
    'view-transaction-details-hint':
      'Klicken Sie hier, um Details zur Transaktion anzuzeigen',
    'visible-columns': 'Sichtbare Spalten',
    volume: 'Volumen',
    webhooks: 'Webhooks',
    week: 'Woche',
    weeks: 'Wochen',
    'with-value': 'with value',
    withdrawal: 'Withdrawal',
    won: 'Won',
    year: 'Jahr',
    years: 'Jahre',
    yes: 'Ja',
    yesterday: 'Gestern',
  },
  nl: {
    '3ds': '3DS',
    '3ds-acs-reference-number': '3DS ACS Reference Number',
    '3ds-authentication-type': '3DS Authentication Type',
    '3ds-challenged': '3DS Challenged',
    '3ds-requested': '3DS Requested',
    '3ds-version': '3DS Version',
    accept: 'Accept',
    accepted: 'Accepted',
    'account-holder': 'Rekeninghouder',
    acquirer: 'Acquirer',
    'acquirer-entity': 'Acquirer Entiteit',
    'acquirer-reference': 'Acquirer-referentie',
    'acquirer-response': 'Antwoord van de Acquirer',
    'acquirer-response-code': 'Acquirer-Reactiecode',
    'acquirer-response-message': 'Acquirer-reactiebericht',
    'acquirer-processing': 'Verwerking door Acquirer',
    acquirerfailed: 'Mislukt',
    acquirerReferenceNumber: 'Acquirer Referentienummer (ARN)',
    acquirerId: 'Acquirer-Id',
    acquirerResponseCode: 'Acquirer-Reactiecode',
    acquirerResponseMessage: 'Acquirer-reactiebericht',
    acquirersuccess: 'Succes',
    actions: 'Acties',
    add: 'Create',
    'add-filter': 'Filter toevoegen',
    'additional-filters': 'Extra Filters',
    'administration-title': 'Administratie',
    all: 'Allen',
    allAlt: 'Allen',
    amount: 'Aantal',
    'amount-greater-than-limit':
      'Het bedrag is groter dan het transactiebedrag',
    'analytics-title': 'Analytics',
    'api-key-not-created': 'API Key niet aangemaakt',
    'api-keys': 'API Keys',
    channel: 'Kanaal',
    apply: 'Toepassen',
    'apply-filters': 'Filters toepassen',
    'applied-filters': 'Het filter wordt op uw dashboard toegepast',
    'aquirer-authorization-code': 'Acquirer-autorisatiecode',
    aquirerAuthorizationCode: 'Acquirer-autorisatiecode',
    'attachment-not-found-error': 'Bijlage niet gevonden',
    attempt: 'Poging',
    attempts: 'Pogingen',
    'authorization-code': 'Authorisatie Code',
    authorizationCode: 'Authorisatie Code',
    authorizationStatus: 'Autorisatiestatus',
    back: 'Terug',
    'bank-identifier-code': 'Bankidentificatie',
    brand: 'Merk',
    cardScheme: 'Card Scheme',
    businessName: 'Business Name',
    cancel: 'Annuleer',
    canceled: 'Geannuleerd',
    capture: 'Capture',
    'capture-create-success': 'Inning succesvol aangemaakt',
    'capture-transaction': 'Capture transactie',
    captured: 'Captured',
    capturing: 'Capturing...',
    'card-details': 'Kaartgegevens',
    'card-holder': 'Kaarthouder',
    'card-holder-name': 'Naam kaarthouder',
    'card-number': 'Kaartnummer',
    cardHolderName: 'Naam kaarthouder',
    cardIssuerCountry: 'Land van kaartuitgifte',
    cardNumber: 'Kaartnummer',
    cardType: 'Kaarttype',
    category: 'Category',
    'custom-filters': 'Aangepaste filters',
    'channel-information': 'Kanaalinformatie',
    modes: 'Modes',
    challengeOptions: 'Uitdagingsopties',
    chargeback: 'Chargeback',
    'chargeback-chat': 'Chargeback process comunitaction',
    'chargeback-create-success': 'Chargeback successfully created',
    'chargeback-confirm-attachment-delete': 'Bijlage verwijderen?',
    'chargeback-files': 'Chargeback process files',
    'chargeback-information': 'General Information',
    'chargeback-order-creationReason': 'Chargeback creation Reason',
    'chargeback-order-information': 'Order Information',
    'chargeback-ref': 'Referentie',
    'chargeback-status-closed': 'Closed',
    'chargeback-status-created': 'Created',
    'chargeback-status-disputing': 'Disputing',
    'chargeback-status-lost': 'Lost',
    'chargeback-status-pendingaction': 'Pending Action',
    'chargeback-status-won': 'Won',
    'chargeback-store-information': 'Store Information',
    'chargeback-store-name': 'Winkel naam',
    'chargeback-title': 'Chargebacks',
    'chargeback-title-add': 'Iniciar processo de Disputa',
    'chargeback-transaction-information': 'Transation Information',
    chargebackRef: 'Chargeback-referentie',
    chargebacks: 'Terugboekingen',
    'choose-file-type': 'Kies het bestandstype',
    clear: 'Opschonen',
    'click-to-toggle': 'Klik om menu te wisselen',
    'click-to-upload': 'Klik hier om te uploaden',
    'client-frequent-flyer-number': 'Frequent Flyer Nummer',
    clientFrequentFlyerNumber: 'Frequent Flyer Nummer',
    clientFrequentFlightNumber: 'Frequent Flyer Nummer',
    closed: 'Afgesloten',
    code: 'Code',
    'collect-shipping-data': 'Verzendgegevens verzamelen',
    'comission-amount': 'Commissie Bedrag',
    content: 'Inhoud',
    'copied-key': 'Key $1 gekopieerd naar clipboard',
    'copied-link': 'Link {1} gekopieerd naar het overdrachtsgebied!',
    'copy-key': 'Copy Key',
    costs: 'Kosten',
    country: 'Land',
    'country-code': 'Landcode',
    'create-chargeback-from-transaction': 'Create Chargeback',
    'create-checkout': 'Betaling opmaken',
    created: 'Gecreerd',
    'created-date': 'Aanmaak Datum',
    creating: 'Creëren...',
    currency: 'Valuta',
    'current-balance': 'Huidige Saldo',
    'customer-address': 'Klant adres',
    'customer-name': 'Klantnaam',
    'dashboard-title': 'Dashboard',
    date: 'Datum',
    day: 'Dag',
    days: 'Dagen',
    dccAmount: 'DCC Amount',
    declined: 'Afgewezen',
    deductions: 'Inhoudingen',
    'delete-attachment-forbidden-error': 'U kunt deze bijlage niet verwijderen',
    denied: 'Afgewezen',
    deposit: 'Deposit',
    description: 'Beschrijving',
    details: 'Details',
    'developers-description': 'Houdt deze privé zover als mogelijk.',
    'developers-link': 'Lees meer over de API integratie',
    'developers-subtitle':
      'Gebruik de Test API Key om de integratie te testen, and the Live API Key voor de daadwerkelijke betalingen.',
    'developers-title': 'Developers',
    disputing: 'Disputing',
    'drag-drop-file-here': 'Drop your file here',
    domain: 'Domein',
    'domain-not-available': 'Domein niet beschikbaar',
    download: 'Download',
    downloading: 'Downloading...',
    'dst-description': 'De tijdszone is momenteel in DST',
    doYouWannaFullChargeback:
      'Wilt u een gedeeltelijke of volledige terugboeking doen?',
    doYouWannaFullRefund:
      'Wilt u een gedeeltelijke of volledige terugbetaling doen?',
    dutch: 'Nederlands',
    eci: 'ECI',
    ecommerce: 'Ecommerce',
    'edit-checkout': 'Betaling bewerken',
    'edit-checkout-success': 'Social Payment succesvol bewerkt',
    'edit-data': 'Gegevens bewerken',
    editing: 'Bewerken...',
    email: 'Email',
    'end-date': 'Einddatum',
    'end-time': 'Eindtijd',
    english: 'Engels',
    'entity-code': 'Entiteitscode',
    environment: 'Omgeving',
    'environment-hint':
      'Check to see Production transactions only. Uncheck to see Test transactions only',
    error: 'Fout',
    'event-type': 'Gebeurtenistype',
    'expire-at': 'Verlopen op',
    expired: 'Verlopen',
    'expired-token': 'Je werd terug geleid omdat je sessie was verlopen',
    'expiry-date': 'Expiry Date',
    export: 'Export $1',
    'export-details': 'Export',
    'export-details-help':
      'Wanneer je begint met het exporteren van je bestand, zal ons systeem je verzoek verwerken. Zodra de verwerking is voltooid, sturen we het geëxporteerde bestand rechtstreeks naar het e-mailadres dat aan je account is gekoppeld ($1).',
    'export-details-alert': 'Attentie',
    externalTransactionId: 'Extern Transactie-ID',
    externalCustomId: 'Acquirer-Id',
    'extra-filters-title': 'Aangepaste Filtering',
    failed: 'Mislukt',
    fees: 'Tarieven',
    'file-sent': '$1 was sent.',
    'file-upload-helper':
      'Om een bestand te uploaden, klik in het label van de invoer',
    'filter-by': 'Filter met $1',
    'filter-by-enter': 'Filteren op $1 (Druk op Enter om te zoeken)',
    'fin-product': 'eindproduct',
    firstname: 'Voornaam',
    flightData: 'Vluchtgegevens',
    'forgot-password': 'Wachtwoord Vergeten?',
    'fraud-scoring': 'Fraud Scoring',
    french: 'Frans',
    fulfilled: 'Vervuld',
    'full-chargeback': 'Volledige terugboeking',
    'full-refund': 'Volledige terugbetaling',
    'full-capture': 'Volledige vastlegging',
    'funding-source': 'Funding Source',
    'generate-checkout': 'Creër betaling',
    german: 'Duits',
    'go-to': 'Go to $1',
    'group': 'Groeps',
    'group-id': 'Groeps-ID',
    'group-name': 'Groepsnaam',
    groupId: 'Groep',
    hasChargeback: 'Chargeback Geassocieerd',
    'hide-all': 'Alles Weergeven',
    holder: 'houder',
    hour: 'Uur',
    hours: 'Uren',
    iban: 'IBAN',
    id: 'ID',
    'in-transactions': 'in $1 transacties',
    'initiated-from': 'Geïnitieerd van',
    initiatedFrom: 'Initiated From',
    installmentAmount: 'Installment Amount',
    installments: 'Installments',
    international: 'Internationaal',
    'international-shipping-amount': 'Verzendkosten internationaal',
    'international-urgent': 'Internationale urgentie',
    'international-urgent-shipping-amount':
      'Prioriteit Verzendkosten internationaal',
    'invite-error': 'Er was een fout bij het verzenden van de uitnodiging.',
    'invite-merchants': 'Uitnodiging Merhants',
    'invite-sent':
      'De uitnodiging is verstuurd aan $1. Onboarding formulier genereerd op: $2',
    invoice: 'Facture',
    invoices: 'Facturen',
    ip: 'IP',
    'is-one-click-payment': 'Is one click',
    isoAmount: 'Amount',
    isOneClickPayment: 'Is one click',
    'item-service-amount': 'Bedrag artikel/service',
    'item-service-description': 'Artikel-/servicebeschrijving',
    'item-service-image': 'Afbeelding van item/service uploaden',
    'item-service-name': 'Naam item/service',
    'journal-date': 'Journaaldatum',
    language: 'Taal',
    'last-4-card-numbers': 'laatste 4 kaart nummers',
    'last-chargebacks': 'Laatste $1 terugboeking',
    'last-month': 'Vorige maand',
    'last-refunds': 'Laatste $1 terugbetaling',
    'last-revenue': 'Laatste $1 omzet',
    'last-week': 'Vorige week',
    lastFourCardNumber: 'Laatste Vier Cijfers van het Kaartnummer',
    lastname: 'Achternaam',
    'latest-payments': 'Laatste betalingen (alleen productie)',
    'liability-shift': 'Liability Shift',
    LiabilityShift: 'Liability Shift',
    'link-validity': 'Link Geldigheid',
    'live-api-key': 'Live API Key',
    loading: 'Loading...',
    'loading-order': 'Loading Order Data',
    'loading-ref-transactions': 'Loadinf related Transactions',
    'loading-transaction': 'Loading Transaction',
    'loading-webhook-attempts': 'Webhook-pogingen laden',
    'log-into-paybyrd': 'Inloggen op Paybyrd',
    login: 'Inloggen',
    logout: 'Uitloggen',
    lost: 'Lost',
    'management-title': 'Management',
    market: 'Markt',
    maskedCardNumber: 'Masked Card Number',
    merchant: 'Verkoper',
    'merchant-id': 'Verkoper-ID',
    'merchant-information': 'Verkopersinformatie',
    'merchant-name': 'Naam verkoper',
    merchantId: 'Verkoper',
    merchantIds: 'Verkopers',
    'merchant-pendent': 'Handelaar In Afwachting',
    'message-label': 'Message',
    mode: 'Mode',
    month: 'Maand',
    'monthly-revenue': 'Maandelijkse omzet (alleen productie)',
    months: 'Maanden',
    name: 'Naam',
    national: 'Nationaal',
    'national-shipping-amount': 'Verzendkosten nationaal',
    'national-urgent': 'Nationale urgentie',
    'national-urgent-shipping-amount': 'Prioriteit Verzendkosten nationaal',
    no: 'Nee',
    'no-balance': 'We konden geen saldo vinden met de geselecteerde filters',
    'no-balance-description': 'Selecteer een andere maand of eenheid ',
    'no-chargebacks':
      'We konden geen terugboekingen vinden voor de geselecteerde filters',
    'no-chargebacks-subtitle': 'Probeer een andere combinatie',
    'no-info': 'Geen informatie',
    'no-invoices': 'Er zijn geen facturen voor de geselecteerde periode',
    'no-invoices-description': 'Selecteer een ander jaar',
    'no-onboardings':
      'Er zijn geen onboarding processen voor de huidig filters',
    'no-onboardings-subtitle': 'Probeer een andere combinatie',
    'no-options': 'No Options',
    'no-orders': 'We krijgen geen transacties gevonden met de gekozen filters',
    'no-orders-subtitle': 'Graag een ander selectie te maken ',
    'no-payments': 'Geen recente transacties',
    'no-payments-subtitle':
      'Vernieuw om te zien of nieuwe transacties beschikbaar zijn',
    'no-reconciliation-files':
      'Er zijn geen reconciliatie bestanden beschikbaar',
    'no-ref-transactions': 'We zien geen relaterde transacties voor deze order',
    'no-ref-transactions-subtitle':
      'Vernieuw om te zien of nieuwe transacties beschikbaar zijn',
    'no-results': 'Geen resultaten',
    'no-payouts': 'Er zijn geen settlements voor de geselecteerde periode',
    'no-settlements': 'Er zijn geen settlements voor de geselecteerde periode',
    'no-settlements-description': 'Selecteer een andere maand',
    'no-shifts': 'We krijgen geen transacties gevonden met de gekozen filters',
    'no-shifts-subtitle': 'Graag een ander selectie te maken ',
    'no-statistics':
      'We krijgen geen transacties gevonden met de gekozen filters',
    'no-statistics-subtitle': 'Selecteer een andere periuode of unit ',
    'no-totals': 'Geen statistieken beschikbaar voor de huidige maand',
    'no-totals-subtitle': 'Bekijk statistiek pagina voor andere periodes',
    'no-transactions':
      'We krijgen geen transacties gevonden met de geselecteerde filters',
    'no-transactions-subtitle': 'Graag een ander selectie te maken ',
    'no-webhooks':
      'We krijgen geen webhooks gevonden met de geselecteerde filters',
    none: 'None',
    'not-visible-columns': 'Verborgen Kolommen',
    now: 'Nu',
    onboarding: 'Onboarding',
    'onboarding-details': 'Onboarding details',
    onboardings: 'Onboardings',
    opened: 'Geopend',
    'order-amount': 'Order hoeveelheid',
    'order-date': 'Besteldatum',
    'order-ref': 'Bestelreferentie',
    'order-reference': 'Order Reference',
    order: 'Bestel',
    orderProcessing: 'Order Processing',
    orderDenied: 'Order Denied',
    orderPaid: 'Order Paid',
    orderPending: 'Order Pending',
    orderRefunded: 'Order Refunded',
    orderCanceled: 'Order Canceled',
    orderError: 'Order Error',
    orderFailed: 'Order Failed',
    orderExpired: 'Order Expired',
    orderDeclined: 'Order Declined',
    orderCreated: 'Order Created',
    orderClosed: 'Order Closed',
    orderOpened: 'Order Opened',
    noOrderInfo: 'No Order Info',
    orderId: 'Bestelnummer',
    orderref: 'Bestelreferentie',
    orderRef: 'Bestelreferentie',
    orders: 'Bestellingen',
    'orders-title': 'Orders',
    paid: 'Betaald',
    'parent-payment-id': 'Betalings-ID ouder',
    'partially captured': 'Partially captured',
    'partially refunded': 'Partially refunded',
    'partial-chargeback': 'Gedeeltelijke terugboeking',
    'partial-refund': 'Gedeeltelijke terugbetaling',
    'partial-capture': 'Gedeeltelijke vastlegging',
    'partners-title': 'Partners',
    'passenger-name-record': 'Passenger Name Record',
    password: 'Wachtwoord',
    'pay-by-link': 'PayByLink',
    'pay-by-link-create': 'Creëer nieuwe betaallink',
    'pay-by-link-description':
      'Creëer, verzend betaallinks en wordt online betaald',
    'pay-by-link-title': 'Pay by Link',
    'pay-link': 'Betaallink',
    'pay-links': 'Betaallinks',
    payment: 'Betaling',
    'payment-amount': 'Betaal hoeveelheid',
    'payment-id': 'Betalings-ID',
    'payment-information': 'Payment Information',
    'payment-link-error': 'Er is een fout bij het verzenden van de links',
    'payment-link-success': 'Payment link was successvol verzonden.',
    'payment-method': 'Betaalmethode',
    'payment-purpose': 'Betalingdoel',
    paymentDate: 'Payment Date',
    paymentId: 'Transaction Id',
    paymentInformation: 'Payment Information',
    paymentMethod: 'Payment Method',
    payments: 'Betalingen',
    'payments-title': 'Betalingen',
    'payouts-title': 'Uitbetalingen',
    'payout-title': 'Detail $1',
    'payouts-warning-message':
      'Deze functie is tijdelijk niet beschikbaar. We brengen verbeteringen aan in ons systeem om je nog beter van dienst te kunnen zijn. We verontschuldigen ons voor het ongemak en zullen deze functionaliteit zo snel mogelijk herstellen. Voor verdere opheldering kun je contact met ons opnemen via de gebruikelijke kanalen.',
    pending: 'Bezig',
    pendingaction: 'Pending Action',
    pendingmerchantaction: 'Handelaarsactie in behandeling',
    period: 'Periode',
    'phone-number': 'Telefoonnummer',
    phoneNumber: 'Telefoonnummer',
    pnr: 'PNR',
    portuguese: 'Portugees',
    pos: 'POS',
    'pre-auth': 'Pre-Auth',
    preauth: 'PreAuth',
    'predictions-dispute': 'I want to dispute',
    'predictions-question': 'I have a question',
    'preferred-payment-methods': 'Voorkeurs Betaalmethode',
    'previous-revenue': 'Previous Revenue',
    processing: 'Verwerken',
    processingData: 'Processing Data',
    prod: 'Productie',
    'product-name': 'Productnaam',
    quarter: 'Kwartaal',
    quarters: 'Kwartalen',
    range: 'Kalender',
    reason: 'Reason for initiating Chargeback',
    'recipient-email': 'Ontvanger Email',
    'recipient-name': 'Ontvanger Naam',
    'recipient-number': 'Ontvanger Telefoonnummer',
    'reconciliation-date': 'Reconciliatie datum',
    'reconciliation-file': 'Reconciliatie archief',
    'reconciliation-files': 'Reconciliatie bestanden',
    'recover-paybyrd-password': 'Herstel Wachtwoord',
    'recovery-password-success':
      'We hebben je een e-mail gestuurd om je wachtwoord opnieuw in te stellen.',
    reference: 'Referentie',
    'reference-ids': 'Referentie-ids',
    refresh: 'Vernieuw',
    refund: 'Terugbetaling',
    'refund-amount': 'Terugbetaling hoeveelheid',
    refundDate: 'Terugbetalingsdatum',
    'refund-confirm-message':
      'Do you really want to refund this transaction in the amount of $1?',
    'refund-transaction': 'Terugstorting van transactie',
    refunded: 'Teruggestort',
    refunding: 'Terugbetaling...',
    refunds: 'Terugbetalingen',
    'refund-create-success': 'Terugbetaling succesvol aangemaakt',
    'register-now': 'Registreer Nu',
    'related-operation-status': 'Gerelateerde bewerkingsstatus',
    'related-shift': 'Gerelateerde Shift',
    'related-status': 'Gerelateerde Status',
    'related-transactions': 'Gerelateerde Transacties',
    relatedOperationStatus: 'Related Operation Status',
    release: 'Release',
    releasing: 'Releasing...',
    remark: 'Remark',
    'request-info': 'Request Info',
    'required-field': 'Dit veld is verplicht',
    resend: 'Opnieuw verzenden',
    'resend-error':
      'Er trad een fout op bij het opnieuw verzenden van de webhook ${1}.',
    'resend-success': "De webhook '$1' is succesvol opnieuw verzonden.",
    resending: 'Opnieuw verzenden...',
    reservation: 'Reservering',
    response: 'Reactie',
    revenue: 'Omzet',
    'reverse-confirmation':
      'Weet u zeker dat u de transactie met de ID $1 wilt vrijgeven?',
    'reverse-create-success': 'Terugboeking succesvol aangemaakt',
    'search-filters': 'Filters zoeken',
    'search-order-ref':
      'Search order reference value. At least 3 characters required to start searching',
    'search-transaction': 'Search Transaction',
    'select-all': 'Selecteer Alles',
    'deselect-all': 'Alles deselecteren',
    'select-filter': 'Filter selecteren',
    selected: 'geselecteerd',
    'selected-s': '$1 $2 geselecteerd',
    'send-files': 'Send Files',
    'send-invite': 'Verzend uitnodiging',
    'send-link': 'Verzend link',
    'send-links': 'Verzend links',
    'send-message': 'Send Message',
    'send-message-and-dispute': 'Send message and open Dispute',
    'send-recover-email': 'Verstuur herstel Email',
    sending: 'Verzenden...',
    'sent-at': 'Verzonden op',
    'settings-title': 'Instellingen',
    'settlement-date': 'Settlement Date',
    'settlement-details': 'Settlement Details',
    settlements: 'Verrekening',
    shift: 'Shift $1',
    'shift-details': 'Shift Details',
    'shifts-title': 'Shifts',
    'shipping-address': 'Verzend adres',
    'shipping-amount': 'Verzendkosten',
    shopper: 'Koper',
    'shopper-country': 'Land van de Koper',
    'shopper-details': 'Details van de Koper',
    'shopper-email': 'E-mail van de Koper',
    'shopper-interaction': 'Shopper Interaction',
    'shopper-ip-address': 'IP-adres van de Koper',
    shopperEmail: 'E-mail van de Koper',
    shopperInformation: 'Shopperinformatie',
    shopperIp: 'IP-adres van de Koper',
    'show-all': 'Alles Verbergen',
    'show-details': 'Deel details',
    'social-payments-title': 'Social Payments',
    spanish: 'Spaans',
    'start-date': 'Start Datum',
    'start-time': 'Starttijd',
    'statistics-title': 'Statistiek',
    status: 'Status',
    stock: 'Voorraad',
    'stock-quantity': 'Hoeveelheid voorraad',
    store: 'Winkel',
    'store-name': 'Winkel naam',
    storeId: 'Winkel',
    success: 'Succes',
    'system-language': 'System Taal',
    'system-timezone': 'Systeem Tijdszone',
    'terminal-code': 'Terminal Code',
    'terminal-serial-number': 'Terminal serienummer',
    terminalCode: 'Terminal Code',
    terminalPassword: 'Wachtwoord Terminal',
    'temporary-failed': 'Tijdelijk Mislukt',
    'new-password': 'Nieuw Wachtwoord',
    'confirm-new-password': 'Bevestig Nieuw Wachtwoord',
    'save-password': 'Wachtwoord Opslaan',
    'changing-password': 'Wachtwoord Wijzigen...',
    'confirm-change-password-message':
      'Wijziging wachtwoord Terminal bevestigen?',
    'passwords-do-not-match-message': 'Wachtwoorden komen niet overeen!',
    'change-terminal-password': 'Wijzig je Terminal wachtwoord',
    'password-must-have-six-numbers':
      'Het wachtwoord moet uit zes cijfers bestaan!',
    'password-changed-successfully': 'Wachtwoord succesvol gewijzigd!',
    terminalSerialNumber: 'Terminal Serial Number',
    test: 'Test',
    'test-api-key': 'Test API Key',
    'this-month': 'Deze maand',
    'this-week': 'Deze week',
    threeDSecure: '3DS',
    title: 'Titel',
    today: 'Vandaag',
    total: 'Totaal',
    'total-amount': 'Totaal bedrag',
    'total-revenue': 'Totaal omzeet',
    totals: 'Totalen',
    'totals-per': 'Totalen per $1',
    'transaction-amount': 'Transactie Bedrag',
    'transaction-details': 'Transactiegegevens',
    'transaction-type': 'Transactie Type',
    transactionId: 'Transaction Id',
    transactions: 'transacties',
    transactionMbWay: 'Transactie MBWAY',
    transactionMultibanco: 'Transactie Multibanco',
    transactionMulticaixa: 'Transactie Multicaixa',
    transactionPayPal: 'Transactie PayPal',
    transactionIdeal: 'Transactie IDeal',
    'transactions-count': 'Transactions Count',
    'transactions-title': 'Transacties',
    'transactions-volume': 'Transactions Volume',
    'transactions-multiple-currencies-found': `Wanneer meerdere valuta's zijn geselecteerd, kan de totale omzet/het bedrag mogelijk niet worden weergegeven.`,
    'transation-processing-title': 'Processing',
    transfer: 'Transfer',
    tranStatus: '3DS Trans Status',
    type: 'Type',
    unit: 'Winkel',
    units: 'Winkels',
    user: 'Gebruiker',
    'validation-max-chars': 'De maximum lengte voor dit veld is $1 karakters',
    'verification-method': 'Verification Method',
    version: 'Version',
    'view-by': 'Beeld met $1',
    'view-details': 'Details bekijken',
    'view-less': 'Minder Weergeven',
    'view-more': '+$1 meer',
    'view-transaction-details-hint': 'Klik om transactiegegevens te bekijken',
    'visible-columns': 'Zichtbare Kolommen',
    volume: 'Volume',
    webhooks: 'Webhooks',
    week: 'Week',
    weeks: 'Weken',
    'with-value': 'with value',
    withdrawal: 'Withdrawal',
    won: 'Won',
    year: 'Jaar',
    years: 'Jaren',
    yes: 'Ja',
    yesterday: 'Gisteren',
  },
};
