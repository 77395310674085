import { call, fork, put } from 'redux-saga/effects';
import { enqueue } from '@store/reducers/notifications';
import { takeEveryAsync } from 'saga-toolkit';
import api from '@legacy/api';
import { DEFAULT_PAGE_SIZE } from '@services/config';
import { fetchStores, fetchSetPassword } from './reducer';

function* handleFetchStores({ meta: { arg } }) {
  try {
    const data = yield call(
      api.getPaginatedStores,
      arg?.pageNumber || 1,
      arg?.pageSize || DEFAULT_PAGE_SIZE,
      arg?.businessName || ''
    );
    return data;
  } catch (_) {
    throw new Error('Unhandled exception while fetching stores');
  }
}

function* handleSetPassword({ meta: { arg } }) {
  const translator = arg?.translator;
  try {
    var response = yield call(api.changePassword, arg?.personId, arg?.password);

    if (response?.status == 200) {
      yield put(
        enqueue({
          message: translator?.i18n('password-changed-successfully') ?? 'Password changed successfully',
          options: { variant: 'success' },
        })
      );

      return true;
    }

    throw new Error(response.data?.error?.internalMessage || 'Unhandled exception while changing password');
  } catch (error) {
    yield put(
      enqueue({
        message: error.message,
        options: { variant: 'error' },
      })
    );
    throw error;
  }
}

function* getStores() {
  yield takeEveryAsync(fetchStores.type, handleFetchStores);
}

function* setPassword() {
  yield takeEveryAsync(fetchSetPassword.type, handleSetPassword);
}

export function* storesSaga() {
  yield fork(getStores);
  yield fork(setPassword);
}
