import React from 'react';
import Chevron from '@assets/icons/Chevron';

import { ListItemButton, ListItemButtonChild, ListItemText, ListItemIcon } from './style';

export function MenuItem({ label, icon, onClick, selected, slug, hasChilds, ...props }) {
  return (
    <ListItemButton {...props} selected={selected} onClick={() => onClick && onClick(slug)}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={label} />
      {hasChilds === true && (
        <Chevron
          sx={{
            width: 10,
            height: 16,
            transform: `rotate(${props.open ? 180 : 0}deg)`,
            transition: 'transform .5s',
          }}
        />
      )}
    </ListItemButton>
  );
}

export function MenuItemChild({ label, icon, onClick, selected, slug, hasChilds, ...props }) {
  return (
    <ListItemButtonChild {...props} selected={selected} onClick={() => onClick && onClick(slug)}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={label} />
      {hasChilds === true && (
        <Chevron
          sx={{
            width: 10,
            height: 16,
            transform: `rotate(${props.open ? 180 : 0}deg)`,
            transition: 'transform .5s',
          }}
        />
      )}
    </ListItemButtonChild>
  );
}

export default MenuItem;
